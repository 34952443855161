import React from 'react';

import { ContainerStyled } from '@modules/core/container';
import { FlexContainer } from '@modules/core/flex-container';

export const HomePage = (): JSX.Element => (
  <>
    <ContainerStyled hasVerticalPadding={true} hasHorizontalPadding={true}>
      <FlexContainer columns={1}>
        <h3>Home Page</h3>
      </FlexContainer>
    </ContainerStyled>
  </>
);
