export interface VerifiedAddressFormModel {
  houseName?: string;
  houseNumber?: string;
  street?: string;
  town?: string;
  postCode: string;
  fromMonth: string;
  fromYear: string;
  toMonth: string;
  toYear: string;
}

export const initialVerifiedAddressFormValues: VerifiedAddressFormModel = {
  houseName: '',
  houseNumber: '',
  fromMonth: '',
  fromYear: '',
  toMonth: '',
  toYear: '',
  postCode: '',
};
