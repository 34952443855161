import gql from 'graphql-tag';

export const CPP_POLICY_NUMBER_QUERY = gql`
  query cppUserDetails {
    cppUserDetails {
      policyNumber
    }
  }
`;

export interface CPPPolicyNumberQueryResult {
  cppUserDetails: {
    policyNumber: string;
  };
}
