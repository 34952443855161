import styled, { css } from 'styled-components';

import { colors, rem } from '@assets/styles/settings';
import { Field } from 'formik';

const StyledShared = () => css`
  background-color: white;

  &.isInvalid {
    border: 1px solid ${colors.error};
  }
`;

interface StyledFieldInputProps {
  flexbasis?: string;
}
export const StyledFieldInput = styled(Field)<StyledFieldInputProps>`
  ${StyledShared};
  flex-basis: ${({flexbasis}) => flexbasis ? flexbasis : 'auto'};
  padding: ${rem(12)};

  &:read-only,
  &:disabled {
    background-color: ${colors.readonly};
    cursor: default;
  }
`;

export const StyledFieldSelect = styled(Field)`
  ${StyledShared};
  width: 48%;
  height: ${rem(50)};
`;
