// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import { PageLayout } from '@modules/domain/page-layout';
import React from 'react';

export const UnauthorisedPage = (): JSX.Element => (
  <PageLayout title="Unauthorised" metaTitle="Unauthorised">
    <Typography.Copy.Text>
      You are not authorised to visit this site. If you think this is an error please call our customer services team on 0344 848 4277*. Lines are open Monday- Friday, 8am – 6pm.
    </Typography.Copy.Text>
    <Typography.Copy.SmallText>
      *034 calls cost no more than calls to geographical numbers (01 or 02) and will be included as part of inclusive minutes in your package
    </Typography.Copy.SmallText>
  </PageLayout>
);
