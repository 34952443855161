import gql from 'graphql-tag';

import { Address, AddressDatePeriod } from '@modules/domain/address/models';

export interface UpdatedUserPreviousAddress extends Address, AddressDatePeriod {}

export interface UserUpdate {
  clientId: string;
  isOffline: boolean;
  title: string;
  middleName: string;
  mothersMaidenName: string;
  dateOfBirthDay: string;
  dateOfBirthMonth: string;
  dateOfBirthYear: string;
  emailAddress: string;
  contactNumber: string;
  previousAddresses: UpdatedUserPreviousAddress[];
  currentAddressFromMonth: string;
  currentAddressFromYear: string;
}

export interface FraudNet {
  jSCData: string;
  hDMData: string;
}

export const UPDATE_USER = gql`
  mutation updateUser($update: UpdateUserInput!) {
    updateUser(update: $update)
  }
`;

export const CALCULATE_BEHAVIOUR_DATA = gql`
  query calculateBehaviourData($jSCData: String!, $hDMData: String!) {
    calculateBehaviourData(jSCData: $jSCData, hDMData: $hDMData) {
      status
    }
  }
`;

export const ACCEPT_TERMS_AND_CONDITIONS = gql`
  mutation acceptTermsAndConditions($isOffline: Boolean!) {
    acceptTermsAndConditions(isOffline: $isOffline)
  }
`;
