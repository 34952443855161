import React, { FunctionComponent } from 'react';

import { flexDirection } from '@modules/core/flex-container';
import { StyledTwoColumnContainer } from '@modules/core/two-column-container/two-column-container.styled';

const containerProps = {
  breakpointColumns: [
    {
      breakpoint: 20,
      columns: 2,
      direction: flexDirection.ROW,
      gutter: 1,
    },
  ],
  columns: 1,
  direction: flexDirection.COLUMN,
  gutter: 0,
};

interface TwoColumnContainerProps {
  justifyContent?: string;
  sideBar?: boolean;
}

export const TwoColumnContainer: FunctionComponent<TwoColumnContainerProps> = ({ sideBar, justifyContent, children }) => (
  <StyledTwoColumnContainer sideBar={sideBar} justifyContent={justifyContent} {...containerProps}>
    {children}
  </StyledTwoColumnContainer>
);
