import React, { ChangeEvent, FunctionComponent } from 'react';

import { StyledSelect } from './select.styled';

interface SelectProps {
  disabled?: boolean;
  id?: string;
  label: string;
  options: any;
  value: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
}

export const Select: FunctionComponent<SelectProps> = ({ disabled, id, label, value, options, onChange }) => (
  <label>
    <span>{label}</span>
    <StyledSelect value={value} onChange={onChange} id={id} disabled={disabled}>
      {options.map((option: any) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </StyledSelect>
  </label>
);
