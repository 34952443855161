import { css } from 'styled-components';

export const typography = () => css`
  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  strong {
    font-weight: 400;
  }
`;
