import React, { Fragment, FunctionComponent } from 'react';

import { SegmentBlock } from '@modules/core/segment-block/segment-block';
import { flexDirection } from '@modules/core/segment-block/segment-block.styled';
import { getHouseValue } from '@partnerships/common/modules/utils';
import { AddressSummary } from '../address-summary';
import { ManualAddressForm, ManualAddressFormModel } from '../manual-address-form';
import { AddressHistoryListItemModel, CurrentAddressModel } from '../models';

interface AddressHistoryListItemProps {
  address: AddressHistoryListItemModel;
  formValues: ManualAddressFormModel;
  id: number;
  showEditForm: boolean;
  onCloseForm: () => void;
  onEditAddressDetails: (id: number) => void;
  onSubmitForm: (values: ManualAddressFormModel) => void;
}

export const AddressHistoryListItem: FunctionComponent<AddressHistoryListItemProps> = ({
  address,
  formValues,
  id,
  showEditForm,
  onCloseForm,
  onEditAddressDetails,
  onSubmitForm,
}): JSX.Element =>
  showEditForm ? (
    <SegmentBlock direction={flexDirection.COLUMN}>
      <ManualAddressForm
        initialFormValues={formValues}
        title={'Edit previous address'}
        isEditing={true}
        onCloseForm={onCloseForm}
        onFormSubmit={onSubmitForm}
      />
    </SegmentBlock>
  ) : (
    <Fragment key={`AddressHistoryListItem=${id}`}>
      <AddressSummary
        county={address.county}
        district={address.district}
        city={address.city}
        house={getHouseValue(address.flat, address.houseName, address.houseNumber)}
        postCode={address.postCode}
        street={address.street}
        toMonth={address.toMonth}
        toYear={address.toYear}
        fromMonth={address.fromMonth}
        fromYear={address.fromYear}
        onEditAddressDetails={() => onEditAddressDetails(id)}
      />
    </Fragment>
  );
