// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent } from 'react';

import { CookieRoutes, PdfRoutes, RightsRoutes, SupportRoutes } from '@app/routes';
import { ContainerStyled } from '@modules/core/container';
import { FooterStyled } from './footer.styled';

const props = {
  footerLinks: [
    {
      title: 'Privacy Policy',
      href: PdfRoutes.PrivacyPolicy,
    },
    {
      title: 'Cookies',
      href: CookieRoutes.Index,
    },
    {
      title: 'Terms & Conditions',
      href: PdfRoutes.TermsAndConditions,
    },
    {
      title: 'Statement of Rights',
      href: RightsRoutes.Index,
    },
    {
      title: 'Contact Us',
      href: SupportRoutes.Index,
    },
  ],
  leftPanelContent: (
    <Typography.Copy.SmallText>
      All Experian consumer services are provided by Experian Ltd (registered number 653331). Experian Ltd is authorised and regulated by
      the Financial Conduct Authority (firm reference number 738097). Experian Ltd is registered in England and Wales with registered office
      at The Sir John Peace Building, Experian Way, NG2 Business Park, Nottingham, NG80 1ZZ.
      <br />
      <br />
      The web monitoring feature and its alerts within Identity Plus is not Financial Conduct Authority regulated activity.
      <br />
      <br />
      Copyright &copy;{new Date().getFullYear()}, Experian Ltd. All rights reserved.
    </Typography.Copy.SmallText>
  ),
  rightPanelContent: <></>,
};

export const FooterWrapper: FunctionComponent = () => (
  <ContainerStyled>
    <FooterStyled
      openLinksInNewTab
      product="Identity Plus"
      productPrice={6.99}
      trialProduct="CreditExpert"
      experianUrl="http://www.experian.co.uk"
      homeUrl={process.env.CPP_ROOT_URL}
      {...props}
    />
  </ContainerStyled>
);
