import styled from '@assets/styles/styled';

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: top;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 50;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 300ms ease-out;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
`;

export interface StyledCheckboxProps {
  size: number; 
  icon: string;
  checked: boolean;
  disabled?: boolean;
}

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: inline-block;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  margin-right: 16px;
  padding: 4px;
  background: ${props => (props.checked ? props.theme.colors.experianDarkBlue : 'white')};
  border: 1px solid ${props => props.theme.colors.experianDarkBlue};
  border-radius: 3px;
  transition: all 300ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 3px 1px lightgrey;
    outline: 1px solid lightgrey;
  }
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
    opacity: ${props => (props.checked ? '1' : '0')};
    stroke-dashoffset: ${props => (props.checked ? '0' : '-50')};
  }
`;
