// @ts-ignore
import { Link, Logo } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { ExternalRoutes } from '@app/routes';
import { ContainerStyled } from '@modules/core/container';
import { Loading } from '@modules/core/loading';
import { UserHeader } from '@modules/domain/user';
import { UserSession, UserSessionResult, UserSessionState } from '@partnerships/common/modules/user-session';
import {
  getExistingUserSession,
  removeCookies,
  terminateUserSession,
} from '@partnerships/common/modules/user-session/user-session-utils';
import { DetailsContainer, HeaderLinkContainer, LinkCpp, LogoCpp, LogosContainer, LogoutLink, SubNav } from './header.styled';

interface HeaderProps {
  displayBackToAccounts: boolean;
  displayBackToMain: boolean;
}

export const Header: FunctionComponent<HeaderProps> = ({ displayBackToAccounts, displayBackToMain }) => {
  const getUserSession = async (): Promise<UserSessionResult> => {
    return await getExistingUserSession();
  };

  const [userSession, setUserSession] = useState<UserSession | null>(null);
  const invalidStates = [UserSessionState.unknown, UserSessionState.badAuth, UserSessionState.genericError] as string[];
  const isAuthenticated = userSession && !invalidStates.includes(userSession.state);

  const userTemplate = () => {
    if (!userSession) {
      return <Loading />;
    } else if (isAuthenticated) {
      return userSession.state === UserSessionState.active ? null : <UserHeader />;
    } else {
      return null;
    }
  };

  const logout = async (e: React.MouseEvent<HTMLLinkElement>) => {
    e.preventDefault();
    await terminateUserSession();
    await removeCookies().then(() => {
      window.location.href = ExternalRoutes.CppIdentity;
    });
  };

  useEffect(() => {
    let inProgress = true;
    let interval;

    const updateUserSession = (): void => {
      getUserSession().then(result => {
        setUserSession(result.userSession);
      });
    };

    if (inProgress) {
      if (!userSession) {
        updateUserSession();
      } else {
        interval = setInterval(() => {
          updateUserSession();
        }, 500);
      }
    }
    return () => {
      inProgress = false;
      if (interval) clearInterval(interval);
    };
  }, [userSession]);

  return (
    <header>
      <ContainerStyled hasHorizontalPadding={true}>
        <LogosContainer>
          <Logo />
          <LinkCpp href={ExternalRoutes.CppIdentity}>
            <LogoCpp src="/cpp-assets/images/cpp-logo.svg" data-testid="cpp-logo" alt="CPP Logo" />
          </LinkCpp>
        </LogosContainer>
        <DetailsContainer>
          <LogoutLink onClick={(e: React.MouseEvent<HTMLLinkElement>) => logout(e)} data-testid="logout-link" inline>
            <span>Log Out</span>
          </LogoutLink>
          {userTemplate()}
        </DetailsContainer>
      </ContainerStyled>
      <SubNav>
        <HeaderLinkContainer hasHorizontalPadding={false} hasVerticalPadding={false}>
          {displayBackToAccounts && (
            <Link.Anchor onClick={() => terminateUserSession()} href={ExternalRoutes.CppIdentity} data-testid="back-to-cpp-accounts" inline>
              &lt; Back to CPP accounts
            </Link.Anchor>
          )}
          {displayBackToMain && (
            <Link.Anchor href="/landing" data-testid="back-to-main-app" inline>
              &lt; Back to main application
            </Link.Anchor>
          )}
        </HeaderLinkContainer>
      </SubNav>
    </header>
  );
};
