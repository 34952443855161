import styled from '@assets/styles/styled';
import React from 'react';

interface StyledFormRowProps {
  justifyContent?: string;
  maxWidth?: string;
  wrap?: boolean;
}

export const StyledFormRow = styled(({ justifyContent, wrap, maxWidth, ...props }) => <section {...props} />)<StyledFormRowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'space-between')};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'none')};
`;
