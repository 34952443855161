// @ts-ignore
import { Link, Typography } from '@experian-uk/corvetteuk-common-ui';
import { PageLayout } from '@modules/domain/page-layout';
import React from 'react';
import { RightsImportant, StyledStatementOfRightsPage } from './statement-of-rights.styled';

export const StatementOfRightsPage = () => (
  <PageLayout sidebar={false} title="Statement of rights" metaTitle="Statement of rights">
    <StyledStatementOfRightsPage>
      <hr />
      <Typography.Headings.Secondary>
        Statement of rights for individuals (including sole traders) with relation to files held by credit reference agencies
      </Typography.Headings.Secondary>
      <RightsImportant>
        <strong>
          Your rights under section 159 of the consumer credit act 1974, and under article 16 GDPR (right of rectification), if you think
          any entry in our file is wrong.
        </strong>
      </RightsImportant>
      <Typography.Copy.Text>
        This statement of your rights is provided by Experian Ltd together with all the information we hold about you on our files. Our
        postal address is Experian, PO Box 9000, Nottingham, NG80 7WP.
      </Typography.Copy.Text>
      <Typography.Copy.Text>Your rights are as follows:</Typography.Copy.Text>
      <Typography.Copy.Text>
        If you think that any of the information we have sent you is wrong and that you are likely to suffer because it is wrong, you can
        ask us to correct it or remove it from our file. You need to write to us telling us what you want us to do. You should explain why
        you think the information is wrong.
      </Typography.Copy.Text>
      <Typography.Copy.Text>
        If you write to us, we have to reply in writing within 28 days. Our reply will tell you whether we have corrected the information,
        removed it from our file or done nothing. If we tell you that we have corrected the information, you will get a copy.
      </Typography.Copy.Text>
      <Typography.Copy.Text>
        If our reply says that we have done nothing, or if we fail to reply within 28 days, or if we correct the information but you are not
        happy with the correction, you can write your own note of correction and ask for it to be included on our file. To do this, you will
        need to write to us within 28 days of receiving our reply. If you did not get a reply from us and you want the information we sent
        you to be corrected, you will need to write to us within 8 weeks of the letter you wrote to us in which you asked us to correct the
        information or remove it from our file.
      </Typography.Copy.Text>
      <Typography.Copy.Text>Your letter will need to:</Typography.Copy.Text>
      <ol>
        <li>
          include the note of correction you have written. It must not be more than 200 words long and should give a clear and accurate
          explanation of why you think the information is wrong. If the information is factually correct but you think it creates a
          misleading impression, your note of correction can explain why.
        </li>
        <li>
          ask us to add your note of correction to our file and to include a copy of it whenever we give anyone any of the information you
          think is wrong or any information based on it.
        </li>
      </ol>
      <Typography.Copy.Text>
        If we accept your note of correction, we have to tell you in writing within 28 days that we are going to add it to our file.
      </Typography.Copy.Text>
      <Typography.Copy.Text>
        If we think it would be wrong to add your note of correction to our file, we have to apply for a ruling from the Information
        Commissioner. We will apply for a ruling if we do not want to include your note of correction because we think it is wrong, or
        because we think it is defamatory, frivolous or scandalous, or unsuitable for publication for some other reason. We can only refuse
        to include your note of correction if the Commissioner agrees with us.
      </Typography.Copy.Text>
      <Typography.Copy.Text>
        If we have not written to you within 28 days of receiving your note of correction, or if we have written telling you that we are not
        going to add your note of correction to our file, you can appeal to the Information Commissioner. If you want to do this, you will
        have to write to the following address:
        <dl>
          <dt>Information Commissioner</dt>
          <dd>Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF.</dd>
          <dt>Phone</dt>
          <dd>0303 123 1113 (or +44 1625 545 745 if calling from outside the UK)</dd>
          <dt>Fax</dt>
          <dd>01625 524 510</dd>
          <dt>Email</dt>
          <dd>
            <Link.Anchor href="mailto:casework@ico.org.uk">casework@ico.org.uk</Link.Anchor>
          </dd>
          <dt>or online contact form:</dt>
          <dd>
            <Link.Anchor href="https://ico.org.uk/global/contact-us/email/">https://ico.org.uk/global/contact-us/email/</Link.Anchor>
          </dd>
        </dl>
      </Typography.Copy.Text>
      <Typography.Copy.Text>When you write, you must give the following details:</Typography.Copy.Text>
      <ol>
        <li>your full name and address</li>
        <li>our name and address</li>
        <li>
          details of the information you think is wrong, including:
          <ul>
            <li>why you think it is wrong</li>
            <li>why you think you are likely to suffer because it is wrong, and</li>
            <li>an indication of when you sent us your note of correction.</li>
          </ul>
        </li>
      </ol>
      <Typography.Copy.Text>
        It would be helpful to the Commissioner if you could include a copy of your note of correction.
      </Typography.Copy.Text>
      <Typography.Copy.Text>
        Before deciding what to do, the Commissioner may ask us for our side of the story and send us a copy of your letter. In return, you
        will be sent any comments we make.
      </Typography.Copy.Text>
      <Typography.Copy.Text>
        The Commissioner can make any order she thinks fit when she has considered your appeal. For example, she can order us to accept your
        note of correction and add it to our file.
      </Typography.Copy.Text>
      <Typography.Copy.Text>
        If at any stage we fail to correct or remove wrong information, you can ask the Information Commissioner to check whether we are
        meeting the requirements of the General Data Protection Regulation. The General Data Protection Regulation requires us to take
        reasonable steps to check the accuracy of personal information. If you think we have failed to correct or remove wrong information
        about you, you have the right to ask the Information Commissioner, at the above address, to check whether our dealing with your
        information has met this requirement.
      </Typography.Copy.Text>
      <Typography.Copy.Text>
        <em>Important Note:</em> The various time limits referred to in this statement (mostly 28 days) start with the day following receipt
        and end with the day of delivery. That means (for example) that if you have 28 days to reply to a letter from us, the period starts
        with the day after you receive our letter; and you then have to make sure that your reply is delivered to us no later than 28 days
        from that date. In order to avoid the risk of losing your rights you should therefore allow for postal delays.
      </Typography.Copy.Text>
    </StyledStatementOfRightsPage>
  </PageLayout>
);
