// @ts-ignore
import { Link } from '@experian-uk/corvetteuk-common-ui';

import styled from '@assets/styles/styled';

export const StyledEditButton = styled(Link.Button)`
  img {
    margin-right: 0.5rem;
    vertical-align: top;
  }
`;
