import * as Yup from 'yup';

import { HouseRegexPattern, PostCodeRegexPattern } from '@modules/core/validation';

export const verifiedAddressFormSchema = Yup.object().shape(
  {
    houseName: Yup.string()
      .trim()
      .when('houseNumber', {
        is: value => !value,
        then: Yup.string().required('Please enter a house name or number.'),
        otherwise: Yup.string(),
      })
      .matches(
        HouseRegexPattern,
        'Your house name or number may only contain letters and numbers. Please remove any other special characters and try again.',
      ),
    houseNumber: Yup.string()
      .trim()
      .when('houseName', {
        is: value => !value,
        then: Yup.string().required('Please enter a house number or name.'),
        otherwise: Yup.string(),
      })
      .matches(
        HouseRegexPattern,
        'Your house name or number may only contain letters and numbers. Please remove any other special characters and try again.',
      ),
    postCode: Yup.string()
      .required('Please enter a valid postcode.')
      .matches(PostCodeRegexPattern, 'Please enter a valid postcode. This must contain only letters and numbers'),
  },
  [['houseNumber', 'houseName']],
);
