import React, { FunctionComponent } from 'react';

import { Content, Heading, Icon, IconContainer, StyledInfoBlock } from './info-block.styled';

export interface InfoBlockProps {
  withBorder?: boolean;
  heading?: string;
  content?: string;
  iconName?: string;
  testId?: string;
}

export const InfoBlock: FunctionComponent<InfoBlockProps> = ({ withBorder, content, heading, iconName, children, testId }) => {
  return (
    <StyledInfoBlock withBorder={withBorder} data-testid={testId}>
      {iconName && (
        <IconContainer>
          <Icon name={iconName} />
        </IconContainer>
      )}

      {heading && <Heading>{heading}</Heading>}

      <Content>{content || children}</Content>
    </StyledInfoBlock>
  );
};
