import gql from 'graphql-tag';

export const USER_DETAILS_QUERY = gql`
  query userDetails {
    userDetails {
      title
      firstName
      lastName
    }
  }
`;

export interface UserDetails {
  firstName: string;
  lastName: string;
  title?: string;
}

export interface UserDetailsQueryResult {
  userDetails: UserDetails;
}
