import { colors, rem, spacers } from '@assets/styles/settings';
import styled from '@assets/styles/styled';

interface StyledFormGroupProps {
  flexBasis?: string;
  paddingTop?: string;
  marginTop?: string;
  maxWidth?: string;
}

export const StyledFormGroup = styled.div<StyledFormGroupProps>`
  display: flex;
  flex-direction: column;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'none')};
  margin: 0.325rem 0;

  ${({ theme: { media }, flexBasis }) =>
    media(
      '15',
      `
   flex-basis: ${flexBasis ? flexBasis : 'auto'};

  `,
    )};

  > label {
    margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0.75rem')};
    padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : rem(10))};
  }
`;

export const StyledInputWrap = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  input,
  select {
    width: 100%;
    margin-right: ${spacers[2]};
  }

  svg {
    width: ${rem(20)};
    height: ${rem(20)};
  }

  p {
    width: 100%;
  }
`;

export const StyledErrorMessage = styled.div`
  color: ${colors.error};
`;
