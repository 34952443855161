import ReactTooltip from 'react-tooltip';
import scrollToElementPackage = require('scroll-to-element');

export const scrollToElement = (elementId: string, elementToOpen?: string, isExternal: boolean = false) => {
  ReactTooltip.hide();

  let externalCallTimer: number | null | NodeJS.Timeout = null;

  const clickButton = (elementToOpenId: string) => {
    const element = document.getElementById(elementToOpenId);

    if (element !== null && (element.getAttribute('aria-expanded') === 'false' || element.getAttribute('aria-expanded') === null)) {
      element.click();
    }
  };

  if (isExternal) {
    externalCallTimer = setTimeout(() => {
      clickButton(elementId);
    }, 100);
  } else if (typeof elementToOpen !== 'undefined') {
    clickButton(elementToOpen);
  }

  const timeout = setTimeout(() => {
    scrollToElementPackage(`#${elementId}`, {
      offset: 0,
      ease: 'inOutCirc',
    });
    if (externalCallTimer !== null) {
      clearTimeout(externalCallTimer as NodeJS.Timeout);
    }
  }, 250);

  return () => clearTimeout(timeout);
};
