import React, { FunctionComponent, useContext, useState } from 'react';

import { StyledErrorMessage } from '@modules/core/error-message';
import { flexDirection } from '@modules/core/flex-container';
import { StyledFlexDivContainer } from '@modules/core/flex-container/flex-container.styled';
import { Select } from '@modules/core/select';
import { SelectMulti } from '@modules/core/select-multi';
import { AddressContext } from '@modules/domain/address/address.context';
import { getHouseValue } from '@partnerships/common/modules/utils';
import { CurrentAddressPanel } from '../current-address-panel';

interface CurrentAddressPeriodControlProps {
  monthOptions: string[];
  monthValue: string;
  yearOptions: string[];
  yearValue: string;
  selectionsHaveBeenConfirmed: boolean;
  invalidMessage: string | null;
  onMonthValueChanged: (newValue: string) => void;
  onYearValueChanged: (newValue: string) => void;
}

const CurrentAddressPeriodControl: FunctionComponent<CurrentAddressPeriodControlProps> = ({
  monthOptions,
  monthValue,
  onMonthValueChanged,
  onYearValueChanged,
  invalidMessage,
  selectionsHaveBeenConfirmed,
  yearOptions,
  yearValue,
}): JSX.Element => {
  const [monthOptionTouched, setMonthOptionTouched] = useState<boolean>(false);
  const [yearOptionTouched, setYearOptionTouched] = useState<boolean>(false);

  const { addressState } = useContext(AddressContext);
  const { currentAddress } = addressState;

  const controlsTouched = (): boolean => {
    return yearOptionTouched && monthOptionTouched;
  };

  if (currentAddress) {
    const { city, flat, houseName, houseNumber, postCode, street, district, county } = currentAddress;
    const house = getHouseValue(flat, houseName, houseNumber);

    return (
      <>
        <CurrentAddressPanel house={house} street={street} district={district} city={city} county={county} postCode={postCode} />
        <StyledFlexDivContainer direction={flexDirection.COLUMN} align="stretch">
          <SelectMulti
            label="When did you move into this address?"
            isInvalid={!!invalidMessage && controlsTouched()}
            isValid={!invalidMessage}
          >
            <div>
              <Select
                disabled={selectionsHaveBeenConfirmed}
                label="Month"
                options={monthOptions}
                value={monthValue}
                onChange={event => {
                  event.preventDefault();
                  setMonthOptionTouched(true);
                  onMonthValueChanged(event.currentTarget.value);
                }}
              />
            </div>
            <div>
              <Select
                disabled={selectionsHaveBeenConfirmed}
                label="Year"
                options={yearOptions}
                value={yearValue}
                onChange={event => {
                  event.preventDefault();
                  setYearOptionTouched(true);
                  onYearValueChanged(event.currentTarget.value);
                }}
              />
            </div>
          </SelectMulti>
        </StyledFlexDivContainer>
        {invalidMessage && controlsTouched() && <StyledErrorMessage> {invalidMessage} </StyledErrorMessage>}
      </>
    );
  }
  return <></>;
};

CurrentAddressPeriodControl.displayName = 'current-address-period-control';

export { CurrentAddressPeriodControl };
