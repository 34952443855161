import styled from '@assets/styles/styled';

export const StyledUserHeader = styled.div`
  display: inline-block;
  max-width: 75%;
`;

export const StyledRegistrationStatus = styled.div`
  ${({ theme }) =>
    theme.media(
      '15',
      `
        
        display: inline-block
        line-height: 1;
        padding-left: 0.5rem;
        border-left: 1px solid;
        margin-left: 0.5rem;
    `,
    )}
`;
