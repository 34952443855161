import styled from 'styled-components';

import { BreakpointColumn, fixedWidth, flexDirection } from '@modules/core/flex-container';

interface FlexContainerStyledProps {
  breakpointColumns: BreakpointColumn[];
  columns: number;
  direction: flexDirection;
  gutter: number;
  wrap?: string;
  maxWidth?: fixedWidth;
}

export const FlexContainerStyled = styled.div<FlexContainerStyledProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};
  justify-content: space-between;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'none')};
  margin: 1rem 0;

  > * {
    flex-basis: auto;
  }

  ${({ columns, gutter }) => `
    > * {
      flex: 0 1 calc(${100 / columns}% - ${gutter}rem);
    }
  `}

  ${({ breakpointColumns, theme: { media } }) => `
    ${applyBreakpointColumns(breakpointColumns, media)};
  `}
`;

const applyBreakpointColumns = (breakpointColumns: BreakpointColumn[], media: (id: number, styles: string) => string) => {
  let styles = '';

  breakpointColumns.forEach(breakpointColumn => {
    styles += `${media(
      breakpointColumn.breakpoint,
      `flex-direction: ${breakpointColumn.direction};
        > * {
          flex: 0 1 calc(${100 / breakpointColumn.columns}% - ${breakpointColumn.gutter}rem);
        
      `,
    )}`;
  });

  return styles;
};

interface StyledFlexDivProps {
  direction: flexDirection;
  flex?: string;
  wrap?: string;
  justify?: string;
  align?: string;
}

export const StyledFlexDivContainer = styled.div<StyledFlexDivProps>`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : '1 1 auto')};
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  flex-wrap: ${({ wrap }) => (wrap ? wrap : 'no-wrap')};
  align-items: ${({ align }) => (align ? align : 'center')};
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
`;
