import React, { FunctionComponent } from 'react';

import { CheckboxContainer, HiddenCheckbox, Icon, StyledCheckbox } from './input-checkbox.styled';

export interface InputCheckboxProps {
  id: string;
  name: string;
  value: string;
  checked: boolean;
  disabled?: boolean;
  onChange?: () => void;
  size: number;
  icon: string;
  className?: string;
  testId?: string;
}

export enum CheckboxIcons {
  tick = 'M20,6L9 17 4 12',
}

export const InputCheckbox: FunctionComponent<InputCheckboxProps> = ({
  testId,
  size,
  icon,
  className,
  id,
  name,
  value,
  checked,
  disabled,
  children,
  onChange,
}) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox
      checked={checked}
      id={id}
      disabled={disabled}
      readOnly={!onChange}
      aria-checked={checked}
      onChange={onChange}
      name={name}
      value={value}
    />
    <label htmlFor={id}>
      <StyledCheckbox size={size} checked={checked} icon={icon} data-testid={testId}>
        <Icon viewBox="0 0 24 24">
          <path d={icon} />
        </Icon>
      </StyledCheckbox>
      {children}
    </label>
  </CheckboxContainer>
);
