import styled from '@assets/styles/styled';
import React from 'react';

interface StyledFlexRowProps {
  justifyContent?: string;
  reversed?: boolean;
  wrap?: boolean;
}

export const StyledFlexRow = styled(({ reversed, justifyContent, wrap, ...props }) => <section {...props} />)<StyledFlexRowProps>`
  display: flex;
  flex-direction: ${({ reversed }) => (reversed ? 'row-reverse' : 'row')};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};
  align-items: flex-start;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'space-between')};
`;
