// @ts-ignore
import { Link } from '@experian-uk/corvetteuk-common-ui';
import styled from 'styled-components';

interface StyledDocumentLinkProps {
  blockItem: boolean;
}

export const StyledDocumentLink = styled(Link.Anchor)<StyledDocumentLinkProps>`
  ${props => !props.blockItem && 'display: block;'}
`;
