export enum HomeRoutes {
  Index = '/',
  Home = '/home',
}

export enum SignUpRoutes {
  Index = '/sign-up',
  AddressDetails = '/sign-up/address-details',
  PersonalDetails = '/sign-up/personal-details',
  Complete = '/sign-up/complete',
  Activation = '/sign-up/activation',
}

export enum ErrorRoutes {
  Unauthorised = '/error/not-authorised',
  AwaitingApproval = '/error/awaiting-approval',
  NotEligible = '/error/not-eligible',
  NotApproved = '/error/not-approved',
  Root = '/error',
  SessionTimeout = '/error/session-timeout',
}

export enum ReactivationRoutes {
  Index = '/reactivation',
  Reactivation = '/reactivation/reactivating',
  ThankYou = '/reactivation/thank-you',
}
