// @ts-ignore
import { mixins } from '@experian-uk/corvetteuk-common-ui';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export interface SpaLinkProps {
  block?: boolean;
}

const SpaLink = styled(({ block, ...props }) => <Link {...props} />)<SpaLinkProps>`
  display: ${props => (props.block ? 'block' : 'inline')};
  font-weight: inherit !important;
  font-size: inherit !important;
  ${props => mixins.buttonStyles(props)}
`;

export { SpaLink };
