import moment, { Moment } from 'moment';

export interface AddressDate {
  month: string;
  year: string;
}

export interface AddressPeriod {
  start: AddressDate;
  end: AddressDate;
}

const getMinAddressPeriodStartDate = (minNumberOfMonthsRequired: number): Date => {
  return moment()
    .subtract(minNumberOfMonthsRequired, 'months')
    .toDate();
};

const isCurrentAddressPeriodGreaterThanMinMonths = (currentAddressPeriod: AddressDate, minNumberOfMonthsRequired: number): boolean => {
  const currentAddressPeriodMonthIndex = moment().month(currentAddressPeriod.month);
  const currentAddressMoment = moment([currentAddressPeriod.year, currentAddressPeriodMonthIndex.month()]);
  return currentAddressMoment.toDate() <= getMinAddressPeriodStartDate(minNumberOfMonthsRequired);
};

const byEarliestPreviousAddressPeriodComparer = (a: AddressPeriod, b: AddressPeriod): number => {
  const aMoment = formatToMoment(a.start.year, a.start.month).toDate();
  const bMoment = formatToMoment(b.start.year, b.start.month).toDate();

  if (aMoment === bMoment) {
    return 0;
  }

  return aMoment > bMoment ? 1 : -1;
};

const getEarliestPreviousAddressPeriod = (previousAddressPeriod: AddressPeriod): Date => {
  const { start } = previousAddressPeriod;
  const earliestAddressPeriodMonthIndex = moment()
    .month(start.month)
    .format('M');
  const earliestAddressPeriodDate = moment(`${start.year}-${earliestAddressPeriodMonthIndex}-01`).add(1, 'month');
  return earliestAddressPeriodDate.toDate();
};

const createCurrentAddressPeriod = (currentAddressDate: AddressDate): AddressPeriod => {
  return {
    start: {
      month: currentAddressDate.month,
      year: currentAddressDate.year,
    },
    end: {
      month: moment().format('MMMM'),
      year: moment().format('YYYY'),
    },
  };
};

const formatToMoment = (year: string, month: string): Moment => {
  return moment(`${year} ${month}`, 'YYYY MMMM');
};

const differenceInMonths = (previousAddressPeriod: AddressPeriod, addressPeriod: AddressPeriod): number => {
  const previous = formatToMoment(previousAddressPeriod.start.year, previousAddressPeriod.start.month);
  const current = formatToMoment(addressPeriod.end.year, addressPeriod.end.month);

  return Math.round(moment.duration(previous.diff(current)).asMonths());
};

const getGapsInMonthsBetweenAddressPeriods = (addressPeriods: AddressPeriod[]): number => {
  return addressPeriods
    .map((addressPeriod, index, addressPeriodsArray) => {
      const previousAddressPeriod = addressPeriodsArray[index - 1];
      return previousAddressPeriod ? differenceInMonths(previousAddressPeriod, addressPeriod) : 0;
    })
    .reduce((accumulatedTotalMonths, diffInMonths) => {
      return accumulatedTotalMonths + diffInMonths;
    });
};

export const hasRequiredAmountOfAddressHistory = (
  currentAddressDate: AddressDate,
  previousAddresses?: AddressPeriod[],
  minNumberOfMonthsRequired = 72,
): boolean => {
  if (isCurrentAddressPeriodGreaterThanMinMonths(currentAddressDate, minNumberOfMonthsRequired)) {
    return true;
  }

  if (previousAddresses && previousAddresses.length > 0) {
    const earliestPreviousAddressPeriodItem = previousAddresses.sort(byEarliestPreviousAddressPeriodComparer)[0];
    const totalNumberOfMonths = moment().diff(getEarliestPreviousAddressPeriod(earliestPreviousAddressPeriodItem), 'months') + 1; // 1 offset to make the months inclusive
    return totalNumberOfMonths >= minNumberOfMonthsRequired;
  }

  return false;
};

export const hasGapsInAddressHistory = (currentAddressDate: AddressDate, previousAddresses?: AddressPeriod[]): boolean => {
  if (previousAddresses && previousAddresses.length > 0) {
    const addressPeriodsOrderedByMostRecent = previousAddresses.sort(byEarliestPreviousAddressPeriodComparer).reverse();
    addressPeriodsOrderedByMostRecent.unshift(createCurrentAddressPeriod(currentAddressDate));
    const gapsInMonthsBetweenAddressPeriods = getGapsInMonthsBetweenAddressPeriods(addressPeriodsOrderedByMostRecent);
    return gapsInMonthsBetweenAddressPeriods > 0;
  }

  return false;
};
