import gql from 'graphql-tag';

export interface SessionStatusQueryResponse {
  sessionStatus: string;
}

export const SESSION_STATUS_QUERY = gql`
  query sessionState {
    sessionStatus
  }
`;
