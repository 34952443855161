// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent } from 'react';

import { PageLayout } from '@modules/domain/page-layout';

export const GenericErrorPage: FunctionComponent = (): JSX.Element => (
  <PageLayout title="An error occurred" metaTitle="Generic Error">
    <Typography.Copy.Text>
      If the problem persists, please contact our Customer Service team on 0344 848 4277*. Lines are open Monday-
      Friday, 8am – 6pm.
    </Typography.Copy.Text>
    <Typography.Copy.SmallText>
      *034 calls cost no more than calls to geographical numbers (01 or 02) and will be included as part of inclusive
      minutes in your package
    </Typography.Copy.SmallText>
  </PageLayout>
);
