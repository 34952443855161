// @ts-ignore
import { Footer } from '@experian-uk/corvetteuk-common-ui';
import styled from 'styled-components';

export const FooterStyled = styled(Footer)`
  section {
    &:first-child {
      width: 100%;
      small {
        font-weight: 300;
        font-size: 0.875rem;
      }
    }
  }
`;
