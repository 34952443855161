// @ts-ignore
import { Button, Notification, Typography } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router';

import { SignUpRoutes } from '@app/routes';
import { Loading } from '@modules/core/loading';
import { defaultMonthValue, defaultYearValue } from '@modules/core/validation';
import { Address } from '@modules/domain/address';
import { AddressContext } from '@modules/domain/address/address.context';
import { FraudNetForm, FraudNetOutput } from '@modules/domain/fraudnet';
import { PageLayout } from '@modules/domain/page-layout';
import { CALCULATE_BEHAVIOUR_DATA, FraudNet, SignUpContext, UPDATE_USER } from '@modules/domain/sign-up';
import { TermsInformation } from '@modules/domain/sign-up/address-details/terms-information';
import { getExistingUserSession } from '@partnerships/common/modules/user-session/user-session-utils';
import { useApolloClient } from 'react-apollo-hooks';

const AddressDetailsPage: FunctionComponent<RouteChildrenProps> = ({ history }): JSX.Element => {
  const signUpContext = useContext(SignUpContext);
  const addressContext = useContext(AddressContext);
  const pageTitle = 'Continue Registration';
  const pageMetaTitle = 'Continue Registration';
  const apolloClient = useApolloClient();

  const { addressState } = addressContext;
  const { personalDetails } = signUpContext;

  const [showError, setShowError] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [currentAddressFromDateConfirmed, setCurrentAddressFromDateConfirmed] = useState<boolean>(true);
  const [termsAndCondtionsChecked, setTermsAndConditionsChecked] = useState<boolean>(false);
  const [behaviourTracked, setBehaviourTracked] = useState<FraudNetOutput | null>(null);
  const [trackerLoaded, setTrackerLoaded] = useState<boolean>(false);

  const handleRegisterNowClick = async (): Promise<void> => {
    if (!currentAddressFromDateConfirmed || !termsAndCondtionsChecked) {
      return;
    }

    setShowLoading(true);

    const { userSession } = await getExistingUserSession();

    try {
      const trackResult = await apolloClient.query<FraudNet>({
        query: CALCULATE_BEHAVIOUR_DATA,
        variables: behaviourTracked as FraudNetOutput,
      });

      if (trackResult.hasOwnProperty('errors')) {
        setShowError(true);
        return;
      }

      const mutationResult = await apolloClient.mutate({
        mutation: UPDATE_USER,
        variables: {
          update: {
            clientId: userSession.clientId,
            isOffline: false,
            title: personalDetails.title,
            middleName: personalDetails.middleName,
            mothersMaidenName: personalDetails.mothersMaidenName,
            dateOfBirthDay: personalDetails.dateOfBirthDay,
            dateOfBirthMonth: personalDetails.dateOfBirthMonth,
            dateOfBirthYear: personalDetails.dateOfBirthYear,
            emailAddress: personalDetails.emailAddress,
            contactNumber: personalDetails.contactNumber,
            currentAddressFromMonth: addressState.currentAddressMonthValue,
            currentAddressFromYear: addressState.currentAddressYearValue,
            previousAddresses: addressState.previousAddresses.map(pa => ({
              city: pa.city,
              country: pa.country,
              county: pa.county,
              district: pa.district,
              flat: pa.flat,
              houseNumber: pa.houseNumber,
              houseName: pa.houseName,
              postCode: pa.postCode,
              street: pa.street,
              fromMonth: pa.fromMonth,
              fromYear: pa.fromYear,
              toMonth: pa.toMonth,
              toYear: pa.toYear,
            })),
          },
        },
      });

      if (mutationResult.hasOwnProperty('errors')) {
        setShowError(true);
        return;
      }

      history.push(SignUpRoutes.Activation);
    } catch (error) {
      setShowError(true);
    }
    setShowLoading(false);
  };

  // tslint:disable-next-line:no-empty
  useEffect(() => {}, [behaviourTracked]);

  const fraudNetHandler = (output: FraudNetOutput, trackerOutcome: boolean) => {
    setBehaviourTracked(output);
    setTrackerLoaded(trackerOutcome);
    setShowError(!trackerOutcome);
  };

  if (showLoading) {
    return (
      <PageLayout title={pageTitle} metaTitle={pageMetaTitle} sidebar={true}>
        <Loading />
      </PageLayout>
    );
  }

  if (
    !personalDetails ||
    addressState.currentAddressMonthValue === defaultMonthValue ||
    addressState.currentAddressYearValue === defaultYearValue
  ) {
    return (
      <PageLayout title={pageTitle} metaTitle={pageMetaTitle} sidebar={true}>
        <Notification notificationTitle="Something went wrong">Could not find your details</Notification>
      </PageLayout>
    );
  }

  if (showError) {
    return (
      <PageLayout title={pageTitle} metaTitle={pageMetaTitle} sidebar={true}>
        <Notification notificationTitle="Something went wrong">Could not update your details</Notification>
      </PageLayout>
    );
  }

  return (
    <PageLayout title={pageTitle} metaTitle={pageMetaTitle} sidebar={true}>
      <Typography.Copy.Text>
        In order to provide you with the most accurate Identity Plus service we recommend providing 6 years of your address history.
      </Typography.Copy.Text>

      <FraudNetForm model={signUpContext} handler={fraudNetHandler} />

      {addressState.currentAddress && (
        <Address
          currentAddress={addressState.currentAddress}
          currentAddressFromDateConfirmed={currentAddressFromDateConfirmed}
          showPreviousAddressUpdatedNotification={false}
        />
      )}

      <TermsInformation
        isChecked={termsAndCondtionsChecked}
        onCheckedChanged={() => {
          setTermsAndConditionsChecked(!termsAndCondtionsChecked);
        }}
      />

      {behaviourTracked && trackerLoaded ? (
        <Button
          type="primary"
          isButton
          label="Register now"
          disabled={!currentAddressFromDateConfirmed || !termsAndCondtionsChecked}
          onClick={async () => await handleRegisterNowClick()}
          data-testid="register-now"
        />
      ) : (
        <div>
          <Loading />
        </div>
      )}
    </PageLayout>
  );
};

AddressDetailsPage.displayName = 'address-details-page';

export { AddressDetailsPage };
