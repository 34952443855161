import { createContext } from 'react';

export interface SignUpContextPersonalDetailsModel {
  title: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  dateOfBirthDay?: string;
  dateOfBirthMonth?: string;
  dateOfBirthYear?: string;
  emailAddress: string;
  mothersMaidenName: string;
  contactNumber: string;
  secondaryPhoneNumber?: string;
  currentAddress?: {
    flat?: string;
    houseName?: string;
    houseNumber?: string;
    street: string;
    city: string;
    postCode: string;
  };
  currentAddressFromMonth: string;
  currentAddressFromYear: string;
}

export interface SignUpContextModel {
  personalDetails: SignUpContextPersonalDetailsModel;
}

export const initialSignUpContextModel: SignUpContextModel = {
  personalDetails: {
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirthDay: '',
    dateOfBirthMonth: '',
    dateOfBirthYear: '',
    emailAddress: '',
    mothersMaidenName: '',
    contactNumber: '',
    secondaryPhoneNumber: '',
    currentAddressFromMonth: '',
    currentAddressFromYear: '',
  },
};

export const SignUpContext = createContext<SignUpContextModel>(initialSignUpContextModel);
