// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import { PageLayout } from '@modules/domain/page-layout';
import React, { FunctionComponent } from 'react';

export const NotFoundPage: FunctionComponent = (): JSX.Element => (
  <PageLayout title="Page cannot be found" metaTitle="Page not found">
    <Typography.Copy.Text>
      We're sorry, but the Experian Identity Plus page you've tried to visit cannot be found.
    </Typography.Copy.Text>
      It might have been moved, or it might not exist anymore.
    <Typography.Copy.Text>
      If you are still having problems, please call us on 0344 848 4277*. Lines are open Monday- Friday, 8am – 6pm.
    </Typography.Copy.Text>
    <Typography.Copy.SmallText>
      *034 calls cost no more than calls to geographical numbers (01 or 02) and will be included as part of inclusive minutes in your package
    </Typography.Copy.SmallText>
  </PageLayout>
);
