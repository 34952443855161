import { UserSessionLocalStorageKey } from '@partnerships/common/modules/user-session/constants';
import { RouteGuard } from 'react-route-guard';
import { UserSessionState } from '../../user-session';

export class ActiveStateRouteGuard implements RouteGuard {
  static create = (): ActiveStateRouteGuard => new ActiveStateRouteGuard();

  private constructor() {}

  shouldRoute = (): boolean => {
    const sessionData = localStorage.getItem(UserSessionLocalStorageKey);

    if (!sessionData) {
      return false;
    }

    const userSession = JSON.parse(sessionData);

    return userSession.state === UserSessionState.active;
  };
}
