import React, { FormEvent, Fragment, FunctionComponent } from 'react';

import { StyledFieldSelect } from '../form-field';
import { StyledFormSelectMulti } from './form-select-multi.styled';

interface FormSelectMultiModelOptions {
  key: string;
  value: string;
}

interface FormSelectMultiModel {
  id: string;
  isValid: boolean;
  errorId: string;
  label: string;
  name: string;
  value: string;
  options: FormSelectMultiModelOptions[];
  onChange: (event: FormEvent<HTMLInputElement>) => void;
}

interface FormSelectMultiProps {
  selects: FormSelectMultiModel[];
}

export const FormSelectMulti: FunctionComponent<FormSelectMultiProps> = ({ selects }) => (
  <StyledFormSelectMulti>
    {selects.map((select: FormSelectMultiModel) => (
      <Fragment key={select.id}>
        <label>{select.label}</label>
        <StyledFieldSelect
          component="select"
          id={select.id}
          data-testid={select.id}
          name={select.id}
          value={select.value}
          onChange={select.onChange}
          aria-describedby={select.errorId ? select.errorId : select.id}
          aria-required={true}
          aria-invalid={!select.isValid}
        >
          {select.options.map((option: FormSelectMultiModelOptions) => (
            <option key={option.key} value={option.value}>
              {option.value}
            </option>
          ))}
        </StyledFieldSelect>
      </Fragment>
    ))}
  </StyledFormSelectMulti>
);
