import { AddressDetailsAction } from '@modules/domain/address/dux';
import { AddressDetailsModel } from '@modules/domain/address/dux/state/address-details.state';
import { createContext, Dispatch } from 'react';

export interface ContextProps {
  addressState: AddressDetailsModel;
  dispatch: Dispatch<AddressDetailsAction>;
}

export const AddressContext = createContext({} as ContextProps);
