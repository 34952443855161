import React, { FunctionComponent, useEffect, useState } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { Redirect } from 'react-router-dom';

import { UserSessionState } from '@partnerships/common/modules/user-session';
import { getUserSession, terminateUserSession } from '@partnerships/common/modules/user-session/user-session-utils';
import { SESSION_STATUS_QUERY, SessionStatusQueryResponse } from '.';
import { ErrorRoutes } from '../routes';

const SessionTimeout: FunctionComponent = ({ children }): JSX.Element => {
  const validSessionStates = [
    UserSessionState.active,
    UserSessionState.signUp,
    UserSessionState.reactivation,
    UserSessionState.sessionExpired,
  ] as string[];
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const { refetch } = useQuery<SessionStatusQueryResponse>(SESSION_STATUS_QUERY, {
    skip: true,
  });

  const getSessionStatus = async (): Promise<any> => {
    return fetch('/web-api/getSessionStatus', {
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      method: 'GET',
      credentials: 'include',
    });
  };

  useEffect(() => {
    getUserSession().then(result => {
      const state = result.userSession.state;
      if (validSessionStates.includes(state)) {
        getSessionStatus()
          .then(response => {
            return response.json();
          })
          .then(query => {
            if (query.data && query.data.sessionStatus === 'expired') {
              if (state !== UserSessionState.sessionExpired) {
                terminateUserSession(UserSessionState.sessionExpired);
              }

              setShouldRedirect(true);
            }
          })
          .catch(err => {
            console.log('Error Reading data ' + err);
          });
      }
    });
  }, [window.location.pathname]);

  if (shouldRedirect && window.location.pathname !== ErrorRoutes.SessionTimeout) {
    return <Redirect to={{ pathname: ErrorRoutes.SessionTimeout }} />;
  } else {
    return <>{children}</>;
  }
};

export { SessionTimeout };
