import { spacers } from '@assets/styles/settings';
import styled from '@assets/styles/styled';

interface StyledDividerProps {
  space?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
}

export const StyledDivider = styled.hr<StyledDividerProps>`
  height: 1px;
  margin: ${({ space }) => spacers[space || 4]} 0;
  background-color: ${({ theme }) => theme.colors.baseMediumGrey};
  border: none;

  @media print {
    border: 1px solid ${({ theme }) => theme.colors.baseMediumGrey};
  }
`;
