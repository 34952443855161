import { rem, spacers } from '@assets/styles/settings';
import styled from '@assets/styles/styled';

interface StyledListUnorderedProps {
  leftMargin?: number;
}

export const StyledListUnordered = styled.ul<StyledListUnorderedProps>`
  margin-bottom: ${rem(24)};
  padding-left: ${rem(18)};
  list-style: disc;

  li {
    margin-left: ${({ leftMargin }) => rem(leftMargin || 18)};
    padding: ${rem(7)};
  }
`;
