// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';

import styled from '@assets/styles/styled';
import { css } from 'styled-components';

import { InfoBlockProps } from './info-block';

const icons: any = {
  info: 'info.svg',
};

export const StyledInfoBlock = styled.div<InfoBlockProps>`
  padding: 2rem 1.875rem;
  position: relative;
  align-self: flex-start;
  margin-top: 1.5rem;

  & {
    flex: 0 1 100%
    ${({ theme }) => theme.media('15', `
      flex: 0 1 35%;
    `)}
  }
  ${props => props.withBorder && css`
     border-top: 1px solid ${({ theme }) => theme.colors.baseOutline};
     ${({ theme }) => theme.media('15', `
       border: 1px solid ${theme.colors.baseOutline};
    `)};
  `}

  ${({ theme }) => theme.mediaEnd('15', `
    margin-top: 3.125rem;
  `)}
`;

export const Content = styled(Typography.Copy.Text)`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.75rem;
`;

export const Heading = styled(Typography.Headings.Tertiary)`
  text-align: center;
  color: ${({ theme }) => theme.colors.baseDarkGrey};

  ${({ theme }) => theme.media('15', `
    text-align: left;
  `)};
`;

export const IconContainer = styled.div`
  padding: 2px;
  position: absolute;
  top: -0.55rem;
  left: 1.25rem;
  width: 3.562rem;
  height: 2.13rem;
  transform: translateY(-50%);
  background-color: #fff;
  text-align: center;
`;

export const Icon = styled.i<{name: string}>`
  display: inline-block;
  width: 2.937rem;
  height: 2.937rem;
  color: ${({ theme }) => theme.colors.experianDarkBlue};
  text-align: center;
  font-size: 1.6rem;
  background-repeat: no-repeat;
  background-size: contain;

  ${props => props.name && css`
    background-image: url(/cpp-assets/images/${icons[props.name]});
  `}
`;


export const InfoBlockLeftContent = styled.div`
  flex: 1 0 100%;

  ${({ theme }) => theme.media('15', `
    flex: 2;
    margin-right: 8rem;
  `)}
`;
// container
export const InfoBlockFlexContainer = styled.div<InfoBlockProps>`
  border-top: 1px solid ${({ theme }) => theme.colors.baseOutline};
  padding: 1.25rem 0;
  display: flex;
  flex-wrap: wrap;
  ${props => props.theme.noBorderTop && `
    border-top: none;
  `}
  ${({ theme }) => theme.media('15', `
    display: flex;
    padding: 1.875rem 0;
  `)}
`;
