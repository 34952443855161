import styled from '@assets/styles/styled';
import { string } from 'prop-types';

interface StyledTwoColumnContainerProps {
  justifyContent?: string;
  sideBar?: boolean;
}

export const StyledTwoColumnContainer = styled.section<StyledTwoColumnContainerProps>`
  * > {
    flex-basis: 100%;
  }
   
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({justifyContent}) => justifyContent ? justifyContent : 'space-between'};
  
  ${({sideBar}) => sideBar ? 
  ({ theme: { media } }) => media('15', `
    &>:first-child {
      flex-basis: calc(60% - 3rem);
    } 
    
    &>:last-child {
      flex-basis: 33%;
    }  
  `) : ''}
`;
