// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import { ModalBox } from '@modules/core/modal-box';
import { StyledSpacer } from '@modules/core/spacer';
import { CallCharges, OpeningDays, OpeningTimes, PrimaryContactNumber } from '@modules/domain/call-card';
import React, { FunctionComponent } from 'react';

interface EditProfileModalProps {
  isVisible: boolean;
  policyNumber: string;
}

const EditProfileModal: FunctionComponent<EditProfileModalProps> = ({ isVisible, policyNumber }): JSX.Element => {
  return (
    <ModalBox isOpen={isVisible} title="Editing your details modal">
      <div data-testid="edit-your-details-modal">
        <Typography.Headings.Secondary>Editing your details</Typography.Headings.Secondary>

        <Typography.Copy.Text>
          If you need to make a change to any of your details please call the CPP team. Your policy number is <strong>{policyNumber}</strong>
        </Typography.Copy.Text>

        <StyledSpacer space={3}>
          <PrimaryContactNumber />
        </StyledSpacer>

        <Typography.Copy.Text>
          <strong>{OpeningDays.WeekDay}: </strong>
          {OpeningTimes.FullDay}
        </Typography.Copy.Text>

        <CallCharges />
      </div>
    </ModalBox>
  );
};

EditProfileModal.displayName = 'edit-profile-modal';

export { EditProfileModal };
