import React, { useEffect, useState } from 'react';
import { SecureRoute } from 'react-route-guard';
import { Redirect, Route, Switch } from 'react-router-dom';

import * as appRoutes from '@app/routes';
import * as pages from '@pages';
import {
  ActiveStateRouteGuard,
  SignUpOrReactivationOrAwaitingStateRouteGuard,
  ValidSessionStateRouteGuard,
} from '@partnerships/common/modules/auth/guards';
import { SessionTimeout } from '@partnerships/common/modules/session-timeout';
import { UserSession, UserSessionState } from '@partnerships/common/modules/user-session';
import { getUserSession } from '@partnerships/common/modules/user-session/user-session-utils';

export const HomeIndexRouteRender: React.FunctionComponent = () => {
  const [userSession, setUserSession] = useState<UserSession>();

  useEffect(() => {
    let inProgress = true;
    if (inProgress) {
      getUserSession().then(result => {
        setUserSession(result.userSession);
      });
    }
    return () => {
      inProgress = false;
    };
  }, []);

  if (!userSession) {
    return null;
  }

  switch (userSession.state) {
    case UserSessionState.active:
      window.location.href = '/landing';
      return null;
    case UserSessionState.awaitingOnboard:
      return <Redirect to={{ pathname: appRoutes.SignUpRoutes.Activation }} />;
    case UserSessionState.signUp:
      return <Redirect to={{ pathname: appRoutes.SignUpRoutes.Index }} />;
    case UserSessionState.reactivation:
      return <Redirect to={{ pathname: appRoutes.ReactivationRoutes.Index }} />;
    case UserSessionState.awaitingApproval:
      return <Redirect to={{ pathname: appRoutes.ErrorRoutes.AwaitingApproval }} />;
    case UserSessionState.notEligible:
      return <Redirect to={{ pathname: appRoutes.ErrorRoutes.Root }} />;
    case UserSessionState.notApproved:
      return <Redirect to={{ pathname: appRoutes.ErrorRoutes.NotApproved }} />;
    case UserSessionState.serviceUnavailable:
      return <Redirect to={{ pathname: appRoutes.ErrorRoutes.Root }} />;
    case UserSessionState.genericError:
      return <Redirect to={{ pathname: appRoutes.ErrorRoutes.Root }} />;
    case UserSessionState.sessionExpired:
      return <Redirect to={{ pathname: appRoutes.ErrorRoutes.SessionTimeout }} />;
    default:
      return <Redirect to={{ pathname: appRoutes.ErrorRoutes.Unauthorised }} />;
  }
};

export const AppRoutes: React.FunctionComponent = () => {
  return (
    <SessionTimeout>
      <Switch>
        <Route exact key={appRoutes.HomeRoutes.Index} path={appRoutes.HomeRoutes.Index} component={HomeIndexRouteRender} />

        <SecureRoute
          key={appRoutes.SignUpRoutes.Index}
          path={appRoutes.SignUpRoutes.Index}
          routeGuard={SignUpOrReactivationOrAwaitingStateRouteGuard.create()}
          redirectToPathWhenFail={appRoutes.ErrorRoutes.Unauthorised}
          component={pages.SignUpPagesContainer}
        />

        <SecureRoute
          key={appRoutes.ReactivationRoutes.Index}
          path={appRoutes.ReactivationRoutes.Index}
          routeGuard={ValidSessionStateRouteGuard.create()}
          redirectToPathWhenFail={appRoutes.ErrorRoutes.Unauthorised}
          component={pages.ReactivationPagesContainer}
        />

        <SecureRoute
          key={appRoutes.RightsRoutes.Index}
          path={appRoutes.RightsRoutes.Index}
          routeGuard={ValidSessionStateRouteGuard.create()}
          redirectToPathWhenFail={appRoutes.ErrorRoutes.Unauthorised}
          component={pages.StatementOfRightsPage}
        />

        <SecureRoute
          key={appRoutes.ContactRoutes.Index}
          path={appRoutes.ContactRoutes.Index}
          routeGuard={ValidSessionStateRouteGuard.create()}
          redirectToPathWhenFail={appRoutes.ErrorRoutes.Unauthorised}
          component={pages.SupportPage}
        />

        <SecureRoute
          exact
          key={appRoutes.SupportRoutes.Index}
          path={appRoutes.SupportRoutes.Index}
          routeGuard={ValidSessionStateRouteGuard.create()}
          redirectToPathWhenFail={appRoutes.ErrorRoutes.Unauthorised}
          component={pages.SupportPage}
        />

        <SecureRoute
          exact
          key={appRoutes.ProfileRoutes.Index}
          path={appRoutes.ProfileRoutes.Index}
          routeGuard={ActiveStateRouteGuard.create()}
          redirectToPathWhenFail={appRoutes.ErrorRoutes.Unauthorised}
          component={pages.ProfilePage}
        />

        <SecureRoute
          exact
          key={appRoutes.CookieRoutes.Index}
          path={appRoutes.CookieRoutes.Index}
          routeGuard={ValidSessionStateRouteGuard.create()}
          redirectToPathWhenFail={appRoutes.ErrorRoutes.Unauthorised}
          component={pages.CookiesPage}
        />

        <Route
          key={appRoutes.ErrorRoutes.SessionTimeout}
          path={appRoutes.ErrorRoutes.SessionTimeout}
          component={pages.SessionTimeoutPage}
        />

        <Route key={appRoutes.ErrorRoutes.Unauthorised} path={appRoutes.ErrorRoutes.Unauthorised} component={pages.UnauthorisedPage} />

        <Route
          key={appRoutes.ErrorRoutes.AwaitingApproval}
          path={appRoutes.ErrorRoutes.AwaitingApproval}
          component={pages.AwaitingApprovalPage}
        />

        <Route key={appRoutes.ErrorRoutes.NotApproved} path={appRoutes.ErrorRoutes.NotApproved} component={pages.NotApprovedPage} />

        <Route key={appRoutes.ErrorRoutes.NotEligible} path={appRoutes.ErrorRoutes.NotEligible} component={pages.GenericErrorPage} />

        <Route key={appRoutes.ErrorRoutes.Root} path={appRoutes.ErrorRoutes.Root} component={pages.GenericErrorPage} />

        <Route component={pages.NotFoundPage} />
      </Switch>
    </SessionTimeout>
  );
};
