import React, { FunctionComponent } from 'react';

import { StyledLabel } from '../select-multi/select-multi.styled';
import { StyledErrorMessage, StyledFormGroup, StyledInputWrap } from './form-group.styled';

interface FormGroupProps {
  id: string;
  errorId?: string;
  labelText: string;
  value?: string;
  errors?: string;
  touched?: boolean;
  required: boolean;
  flexBasis?: string;
  paddingTop?: string;
  marginTop?: string;
  maxWidth?: string;
}

export const FormGroup: FunctionComponent<FormGroupProps> = ({
  id,
  errorId,
  children,
  labelText,
  value,
  errors,
  touched,
  flexBasis,
  paddingTop,
  marginTop,
  maxWidth,
}) => (
  <StyledFormGroup flexBasis={flexBasis} paddingTop={paddingTop} marginTop={marginTop} maxWidth={maxWidth}>
    <StyledLabel htmlFor={id}>{labelText}</StyledLabel>
    <StyledInputWrap>
      {children}
      {errors && touched && <StyledErrorMessage id={errorId ? errorId : id}>{errors}</StyledErrorMessage>}
    </StyledInputWrap>
  </StyledFormGroup>
);
