// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';

import moment from 'moment';
import React, { FunctionComponent } from 'react';

import { DefinitionList } from '@modules/core/definition-list';
import { SegmentBlock } from '@modules/core/segment-block/segment-block';

interface SectionPersonalDetailsProps {
  customerDetails: {
    titledFullName: string;
    dob: string;
  };
  handleEditButtonClick?: () => void;
}
const PersonalDetails: FunctionComponent<SectionPersonalDetailsProps> = ({ customerDetails, handleEditButtonClick }): JSX.Element => {
  const dateOfBirthDate = moment(customerDetails.dob);

  return (
    <section data-testid="profile-personal-details">
      <Typography.Headings.Secondary>Personal details</Typography.Headings.Secondary>

      <SegmentBlock handleEditButtonClick={handleEditButtonClick}>
        <DefinitionList>
          <dt>Name:</dt>
          <dd>
            <strong>{customerDetails.titledFullName}</strong>
          </dd>
          <dt>Date of Birth:</dt>
          <dd>
            <strong>{dateOfBirthDate.isValid() ? dateOfBirthDate.format('DD/MM/YYYY') : 'Not supplied'}</strong>
          </dd>
        </DefinitionList>
      </SegmentBlock>
    </section>
  );
};

PersonalDetails.displayName = 'personal-details';

export { PersonalDetails };
