import ApolloClient from 'apollo-client';

import { existingUserSessionFactory, UserSessionState, UserSessionUpdate } from '@partnerships/common/modules/user-session';

import { GET_USER_AUTH_STATE, OnboardCpp, OnboardCppResponse, PERFORM_ONBOARD_CPP } from './data';

const setErrorState = async (): Promise<void> => {
  await existingUserSessionFactory.merge({ state: UserSessionState.genericError });
};

export const checkAuthState = async (client: ApolloClient<any>): Promise<boolean> => {
  let authState;

  try {
    const result = await client.query({
      query: GET_USER_AUTH_STATE,
    });
    authState = result.data.userAuthState;
  } finally {
    if (authState) {
      if (authState !== UserSessionState.signUp) {
        await existingUserSessionFactory.merge(authState);
      }
    } else {
      await setErrorState();
    }
  }

  return authState && authState.state === UserSessionState.signUp;
};

export const onboard = async (client: ApolloClient<any>): Promise<boolean> => {
  const currentSession = await existingUserSessionFactory.create();
  const userSession = currentSession.userSession;

  const clientId = userSession.clientId;

  const onboardRequest = {
    clientId,
  };

  let session;

  try {
    session = await onboardForClient(client, onboardRequest);
  } finally {
    if (session) {
      await existingUserSessionFactory.merge(session);
    } else {
      await setErrorState();
    }
  }

  return session && session.state === UserSessionState.active;
};

const onboardForClient = async (client: ApolloClient<any>, onboardRequest: OnboardCpp): Promise<UserSessionUpdate> => {
  const clientId = onboardRequest.clientId;
  if (clientId === 'cpp') {
    const cppOnboardResult = await client.mutate<OnboardCppResponse>({
      mutation: PERFORM_ONBOARD_CPP,
      variables: { request: onboardRequest },
    });
    return cppOnboardResult.data!.onboardCpp;
  }

  throw new Error(`Unrecognized clientId: ${clientId}`);
};
