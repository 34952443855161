import { colors, rem, spacers } from '@assets/styles/settings';
import styled from '@assets/styles/styled';

export const StyledSelect = styled.select`
  width: 100%;
  height: ${rem(50)};
  padding-left: ${spacers[2]};
  background: white;
  border: 1px solid ${colors.border};

  &:focus {
    background: ${colors.bg};
    border-color: ${props => props.theme.primary};
  }
`;
