import { AddressHistoryItem, CurrentAddressModel, PreviousAddress } from '@modules/domain/address/models';

export type AddressDetailsAction =
  | { type: 'addCurrentAddress'; currentAddress: CurrentAddressModel }
  | { type: 'setPreviousAddresses'; addressHistoryItems: AddressHistoryItem[]; updatePending: boolean }
  | { type: 'addPreviousAddress'; addressHistoryItem: AddressHistoryItem }
  | { type: 'editPreviousAddress'; previousAddress: PreviousAddress }
  | { type: 'cancelOverwritePreviousAddress' }
  | { type: 'currentAddressMonthChanged'; newValue: string }
  | { type: 'currentAddressYearChanged'; newValue: string }
  | { type: 'confirmedCurrentAddressResidencyFromDate' }
  | { type: 'overwritePreviousAddressesConfirmed' }
  | { type: 'resetAddressUpdatePending' };
