interface AddressTypes {
  currentAddress: string;
  previousAddress: string;
}

const ADDRESS_TYPE: AddressTypes = {
  currentAddress: 'C',
  previousAddress: 'P',
};

export { ADDRESS_TYPE };
