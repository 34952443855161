// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent } from 'react';

import { PdfRoutes } from '@app/routes';

import { DocumentLink } from '@modules/core/document-link';
import { InputCheckbox } from '@modules/core/input-checkbox';
import { CheckboxIcons } from '@modules/core/input-checkbox/input-checkbox';
import { StyledSpacer } from '@modules/core/spacer';

interface TermsSectionProps {
  isChecked: boolean;
  onCheckedChanged: (value: boolean) => void;
}

export const TermsSection: FunctionComponent<TermsSectionProps> = ({ isChecked, onCheckedChanged }) => (
  <>
    <Typography.Headings.Secondary>Terms & Conditions</Typography.Headings.Secondary>
    <StyledSpacer>
      <Typography.Copy.Text>
        It is important that you understand the terms of our contract with you, which are the Experian Terms & Conditions and Privacy Policy
        for Experian Identity Plus. For your own benefit and protection, you should read these terms before proceeding. If you don’t
        understand any point, or if you have any questions, please contact us. If for any reason you are not satisfied with Experian
        Identity Plus, you are free to cancel your membership by contacting the CPP customer services team.
      </Typography.Copy.Text>
    </StyledSpacer>
    <StyledSpacer>
      <InputCheckbox
        id="terms-conditions"
        name="terms-conditions"
        value=""
        size={32}
        checked={isChecked}
        disabled={false}
        icon={CheckboxIcons.tick}
        onChange={() => onCheckedChanged(!isChecked)}
        testId="terms-conditions"
      >
        I accept the Experian <DocumentLink inline uri={PdfRoutes.TermsAndConditions} label="Terms &amp; Conditions" /> and{' '}
        <DocumentLink inline uri={PdfRoutes.PrivacyPolicy} label="Privacy Policy" /> for Experian Identity Plus and request that you provide
        the service immediately.
      </InputCheckbox>
    </StyledSpacer>
  </>
);
