import { UserSessionLocalStorageKey, UserSessionState, UserSessionStateModel } from './constants';
import { existingUserSessionFactory } from './existing/existing-user-session-factory';
import { unknownUserSessionFactory } from './unknown/unknown-user-session-factory';
import { UserSession, UserSessionResult } from './user-session.query';

export const getUserSession = async (): Promise<UserSessionResult> => {
  return await getExistingUserSession();
};

export const getExistingUserSession = async (): Promise<UserSessionResult> => {
  return !!localStorage.getItem(UserSessionLocalStorageKey)
    ? Promise.resolve<UserSessionResult>(existingUserSessionFactory.create())
    : Promise.resolve<UserSessionResult>(unknownUserSessionFactory.create());
};

export const removeCookies = async (): Promise<any> => {
  return fetch('/graphql', {
    body: JSON.stringify({ query: `{ logout }` }),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    credentials: 'same-origin',
  });
};

export const terminateUserSession = async (state: UserSessionStateModel = UserSessionState.badAuth): Promise<UserSession | null> => {
  const existingSession = localStorage.getItem(UserSessionLocalStorageKey);
  let updatedSession: UserSession;

  if (existingSession) {
    updatedSession = JSON.parse(existingSession);
    updatedSession.state = state;
    localStorage.setItem(UserSessionLocalStorageKey, JSON.stringify(updatedSession));
    return Promise.resolve<UserSession>(updatedSession);
  }

  return Promise.reject<null>('No existing session');
};
