// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent } from 'react';

import { PageLayout } from '@modules/domain/page-layout';

export const AwaitingApprovalPage: FunctionComponent = (): JSX.Element => (
  <PageLayout title="We are processing your application" metaTitle="Processing application">
    <>
      <Typography.Copy.Text>
        In order to process your application, we need to carry out further checks on the information you provided. We do this to protect
        your information and to ensure that only you can access your credit report.
      </Typography.Copy.Text>
      <Typography.Copy.Text>
        We normally complete these checks within two working days and once we have, we'll send you an email to let you know the outcome and
        any additional action you need to take. If you applied more than two working days ago and haven't received anything from us yet or
        if you have any further questions, please contact the customer services team on 0344 848 4277*. Lines are open Monday- Friday, 8am –
        6pm.
      </Typography.Copy.Text>
      <Typography.Copy.SmallText>
        *034 calls cost no more than calls to geographical numbers (01 or 02) and will be included as part of inclusive minutes in your
        package
      </Typography.Copy.SmallText>
    </>
  </PageLayout>
);
