// @ts-ignore
import { Button, Link } from '@experian-uk/corvetteuk-common-ui';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';

import { StyledErrorMessage } from '@modules/core/error-message';
import { flexDirection } from '@modules/core/flex-container';
import { StyledFlexDivContainer } from '@modules/core/flex-container/flex-container.styled';
import { StyledFlexRow } from '@modules/core/flex-row';
import { FormEdit } from '@modules/core/form-edit';
import { StyledFieldInput } from '@modules/core/form-field';
import { FormGroup } from '@modules/core/form-group';
import { FormRow } from '@modules/core/form-row/form-row';
import { StyledFormSpan } from '@modules/domain/address/verified-address-form/verified-address-form.styled';
import { AddAddressFormProps } from '../props/add-address.props';
import { VerifiedAddressFormModel } from './verified-address-form.model';
import { verifiedAddressFormSchema } from './verified-address-form.schema';

const VerifiedAddressForm: FunctionComponent<AddAddressFormProps<VerifiedAddressFormModel>> = ({
  initialFormValues,
  onCloseForm,
  onFormSubmit,
}): JSX.Element => (
  <>
    <Formik initialValues={initialFormValues} onSubmit={values => onFormSubmit(values)} validationSchema={verifiedAddressFormSchema}>
      {({ errors, setFieldValue, touched, values }) => (
        <FormEdit handleClose={onCloseForm} submitButtonText="Look up address" hideSubmitButton={true}>
          <StyledFlexRow reversed wrap={true}>
            <Link.Anchor href="JavaScript:void(0);" onClick={onCloseForm}>
              Add later
            </Link.Anchor>
            <FormRow justifyContent="flex-start" maxWidth="448px">
              <FormGroup
                id="houseNumber"
                labelText="House number"
                value={values.houseNumber}
                required={false}
                flexBasis="180px"
                paddingTop="0"
                marginTop="0"
              >
                <StyledFieldInput
                  type="text"
                  id="houseNumber"
                  name="houseNumber"
                  data-testid="houseNumber"
                  value={values.houseNumber ? values.houseNumber : ''}
                  aria-describedby="houseNumber-error"
                  aria-required={true}
                  aria-invalid={errors.houseNumber !== undefined}
                  spellCheck={false}
                />
              </FormGroup>
              <StyledFlexDivContainer flex="0 1 auto" direction={flexDirection.ROW}>
                <StyledFormSpan>
                  <strong>OR</strong>
                </StyledFormSpan>
              </StyledFlexDivContainer>
              <FormGroup
                id="houseName"
                labelText="House name"
                value={values.houseName ? values.houseName : ''}
                required={false}
                flexBasis="180px"
                paddingTop="0"
                marginTop="0"
              >
                <StyledFieldInput
                  type="text"
                  id="houseName"
                  name="houseName"
                  data-testid="houseName"
                  value={values.houseName ? values.houseName : ''}
                  aria-describedby="houseName-error"
                  aria-required={true}
                  aria-invalid={errors.houseName !== undefined}
                  spellCheck={false}
                />
              </FormGroup>
            </FormRow>
          </StyledFlexRow>
          {errors.houseNumber && touched.houseNumber && <StyledErrorMessage id="houseNumber">{errors.houseNumber}</StyledErrorMessage>}
          {!errors.houseNumber && errors.houseName && touched.houseName && (
            <StyledErrorMessage id="houseName">{errors.houseName}</StyledErrorMessage>
          )}
          <FormRow justifyContent="flex-start" wrap={true}>
            <FormGroup
              id="postCode"
              labelText="Postcode"
              value={values.postCode}
              touched={touched.postCode}
              required={true}
              flexBasis="270px"
            >
              <StyledFieldInput
                type="text"
                id="postCode"
                name="postCode"
                data-testid="postCode"
                value={values.postCode}
                aria-describedby="postCode-error"
                aria-required={true}
                aria-invalid={errors.postCode !== undefined}
                flexbasis="270px"
                spellCheck={false}
              />
            </FormGroup>
            <StyledFlexDivContainer direction={flexDirection.COLUMN} justify="flex-end" align="flex-start">
              <Button type="secondary" data-testid="find-address" label="Find Address" isButton aria-label="Find Address Button" />
            </StyledFlexDivContainer>
          </FormRow>
          {errors.postCode && touched.postCode && <StyledErrorMessage id="postCode">{errors.postCode}</StyledErrorMessage>}
        </FormEdit>
      )}
    </Formik>
  </>
);

VerifiedAddressForm.displayName = 'verified-address-form';

export { VerifiedAddressForm };
