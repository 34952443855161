import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import { RouterWithStyles } from '@app/router-with-styles';
import { createApolloClient } from '@partnerships/common/app/create-apollo-client';

const apolloClient = createApolloClient();

export const App = (): JSX.Element => (
  <ApolloProvider client={apolloClient}>
    <ApolloHooksProvider client={apolloClient}>
      <RouterWithStyles />
    </ApolloHooksProvider>
  </ApolloProvider>
);
