// ========================================================================
// BASE/RESET
// ========================================================================

export const reset = `
  body,
  blockquote,
  fieldset,
  figure {
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  ol,
  ul {
    padding-left: 0;
  }

  dd {
    margin-left: 0;
  }

  fieldset,
  button {
    padding: 0;
  }

  legend {
    padding-left: 0;
  }
`;
