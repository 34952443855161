export interface PersonalDetailsFormModel {
  title: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  dateOfBirthDay?: string;
  dateOfBirthMonth?: string;
  dateOfBirthYear?: string;
  emailAddress: string;
  contactNumber: string;
  secondaryPhoneNumber?: string;
}
