import gql from 'graphql-tag';

export const UPDATE_PREVIOUS_ADDRESSES = gql`
  mutation updatePreviousAddresses($addresses: [UpdatedCustomerAddressInput]!) {
    updatePreviousAddresses(addresses: $addresses) {
      addressType
      city
      country
      county
      district
      flat
      houseNumber
      houseName
      isVerified
      manual
      abroad
      postCode
      street
      lastUpdatedDt
      fromDt
      toDt
    }
  }
`;
