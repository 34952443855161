import styled from '@assets/styles/styled';

export const StyledNotePanel = styled.div`
  background-color: ${props => props.theme.colors.baseLightGrey};
  border: 1px solid #e4e4e4;
  padding: 1.2rem;
  font-size: 1rem;
  font-weight: 400;
  
`;
