import { Formik, FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent, FormEvent, FunctionComponent, useState } from 'react';
import { Query } from 'react-apollo';

import { Error } from '@modules/core/error';
import { FormEdit } from '@modules/core/form-edit';
import { FormGroup } from '@modules/core/form-group';
import { StyledFormRow } from '@modules/core/form-row/form-row.styled';
import { FormSelectMulti } from '@modules/core/form-select-multi';
import { Loading } from '@modules/core/loading';
import { StyledSelect } from '@modules/core/select/select.styled';
import { StyledSpacer } from '@modules/core/spacer';
import { defaultMonthValue, defaultYearValue } from '@modules/core/validation';
import { getMonthsValues, getYearsValues } from '@utils/date-values';
import { VerifiedAddressFormModel } from '../verified-address-form/verified-address-form.model';
import { AddressLookUpItemsQueryResult, GET_ADDRESS_LOOKUP_ITEMS_QUERY } from './address-look-up.queries';
import { addressLookUpSchema } from './address-look-up.schema';
import { AddressLookUpItem, AddressLookUpItemMaybeRecord, AddressLookUpItemRecord, AddressLookUpItemRecordNotFound } from './models';

const defaultLookUpItemId = '0';
const defaultLookUpValue = 'Please select your address';

const myAddressIsNotListedItemId = '1';
const myAddressIsNotListedItemValue = 'My address is not listed here';

interface AddressLookUpProps<TFormModel> {
  initialFormValues: TFormModel;
  house: string;
  postCode: string;
  onAddressSelected: (formValues: TFormModel, record: AddressLookUpItemMaybeRecord) => void;
  onCloseForm: () => void;
  onMyAddressIsNotListed: (formValues: TFormModel) => void;
}

const AddressLookUp: FunctionComponent<AddressLookUpProps<VerifiedAddressFormModel>> = ({
  initialFormValues,
  house,
  postCode,
  onAddressSelected,
  onCloseForm,
  onMyAddressIsNotListed,
}): JSX.Element => {
  const [selectedLookUpItemId, setSelectedLookUpItemId] = useState<string>(defaultLookUpItemId);

  const getMonthSelectOptions = () => {
    const defaultOption = { key: defaultMonthValue, value: defaultMonthValue };
    const monthOptions = getMonthsValues().map(month => ({ key: month, value: month }));
    return [defaultOption, ...monthOptions];
  };

  const getYearSelectOptions = () => {
    const defaultOption = { key: defaultYearValue, value: defaultYearValue };
    const monthOptions = getYearsValues().map(year => ({ key: year, value: year }));
    return [defaultOption, ...monthOptions];
  };

  const handleResidentFromFormGroupErrors = (
    errors: FormikErrors<VerifiedAddressFormModel>,
    touched: FormikTouched<VerifiedAddressFormModel>,
  ): string | undefined => {
    if (errors.fromMonth && touched.fromMonth) {
      return errors.fromMonth;
    }

    if (errors.fromYear && touched.fromYear) {
      return errors.fromYear;
    }

    return undefined;
  };

  const handleResidentToFormGroupErrors = (
    errors: FormikErrors<VerifiedAddressFormModel>,
    touched: FormikTouched<VerifiedAddressFormModel>,
  ): string | undefined => {
    if (errors.toMonth && touched.toMonth) {
      return errors.toMonth;
    }

    if (errors.toYear && touched.toYear) {
      return errors.toYear;
    }

    return undefined;
  };

  return (
    <Query<AddressLookUpItemsQueryResult> query={GET_ADDRESS_LOOKUP_ITEMS_QUERY} variables={{ house, postCode }}>
      {({ data, error, loading }) => {
        if (loading || !data) {
          return <Loading />;
        }

        if (error) {
          return <Error error="Unable to get addresses" />;
        }

        return (
          <Formik
            initialValues={initialFormValues}
            onSubmit={values => {
              const match = data.postCodeLookupItems.find((item: AddressLookUpItem) => item.id === selectedLookUpItemId);
              const addressLookUpRecord = match ? new AddressLookUpItemRecord(match) : new AddressLookUpItemRecordNotFound();
              onAddressSelected(values, addressLookUpRecord);
            }}
            validationSchema={addressLookUpSchema}
          >
            {({ errors, setFieldValue, touched, values }) => (
              <FormEdit
                disabled={selectedLookUpItemId === defaultLookUpItemId}
                handleClose={onCloseForm}
                submitButtonText="Add Address"
                submitButtonTestId="add-address"
              >
                <StyledFormRow>
                  <FormGroup id="address" labelText="Address" required={false} flexBasis="100%">
                    <StyledSelect
                      value={selectedLookUpItemId}
                      data-testid="select-address"
                      onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                        event.preventDefault();
                        setSelectedLookUpItemId(event.currentTarget.value);
                        if (event.currentTarget.value === myAddressIsNotListedItemId) {
                          onMyAddressIsNotListed(values);
                        }
                      }}
                    >
                      <option key={defaultLookUpItemId} value={defaultLookUpItemId}>
                        {defaultLookUpValue}
                      </option>

                      {data.postCodeLookupItems.map((item: AddressLookUpItem) => (
                        <option key={item.id} value={item.id}>
                          {item.text}
                        </option>
                      ))}

                      <option key={myAddressIsNotListedItemId} value={myAddressIsNotListedItemId}>
                        {myAddressIsNotListedItemValue}
                      </option>
                    </StyledSelect>
                  </FormGroup>
                </StyledFormRow>

                <StyledFormRow>
                  <FormGroup
                    id="residentFrom"
                    errorId="residentFromErrorGroup"
                    labelText="When did you move into this address?"
                    errors={handleResidentFromFormGroupErrors(errors, touched)}
                    value={values.fromMonth && values.fromYear}
                    touched={touched.fromMonth || touched.toMonth}
                    required={true}
                    flexBasis="100%"
                  >
                    <FormSelectMulti
                      selects={[
                        {
                          label: 'Month',
                          name: 'Month',
                          id: 'fromMonth',
                          isValid: !errors.fromMonth,
                          errorId: 'residentFromErrorGroup',
                          options: getMonthSelectOptions(),
                          value: values.fromMonth,
                          onChange: (event: FormEvent<HTMLInputElement>) => {
                            const { name, value } = event.currentTarget;
                            setFieldValue(name, value);
                          },
                        },
                        {
                          label: 'Year',
                          name: 'Year',
                          id: 'fromYear',
                          isValid: !errors.fromYear,
                          errorId: 'residentFromErrorGroup',
                          options: getYearSelectOptions(),
                          value: values.fromYear,
                          onChange: (event: FormEvent<HTMLInputElement>) => {
                            const { name, value } = event.currentTarget;
                            setFieldValue(name, value);
                          },
                        },
                      ]}
                    />
                  </FormGroup>
                </StyledFormRow>
                <StyledFormRow>
                  <FormGroup
                    id="residentTo"
                    errorId="residentToErrorGroup"
                    labelText="When did you move out of this address?"
                    value={values.toMonth && values.toYear}
                    errors={handleResidentToFormGroupErrors(errors, touched)}
                    touched={touched.toMonth || touched.toYear}
                    required={true}
                    flexBasis="100%"
                  >
                    <FormSelectMulti
                      selects={[
                        {
                          label: 'Month',
                          id: 'toMonth',
                          isValid: !errors.toMonth,
                          errorId: 'residentToErrorGroup',
                          name: 'Month',
                          options: getMonthSelectOptions(),
                          value: values.toMonth,
                          onChange: (event: FormEvent<HTMLInputElement>) => {
                            const { name, value } = event.currentTarget;
                            setFieldValue(name, value);
                          },
                        },
                        {
                          label: 'Year',
                          id: 'toYear',
                          isValid: !errors.toYear,
                          errorId: 'residentToErrorGroup',
                          name: 'Year',
                          options: getYearSelectOptions(),
                          value: values.toYear,
                          onChange: (event: FormEvent<HTMLInputElement>) => {
                            const { name, value } = event.currentTarget;
                            setFieldValue(name, value);
                          },
                        },
                      ]}
                    />
                  </FormGroup>
                </StyledFormRow>
                <StyledSpacer />
              </FormEdit>
            )}
          </Formik>
        );
      }}
    </Query>
  );
};

AddressLookUp.displayName = 'address-lookup';

export { AddressLookUp };
