import React, { FunctionComponent } from 'react';

import { DefinitionList } from '@modules/core/definition-list';
import { SegmentBlock } from '@modules/core/segment-block/segment-block';

interface SectionContactDetailsProps {
  contactDetails: {
    emailAddress?: string;
    contactNumber?: string;
    secondaryPhoneNumber?: string;
  };
  handleEditButtonClick?: () => void;
}
export const SectionContactDetails: FunctionComponent<SectionContactDetailsProps> = ({
  contactDetails,
  handleEditButtonClick,
}): JSX.Element => {
  const noDataText = 'Not supplied';

  return (
    <SegmentBlock handleEditButtonClick={handleEditButtonClick} data-testid="section-contact-details">
      <DefinitionList>
        <dt>Email address:</dt>
        <dd data-testid="idplus-email">
          <strong>{contactDetails.emailAddress ? contactDetails.emailAddress : noDataText}</strong>
        </dd>
        <dt>Home number:</dt>
        <dd data-testid="idplus-home">
          <strong>{contactDetails.contactNumber ? contactDetails.contactNumber : noDataText}</strong>
        </dd>
        <dt>Mobile number:</dt>
        <dd data-testid="idplus-mobile">
          <strong>{contactDetails.secondaryPhoneNumber ? contactDetails.secondaryPhoneNumber : noDataText}</strong>
        </dd>
      </DefinitionList>
    </SegmentBlock>
  );
};
