import React, { FunctionComponent } from 'react';

import { TermsSection } from '../../terms-section';
import { StyledTermsInformation } from './terms-information.styled';

interface TermsInformationProps {
  isChecked: boolean;
  onCheckedChanged: (value: boolean) => void;
}

export const TermsInformation: FunctionComponent<TermsInformationProps> = ({ isChecked, onCheckedChanged }) => (
  <StyledTermsInformation>
    <TermsSection isChecked={isChecked} onCheckedChanged={onCheckedChanged} />
  </StyledTermsInformation>
);
