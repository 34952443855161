import styled from 'styled-components';

interface ContainerStyledProps {
  hasVerticalPadding?: boolean;
  hasHorizontalPadding?: boolean;
}

export const ContainerStyled = styled.div<ContainerStyledProps>`
  max-width: 90%;
  margin: 0 auto;
  padding: 1.25rem 0;
  color: ${props => props.theme.colors.baseDarkGrey};
  font-weight: 300;
  font-size: ${props => props.theme.typography.baseFontSize};

  ${({ theme: { media } }) =>
    media(
      '15',
      `
    padding: 1.875rem 0;
  `,
    )}

  ${({ theme: { media } }) =>
    media(
      '30',
      `
    max-width: 1140px;
  `,
    )}
`;
