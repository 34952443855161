import { AddressContext } from '@modules/domain/address/address.context';
import { addressDetailsReducer } from '@modules/domain/address/dux';
import { addressState } from '@modules/domain/address/dux/state/address-details.state';
import React, { FunctionComponent, useReducer } from 'react';

const { Provider } = AddressContext;

export const AddressStateProvider: FunctionComponent = ({ children }): JSX.Element => {
  const [state, dispatch] = useReducer(addressDetailsReducer, addressState);

  return <Provider value={{ addressState: state, dispatch }}>{children}</Provider>;
};
