import React, { FunctionComponent } from 'react';
import { StyledEditButton } from '.';

interface EditButtonProps {
  handleClick: () => void;
}

export const EditButton: FunctionComponent<EditButtonProps> = ({ handleClick }): JSX.Element => (
  <StyledEditButton className="edit-button" type="button" onClick={handleClick} data-testid="edit-button">
    Edit
  </StyledEditButton>
);
