import moment from 'moment';

import { toDateIsAfterStartDate } from '@utils/date-values';
import { defaultMonthValue, defaultYearValue } from './constants';

export const testAddressPeriodsForValidationError = (fromMonth: string, fromYear: string, toMonth: string, toYear: string): boolean => {
  if (!fromMonth || !fromYear || !toMonth || !toYear) {
    return true;
  }

  const monthValuesAreDefault = fromMonth === defaultMonthValue || toMonth === defaultMonthValue;
  const yearValuesAreDefault = fromYear === defaultYearValue || toYear === defaultYearValue;

  if (monthValuesAreDefault && yearValuesAreDefault) {
    return true;
  }

  return toDateIsAfterStartDate(fromMonth, toMonth, fromYear, toYear);
};

export const testDateIsInTheFuture = (month: string, year: string): boolean => {
  const today = moment();
  const date = moment(`${year} ${month}`, 'YYYY MMM');
  return date.isAfter(today);
};
