// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import React from 'react';

import { PageLayout } from '@modules/domain/page-layout';

export const SessionTimeoutPage = (): JSX.Element => (
  <PageLayout title="Your session has ended" metaTitle="Session Ended">
    <div data-testid="error-session-timeout">
      <Typography.Copy.Text>
        For your security, we have ended your session after a period of inactivity. If you would like to resume using Identity Plus, please
        return to your account to begin a new session.
      </Typography.Copy.Text>
    </div>
  </PageLayout>
);
