import { AddressLookUpItem } from './address-look-up-item.model';

export class AddressLookUpItemRecord {
  found: true;
  result: AddressLookUpItem;

  constructor(result: AddressLookUpItem) {
    this.result = result;
    this.found = true;
  }
}
