import useScript from '@modules/domain/fraudnet/use-script';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

export interface FraudNetOutput {
  jSCData: string;
  hDMData: string;
}

export interface FraudNetFormProps {
  model: object;
  handler: (output: FraudNetOutput, trackerLoaded: boolean) => void;
}

export const FraudNetForm: FunctionComponent<FraudNetFormProps> = ({ model, handler }): JSX.Element => {
  const [submit, setSubmit] = useState<boolean>(false);
  const [finished, setFinished] = useState<boolean>(false);
  const [process, setProcess] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);

  const [output, setOutput] = useState<FraudNetOutput | null>(null);

  const [loaded, error] = useScript('/cpp-assets/scripts/ecsprefs.js');

  const formRef = useRef<HTMLFormElement>(null);
  const submitElement = useRef<HTMLInputElement>(null);
  const ecsPrefs = useRef<HTMLInputElement>(null);
  const userPrefs = useRef<HTMLInputElement>(null);
  const limitInterval = 12;

  useEffect(() => {
    const timer = setInterval(() => {
      if (ecsPrefs.current && ecsPrefs.current.value && userPrefs.current && userPrefs.current.value) {
        clearInterval(timer);
        if (!output) {
          setOutput({
            jSCData: ecsPrefs.current.value,
            hDMData: userPrefs.current.value,
          });
          setFinished(true);
        }
      } else if (counter >= limitInterval) {
        clearInterval(timer);
        handler(
          {
            jSCData: '',
            hDMData: '',
          },
          false,
        );
      } else {
        setCounter(count => count + 1);
      }
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  useEffect(() => {
    if (loaded) {
      // @ts-ignore
      ecs.initiate(null);
      setSubmit(true);
      setProcess(true);
    }
  }, [loaded]);

  useEffect(() => {
    if (process && submit && submitElement.current) {
      submitElement.current.click();
    }
  }, [process]);

  useEffect(() => {
    if (finished && output) {
      handler(output, true);
    }
  }, [finished]);

  const onSubmit = () => {
    // timeout here linked to timeout of hdim ajax call in ecspref.js, userPrefs2 will fail if hdim fails
    setTimeout(() => {
      // @ts-ignore
      ecs.validate('ecs_prefs');
    }, 4000);
  };

  const isPrimitive = (test: any) => test !== Object(test);

  const getInputs = (obj: any): React.ReactFragment[] => {
    let inputs: React.ReactFragment[] = [];

    if (Array.isArray(obj)) {
      (obj as any[]).forEach(item => {
        const items = getInputs(item);
        inputs = inputs.concat(items);
      });

      return inputs;
    }

    Object.keys(obj).forEach((key, index) => {
      const item = obj[key];
      if (isPrimitive(item)) {
        inputs.push(<input readOnly type="text" spellCheck={false} name={`${key}-input-${index}`} value={`${item}`} key={`${key}-${index}`} />);
      } else {
        const items = getInputs(item);
        inputs = inputs.concat(items);
      }
    });

    return inputs;
  };

  return (
    <div hidden={true} aria-hidden={true}>
      {model && loaded && !error && (
        <div>
          <form ref={formRef} action="javascript:void(0)" onSubmit={onSubmit}>
            {getInputs(model)}
            <input ref={submitElement} type="submit" value="Submit" />
            <input ref={ecsPrefs} type="hidden" id="ecs_prefs" name="ecs_prefs" spellCheck={false} />
            <input ref={userPrefs} type="hidden" id="user_prefs2" name="user_prefs2" spellCheck={false}/>
          </form>
        </div>
      )}
    </div>
  );
};
