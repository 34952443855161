// @ts-ignore
import { Oops } from '@experian-uk/corvetteuk-common-ui';
import { ApolloError } from 'apollo-client';
import React, { FunctionComponent } from 'react';

interface ErrorProps {
  error?: ApolloError | string;
}

export const Error: FunctionComponent<ErrorProps> = ({ error }): JSX.Element => {
  return (
    <Oops data-testid="error" oopsTitle="Something went wrong">
      <span>{formatError(error)}</span>
    </Oops>
  );
};

const formatError = (error?: ApolloError | string): string => {
  if (error instanceof ApolloError) {
    return error.message.replace('GraphQL error:', '').trim();
  }

  if (typeof error === 'string') {
    return error;
  }

  return 'Something went wrong';
};
