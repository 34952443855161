import React from 'react';
import { SecureRoute } from 'react-route-guard';

import { ErrorRoutes, ReactivationRoutes } from '@app/routes';
import { AddressStateProvider } from '@modules/domain/address';
import { initialReactivationContextModel, ReactivationContext } from '@modules/domain/sign-up';
import { ReActivationStateRouteGuard } from '@partnerships/common/modules/auth/guards';
import { WelcomeBackPage } from './welcome-back';

export const ReactivationPagesContainer = (): JSX.Element => (
  <ReactivationContext.Provider value={initialReactivationContextModel}>
    <AddressStateProvider>
      <SecureRoute
        key={ReactivationRoutes.Index}
        path={ReactivationRoutes.Index}
        exact={true}
        routeGuard={ReActivationStateRouteGuard.create()}
        redirectToPathWhenFail={ErrorRoutes.Unauthorised}
        component={WelcomeBackPage}
      />
    </AddressStateProvider>
  </ReactivationContext.Provider>
);
