// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent } from 'react';

export const WelcomeSection: FunctionComponent = (): JSX.Element => {
  return (
    <>
      <Typography.Copy.Text>
        <strong>
          Created by the experts at Experian, the Identity Plus service for CPP will keep you updated with potentially fraudulent activity
          on your credit report as well as scanning the internet and alerting you if your details are found online.
        </strong>{' '}
      </Typography.Copy.Text>
      <Typography.Copy.Text>
        <strong>Experian Fraud Report</strong>
        <br />
        If you log in, you can get your daily Experian Fraud Report. This details key information from your Experian Credit Report that may
        help you identify fraudulent activity on your credit report.
      </Typography.Copy.Text>
      <Typography.Copy.Text>
        <strong>Experian fraud alerts</strong>
        <br />
        Get alerts by email or text message about certain changes to your Experian Fraud Report.
      </Typography.Copy.Text>
      <Typography.Copy.Text>
        <strong>Web monitoring</strong>
        <br />
        We help you better protect your identity by scanning certain internet sites and locations for selected personal and financial
        details and alerting you by email or text message if anything looks wrong or fraudulent.
        <br />
        Web monitoring is designed to work alongside taking a cautious approach to your sharing of data and use of the internet and other
        digital services.
      </Typography.Copy.Text>
      <Typography.Copy.Text>
        <strong>Enhanced fraud support</strong>
        <br />
        If you become a victim of fraud, our dedicated experts will help walk you through the next steps.
        <br />
        <br />
        You will be assigned a dedicated case worker who will guide you through the steps to recovering from fraud, depending on your
        situation. Your caseworker will get in touch with companies on your behalf as well as taking protective measures through
        communications with other credit reference agencies and fraud prevention services such as Cifas.
        <br />
        <br />
        The caseworker will help you set up further protective controls on your credit file if necessary and will continue to check your
        file at regular intervals once your case has been closed.
      </Typography.Copy.Text>
    </>
  );
};
