// @ts-ignore
import { Button } from '@experian-uk/corvetteuk-common-ui';
import { Form } from 'formik';

import { hiddenVisually } from '@assets/styles/mixins';
import { colors, spacers } from '@assets/styles/settings';
import styled from '@assets/styles/styled';

interface StyledFormEditProps {
  mb?: boolean;
  padding?: number;
}

export const StyledFormEdit = styled(Form)<StyledFormEditProps>`
  position: relative;
  ${({ mb }) => mb && `margin-bottom: ${spacers[2]}`};
  ${({ padding }) => padding && `padding: ${spacers[padding]}`};
`;

export const StyledFormSubmitButton = styled(Button)`
  margin-left: 32%;
`;

export const StyledFormCancelButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 0;
  background: 0;
  border: 0;

  svg {
    width: 25px;
    height: 25px;
    fill: ${colors.border};
  }

  span {
    ${hiddenVisually};
  }
`;
