import { AddressHistoryItem } from '@modules/domain/address/models';
import gql from 'graphql-tag';

export const PERSONAL_DETAILS_QUERY = gql`
  query userDetails {
    userDetails {
      title
      firstName
      middleName
      mothersMaidenName
      lastName
      dateOfBirthDay
      dateOfBirthMonth
      dateOfBirthYear
      emailAddress
      contactNumber
      secondaryPhoneNumber
      currentAddress {
        flat
        houseName
        houseNumber
        street
        district
        city
        county
        postCode
        fromDate
      }
      previousAddresses {
        houseName
        houseNumber
        flat
        street
        district
        city
        county
        postCode
        fromMonth
        fromYear
        toMonth
        toYear
      }
    }
  }
`;

export interface UserDetails {
  title: string;
  firstName: string;
  middleName?: string;
  mothersMaidenName: string;
  lastName: string;
  dateOfBirthDay?: string;
  dateOfBirthMonth?: string;
  dateOfBirthYear?: string;
  emailAddress: string;
  contactNumber: string;
  secondaryPhoneNumber?: string;
  currentAddress?: {
    flat?: string;
    houseName?: string;
    houseNumber?: string;
    street: string;
    city: string;
    district: string;
    county: string;
    postCode: string;
    fromDate: string;
  };
  previousAddresses?: AddressHistoryItem[];
}

export interface UserPersonalDetailsQueryResult {
  userDetails: UserDetails;
}
