// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';

import styled from '@assets/styles/styled';
import React, { FunctionComponent } from 'react';

import { Faqs, FaqsContent } from '@modules/domain/faqs';
import { PageLayout } from '@modules/domain/page-layout';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export const MessageBanner = styled.section`
  background: rgba(0, 0, 0, 0) linear-gradient(to right, rgb(38, 71, 141) 0%, rgb(64, 110, 179) 100%) repeat scroll 0% 0%;
  min-height: 9.375rem;
  color: white;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;


const SupportPageComponents: FunctionComponent<RouteComponentProps> = ({location, history, match}): JSX.Element => {
  return (
    <>
      <MessageBanner>
        <p>To support us helping those in the most vulnerable situations, please only call to speak to Experian if your request is urgent.</p>
      </MessageBanner>
      <PageLayout title="Contact Us" metaTitle="Contact Us">
        <Typography.Copy.Text>
          To contact us please call 0344 848 4277. Calls cost no more than calls to geographical numbers (01 or 02) and
          will be included as
          part of inclusive minutes in your package. Lines are open Monday to Friday 9am – 4pm.
        </Typography.Copy.Text>
        <Typography.Headings.Primary>Frequently Asked Questions</Typography.Headings.Primary>
        <Typography.Copy.Text>
          We understand you may have some questions about your Identity Plus service, so here are the top five most
          commonly asked.
        </Typography.Copy.Text>
        <Faqs faqs={FaqsContent} location={location} history={history} match={match}/>
      </PageLayout>
    </>
  );
};

export const SupportPage = withRouter(SupportPageComponents);
