// @ts-ignore
import { Link, Typography } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent } from 'react';

import { ExternalRoutes } from '@app/routes';
import { StyledListUnordered } from '@modules/core/list-unordered';
import { StyledSpacer } from '@modules/core/spacer';
import { StyledTable, StyledTableHeading } from '@modules/core/table';
import { TextWrap } from '@modules/core/text-wrap';
import { PageLayout } from '@modules/domain/page-layout';

const AllAboutCookies: FunctionComponent = () => (
  <Link.Anchor target="_blank" inline href={ExternalRoutes.AllAboutCookies}>
    www.allaboutcookies.org
  </Link.Anchor>
);

export const CookiesPage: FunctionComponent = () => {
  const pageTitle = 'Cookies on this website';

  return (
    <PageLayout sidebar={false} title={pageTitle} metaTitle={pageTitle}>
      <StyledSpacer />

      <StyledSpacer space={6}>
        <Typography.Headings.Secondary>All about cookies</Typography.Headings.Secondary>
        <Typography.Copy.Text as="div">
          <StyledListUnordered>
            <li>We use cookies to make your online experience better</li>
            <li>Cookies are small files that we save on your device. Almost all websites use cookies</li>
            <li>
              We mainly use cookies to remember your preferences and to track page visits so that we can make improvements to our site
            </li>
            <li>We've set out below more information about how we use cookies and how you can control them</li>
          </StyledListUnordered>
        </Typography.Copy.Text>
      </StyledSpacer>

      <StyledSpacer space={6}>
        <Typography.Headings.Secondary>How this site uses cookies</Typography.Headings.Secondary>
        <Typography.Copy.Text>The main cookies used on this website are as follows:</Typography.Copy.Text>
        <StyledTable format="coloured">
          <thead>
            <tr>
              <StyledTableHeading width="25%" scope="col">
                Cookie Type
              </StyledTableHeading>
              <StyledTableHeading width="75%" scope="col">
                Details (from Experian.co.uk)
              </StyledTableHeading>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Analytics</td>
              <td>
                These cookies help us to improve this website over time, by giving us insights into how the various sections of the website
                are used and how users interact with the website. The information collected is anonymous and statistical.
              </td>
            </tr>
            <tr>
              <td>Authentication</td>
              <td>
                These cookies are used to identify unique visitors to the website. If you access the website, these are the cookies that
                allow us to remember who you are so that we can provide you with access to pages personal to you, for example your account
                pages.
                <br />
                <br />
                These cookies help keep your visit to the site secure.
              </td>
            </tr>
            <tr>
              <td>Session</td>
              <td>
                These are cookies that are designed to ensure that your visit to the website is as smooth as possible. Their main uses are:
                <br />
                <StyledListUnordered>
                  <li>
                    Allowing us to identify your device as you use the website, so that you are not treated as a new visitor each time you
                    go to another part of the website;
                  </li>
                  <li>
                    Ensure that the servers that we use to power the website each serve an equal number of users, to help make everyone's
                    browsing as swift and responsible as possible;
                  </li>
                  <li>Noting your browser's capabilities.</li>
                </StyledListUnordered>
              </td>
            </tr>
          </tbody>
        </StyledTable>
      </StyledSpacer>

      <StyledSpacer space={6}>
        <Typography.Headings.Secondary>How to reject and delete them</Typography.Headings.Secondary>
        <Typography.Copy.Text>
          Most web browsers automatically accept cookies. However, you do not have to accept cookies and you can, should you choose to at
          any time, reject or block the use of cookies and delete all cookies currently stored on your device. You can find out how to do
          this for your particular browser by clicking "help" on your browser's menu, or by visiting: <AllAboutCookies />.
        </Typography.Copy.Text>
        <Typography.Copy.Text as="div">For example, in Microsoft Internet Explorer 7.0 you can delete cookies by:</Typography.Copy.Text>
        <Typography.Copy.Text as="div">
          <StyledListUnordered>
            <li>Selecting "Tools/Internet Options"</li>
            <li>Clicking on "Delete" in the Browsing History section</li>
            <li>Selecting "delete cookies"</li>
          </StyledListUnordered>
        </Typography.Copy.Text>
        <Typography.Copy.Text as="div">
          Options to accept, block, or require notification for the use of cookies are found under:
        </Typography.Copy.Text>
        <Typography.Copy.Text as="div">
          <StyledListUnordered>
            <li>"Tools/Internet Options/Privacy/Advanced"</li>
          </StyledListUnordered>
        </Typography.Copy.Text>
        <Typography.Copy.Text>
          Please be aware however that if you chose to block cookies you may not be able to access certain features of this web site. <br />
          For information on how to reject or delete cookies on the browser of your mobile device you may need to refer to your device's
          manual.
        </Typography.Copy.Text>
      </StyledSpacer>

      <StyledSpacer space={6}>
        <Typography.Headings.Secondary>Third party cookies</Typography.Headings.Secondary>
        <Typography.Copy.Text>
          We may allow third party organisations to set cookies using this website in order to deliver services. If you would like more
          information about the cookies used by these third parties, as well as details on how to opt-out, please see the table below.
        </Typography.Copy.Text>
        <StyledTable format="coloured">
          <thead>
            <tr>
              <StyledTableHeading width="25%" scope="col">
                Cookie Name
              </StyledTableHeading>
              <StyledTableHeading width="75%" scope="col">
                What is the cookie used for
              </StyledTableHeading>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Google Analytics</strong>
              </td>
              <td>
                These cookies help us to improve this website over time, by giving us insights into how the various sections of the website
                are used and how users interact with the website. The information collected is anonymous and statistical.
                <br />
                <br />
                If you do not wish to allow the use of Google Analytics cookies at all, Google provides an opt-out plug-in for most common
                website browsers{' '}
                <Link.Anchor target="_blank" inline href="http://tools.google.com/dlpage/gaoptout">
                  <TextWrap>http://tools.google.com/dlpage/gaoptout</TextWrap>
                </Link.Anchor>
                .
              </td>
            </tr>
          </tbody>
        </StyledTable>
      </StyledSpacer>

      <StyledSpacer space={6}>
        <Typography.Headings.Secondary>Tracking technologies that our emails use</Typography.Headings.Secondary>
        <Typography.Copy.Text>
          We send emails using an email provider who uses tracking technologies. We use this information primarily to understand interaction
          with the emails we send, by monitoring whether our emails are opened and what links are clicked on by our readers. We then use
          this information to improve the emails that we send to you and the services that we provide.
        </Typography.Copy.Text>
        <Typography.Copy.Text>
          If you do not wish to allow us to use these technologies, the best way to do this is to not enable images when you view our
          emails. In other words, only view the plain-text of the email.
        </Typography.Copy.Text>
      </StyledSpacer>

      <StyledSpacer space={6}>
        <Typography.Headings.Secondary>More information on cookies</Typography.Headings.Secondary>
        <Typography.Copy.Text>
          If you'd like more information and control over cookies visit <AllAboutCookies />.
        </Typography.Copy.Text>
      </StyledSpacer>
    </PageLayout>
  );
};
