import React from 'react';
import { SecureRoute } from 'react-route-guard';

import { ErrorRoutes, SignUpRoutes } from '@app/routes';
import { AddressStateProvider } from '@modules/domain/address/address.provider';
import { initialSignUpContextModel, SignUpContext } from '@modules/domain/sign-up';
import { ActivationPage } from '@pages/sign-up/activation';
import { AddressDetailsPage } from '@pages/sign-up/address-details';
import { PersonalDetailsPage } from '@pages/sign-up/personal-details';
import { WelcomePage } from '@pages/sign-up/welcome';
import { SignUpOrReactivationOrAwaitingStateRouteGuard } from '@partnerships/common/modules/auth/guards';
import { UserSessionLocalStorageKey, UserSessionState } from '@partnerships/common/modules/user-session/constants';

const isReactivation = () => {
  const userSessionData = localStorage.getItem(UserSessionLocalStorageKey);

  if (!userSessionData) {
    return false;
  }

  const userSession = JSON.parse(userSessionData);

  return userSession.state === UserSessionState.reactivation;
};

export const SignUpPagesContainer = (): JSX.Element => (
  <SignUpContext.Provider value={initialSignUpContextModel}>
    <AddressStateProvider>
      <SecureRoute
        key={SignUpRoutes.Index}
        path={SignUpRoutes.Index}
        exact={true}
        routeGuard={SignUpOrReactivationOrAwaitingStateRouteGuard.create()}
        redirectToPathWhenFail={ErrorRoutes.Unauthorised}
        render={props => <WelcomePage {...props} isReactivation={isReactivation()} />}
      />

      <SecureRoute
        key={SignUpRoutes.PersonalDetails}
        path={SignUpRoutes.PersonalDetails}
        routeGuard={SignUpOrReactivationOrAwaitingStateRouteGuard.create()}
        redirectToPathWhenFail={ErrorRoutes.Unauthorised}
        component={PersonalDetailsPage}
      />

      <SecureRoute
        key={SignUpRoutes.AddressDetails}
        path={SignUpRoutes.AddressDetails}
        routeGuard={SignUpOrReactivationOrAwaitingStateRouteGuard.create()}
        redirectToPathWhenFail={ErrorRoutes.Unauthorised}
        component={AddressDetailsPage}
      />

      <SecureRoute
        key={SignUpRoutes.Activation}
        path={SignUpRoutes.Activation}
        routeGuard={SignUpOrReactivationOrAwaitingStateRouteGuard.create()}
        redirectToPathWhenFail={ErrorRoutes.Unauthorised}
        component={ActivationPage}
      />
    </AddressStateProvider>
  </SignUpContext.Provider>
);
