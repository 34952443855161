import React, { FunctionComponent } from 'react';

import { StyledInputs, StyledLabel } from './select-multi.styled';

import { StyledInputContainer } from '../input/input.styled';

import { Icon } from '../icon';

interface SelectMultiProps {
  label: string;
  isValid: boolean;
  isInvalid: boolean;
}

export const SelectMulti: FunctionComponent<SelectMultiProps> = ({ label, children, isInvalid, isValid }) => (
  <>
    <StyledLabel>{label}</StyledLabel>
      <StyledInputs>{children}</StyledInputs>
  </>
);
