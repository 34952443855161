import React, { FunctionComponent } from 'react';

import { StyledLabel } from '@modules/core/select-multi/select-multi.styled';

interface CurrentAddressPanelProps {
  house: string;
  street?: string;
  district?: string;
  county?: string;
  city?: string;
  postCode: string;
}

const CurrentAddressPanel: FunctionComponent<CurrentAddressPanelProps> = ({
  house,
  street,
  district,
  county,
  city,
  postCode,
}): JSX.Element => (
  <React.Fragment>
    <StyledLabel>
      <p>{house} {street}</p>
      {district && <p>{district}</p>}
      {city && <p>{city}</p>}
      {county && <p>{county}</p>}
      <p>{postCode}</p>
    </StyledLabel>
  </React.Fragment>
);

CurrentAddressPanel.displayName = 'current-address-panel';

export { CurrentAddressPanel };
