import React, { FunctionComponent } from 'react';

import { spacers } from '@assets/styles/settings';
import styled from '@assets/styles/styled';

import { Loading } from '@modules/core/loading';

interface LoaderProps {
  message: string;
}

export const StyledBody = styled.div`
  height: 30%;
  padding: ${spacers[2]};

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${spacers[2]};
  }
`;

export const OnboardingLoader: FunctionComponent<LoaderProps> = ({ message }): JSX.Element => {
  return (
    <StyledBody>
      <Loading />
      <p>{message}</p>
    </StyledBody>
  );
};
