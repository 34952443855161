// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import { StyledSpacer } from '@modules/core/spacer';
import { CallCard } from '@modules/domain/call-card';
import React, { FunctionComponent } from 'react';

const ProfileIntroSection: FunctionComponent = (): JSX.Element => (
  <section data-testid="profile-intro">
    <Typography.Copy.Text>
      Your profile details displayed below are based on information shared by CPP with Experian. If you need to make a change to any of your
      details please call the CPP team using the details below.
    </Typography.Copy.Text>

    <StyledSpacer space={5}>
      <CallCard />
    </StyledSpacer>
  </section>
);

ProfileIntroSection.displayName = 'profile-intro-section';

export { ProfileIntroSection };
