import { rem } from '@assets/styles/settings';
import styled from '@assets/styles/styled';

export const StyledCppLinks = styled.div`
  padding-left: 2rem;
  margin-bottom: 3rem;
  font-size: ${rem(16)};
  ${({ theme: { mediaEnd } }) => mediaEnd('15', `
  margin-top: 3rem;
  padding-left: 0;
  margin: 0 auto;
  display: table;
`)}
`;
