import styled, { keyframes } from '@assets/styles/styled';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export type FadeDirection = 'in' | 'out';

export interface FadeProps {
  fadeDirection: FadeDirection;
}

export const Fade = styled.div<FadeProps>`
  visibility: ${props => (props.fadeDirection === 'out' ? 'hidden' : 'visible')};
  transition: visibility 1s linear;
  animation: ${props => (props.fadeDirection === 'out' ? fadeOut : fadeIn)} 1s linear;
`;
