import styled from '@assets/styles/styled';
import { StyledSegmentBlock } from '@modules/core/segment-block/segment-block.styled';

export const ShadowBox = styled(StyledSegmentBlock)`
  background: ${({ theme }) => theme.colors.baseWhite};
  box-shadow: 0 3px 4px 2px ${({ theme }) => theme.colors.shadeLightGrey};

  ${({ theme }) =>
    theme.media(
      '15',
      `
      padding: 0.75rem;

      > * {
        max-width: 64rem;
      }

      section {
        margin-bottom: 1.5rem;
      }
    `,
    )}
`;

export const StyledContactNumber = styled.div`
  display: flex;

  strong {
    display: block;
    margin-bottom: 0.25rem;
    line-height: 1;
  }

  p {
    margin-left: 1.5rem;
  }
`;

export const StyledOpeningTimes = styled.div`
  width: 100%;
  margin: 1.5rem 0;
  padding-top: 1.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.baseOutline};

  p {
    margin-bottom: 0.75rem;
    line-height: 1;
  }

  strong {
    display: block;
    margin-bottom: 0.25rem;
  }

  ${({ theme }) =>
    theme.media(
      '15',
      `
      display: flex;
      flex: 1;
      justify-content: left;
      width: auto;
      margin-top: 0;
      margin-left: 5%;
      max-width: 35rem;
      padding: 0 0 0 5%;
      border-top: 0;
      border-left: 1px solid ${theme.colors.baseOutline};

      p:nth-last-child(n+2)  {
        margin: 0 5% 0 0;
      }

      strong {
        margin-bottom: 1rem;
      }
    `,
    )}

  ${({ theme }) =>
    theme.media(
      '20',
      `
      margin-left: 7%;
      padding-left: 7%;

      p:nth-last-child(n+2)  {
        margin-right: 11%;
      }
    `,
    )}
`;
