import { useEffect, useState } from 'react';
import { getExistingUserSession } from './user-session-utils';

import { UserSessionQueryStringParamKey } from './constants';
import { newUserSessionFactory } from './new/new-user-session-factory';
import { unknownUserSessionFactory } from './unknown/unknown-user-session-factory';
import { OnSuccessfullyGetUserSessionCallback, UserSessionResult } from './user-session.query';

const canParseSessionIdFromQueryString = (): boolean => {
  return !!new URLSearchParams(window.location.search).get(UserSessionQueryStringParamKey);
};

const getUserSession = async (onSuccessfullUserSessionCallback?: OnSuccessfullyGetUserSessionCallback): Promise<UserSessionResult> => {
  if (canParseSessionIdFromQueryString()) {
    const sessionId = new URLSearchParams(window.location.search).get(UserSessionQueryStringParamKey);
    return Promise.resolve<UserSessionResult>(newUserSessionFactory.create(sessionId as string))
      .catch(() => unknownUserSessionFactory.create())
      .then(data => {
        if (onSuccessfullUserSessionCallback) {
          onSuccessfullUserSessionCallback(data);
        }
        return data;
      });
  }

  return getExistingUserSession();
};

export const useUserSession = (onSuccessfullUserSessionCallback?: OnSuccessfullyGetUserSessionCallback): [UserSessionResult] => {
  const [userSession, setUserSession] = useState<UserSessionResult>();

  useEffect(() => {
    let inProgress = true;
    getUserSession(onSuccessfullUserSessionCallback).then(result => {
      if (inProgress) {
        setUserSession(result);
      }
    });
    return () => {
      inProgress = false;
    };
  }, []);

  return [userSession as UserSessionResult];
};
