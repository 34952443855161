import React, { FunctionComponent, useState } from 'react';
import { useApolloClient } from 'react-apollo-hooks';

import { Loading } from '@modules/core/loading';
import { SegmentBlock } from '@modules/core/segment-block/segment-block';
import { flexDirection } from '@modules/core/segment-block/segment-block.styled';
import { AddressFromLookUpId, AddressLookUpItemMaybeRecord, GET_ADDRESS_FROM_LOOKUP_ID } from '@modules/domain/address/address-look-up';
import { initialManualAddressFormValues, ManualAddressForm, ManualAddressFormModel } from '@modules/domain/address/manual-address-form';
import { AddressHistoryItem } from '@modules/domain/address/models';
import { mapVerifiedFormValuesToManualFormValues } from '@modules/domain/address/utils';
import { VerfiedAddress } from '@modules/domain/address/verified-address';
import { initialVerifiedAddressFormValues, VerifiedAddressFormModel } from '@modules/domain/address/verified-address-form';
import { ADDRESS_TYPE } from '@shared/const';

interface AddPreviousAddressProps {
  onCancel: () => void;
  onPreviousAddressAdded: (address: AddressHistoryItem) => void;
}

const AddPreviousAddressForm: FunctionComponent<AddPreviousAddressProps> = ({ onCancel, onPreviousAddressAdded }): JSX.Element => {
  const [showManualAddressForm, setShowManualAddressForm] = useState<boolean>(false);
  const [showAddressLookUpList, setShowAddressLookUpList] = useState<boolean>(false);

  const [verifiedAddressFormValues, setVerifiedAddressFormValues] = useState<VerifiedAddressFormModel>(initialVerifiedAddressFormValues);
  const [manualAddressFormValues, setManualAddressFormValues] = useState<ManualAddressFormModel>(initialManualAddressFormValues);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const apolloClient = useApolloClient();

  if (showLoading) {
    return <Loading />;
  }

  return (
    <SegmentBlock direction={flexDirection.COLUMN}>
      {showManualAddressForm ? (
        <ManualAddressForm
          initialFormValues={manualAddressFormValues}
          title={'Add a new previous address'}
          onCloseForm={onCancel}
          onFormSubmit={(formValues: ManualAddressFormModel) => {
            const prevAddress: AddressHistoryItem = {
              abroad: false,
              addressType: ADDRESS_TYPE.previousAddress,
              isVerified: true,
              manual: true,
              city: formValues.postTownOrCity,
              county: formValues.county,
              district: formValues.district,
              flat: formValues.flat,
              houseName: formValues.houseName,
              houseNumber: formValues.houseNumber,
              postCode: formValues.postCode,
              street: formValues.street,
              fromMonth: formValues.fromMonth,
              fromYear: formValues.fromYear,
              toMonth: formValues.toMonth,
              toYear: formValues.toYear,
            };
            onPreviousAddressAdded(prevAddress as AddressHistoryItem);
          }}
        />
      ) : (
        <VerfiedAddress
          formValues={verifiedAddressFormValues}
          lookUpPostCodeValue={verifiedAddressFormValues.postCode}
          onCloseForm={onCancel}
          onCloseLookUp={() => setShowAddressLookUpList(false)}
          onFormSubmitted={(formValues: VerifiedAddressFormModel) => {
            setVerifiedAddressFormValues(formValues);
            setShowAddressLookUpList(true);
          }}
          onLookUpAddressSelected={async (formValues: VerifiedAddressFormModel, record: AddressLookUpItemMaybeRecord) => {
            if (record.found) {
              setShowLoading(true);

              const { data } = await apolloClient.query<AddressFromLookUpId>({
                query: GET_ADDRESS_FROM_LOOKUP_ID,
                variables: { lookUpItemId: record.result.id },
              });

              const prevAddress: AddressHistoryItem = {
                ...data.addressFromPostCodeLookup,
                abroad: false,
                addressType: ADDRESS_TYPE.previousAddress,
                manual: false,
                isVerified: true,
                fromMonth: formValues.fromMonth,
                fromYear: formValues.fromYear,
                toMonth: formValues.toMonth,
                toYear: formValues.toYear,
              };
              setVerifiedAddressFormValues(formValues);
              onPreviousAddressAdded(prevAddress as AddressHistoryItem);
            }
          }}
          onMyAddressIsNotListed={(formValues: VerifiedAddressFormModel) => {
            setVerifiedAddressFormValues(formValues);
            setShowManualAddressForm(true);
            setManualAddressFormValues(mapVerifiedFormValuesToManualFormValues(verifiedAddressFormValues));
          }}
          showAddressLookUpList={showAddressLookUpList}
        />
      )}
    </SegmentBlock>
  );
};

AddPreviousAddressForm.displayName = 'add-previous-address-form';

export { AddPreviousAddressForm };
