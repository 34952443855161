export interface ManualAddressFormModel {
  flat?: string;
  houseName?: string;
  houseNumber?: string;
  street: string;
  district?: string;
  county: string;
  postTownOrCity: string;
  postCode: string;
  fromMonth: string;
  fromYear: string;
  toMonth: string;
  toYear: string;
}

export const initialManualAddressFormValues: ManualAddressFormModel = {
  flat: '',
  houseName: '',
  houseNumber: '',
  street: '',
  district: '',
  county: '',
  postTownOrCity: '',
  postCode: '',
  fromMonth: '',
  fromYear: '',
  toMonth: '',
  toYear: '',
};
