import { AddressHistoryItem, CurrentAddressModel } from '@modules/domain/address/models';
import gql from 'graphql-tag';

export const MY_PROFILE_QUERY = gql`
  query userContactDetails {
    userContactDetails {
      contactPhoneNumber
      currentAddress {
        flat
        houseName
        houseNumber
        street
        district
        city
        county
        postCode
        fromDt
      }
      dob
      email
      previousAddresses {
        addressType
        city
        country
        county
        district
        flat
        houseName
        houseNumber
        isVerified
        manual
        abroad
        postCode
        fromMonth
        fromYear
        street
        toMonth
        toYear
      }
      secondaryPhoneNumber
      titledFullName
    }
  }
`;

export interface UserContactDetailsModel {
  contactPhoneNumber: string;
  currentAddress: CurrentAddressModel;
  dob: string;
  email: string;
  previousAddresses: AddressHistoryItem[];
  secondaryPhoneNumber?: string;
  titledFullName: string;
}

export interface MyProfileQueryResult {
  userContactDetails: UserContactDetailsModel;
}
