import { ErrorRoutes, HomeRoutes, ReactivationRoutes, SignUpRoutes } from '@partnerships/common/modules/routes';

export { ErrorRoutes, HomeRoutes, ReactivationRoutes, SignUpRoutes };

export enum ProfileRoutes {
  Index = '/profile',
}

export enum PdfRoutes {
  TermsAndConditions = '/documents/termsandconditions.pdf',
  PrivacyPolicy = '/documents/privacypolicy.pdf',
}

export enum ContactRoutes {
  Index = '/contact',
}

export enum SupportRoutes {
  Index = '/support',
}

export enum CookieRoutes {
  Index = '/cookies',
}

export enum RightsRoutes {
  Index = '/statement-of-rights',
}

export enum ExternalRoutes {
  CppIdentity = 'https://www.cppdirect.co.uk/members/account',
  AllAboutCookies = 'https://www.allaboutcookies.org/',
}
