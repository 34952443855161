export const UserSessionLocalStorageKey = 'session';
export const UserSessionQueryStringParamKey = 'session-id';

export type UserSessionStateModel =
  | 'active'
  | 'awaitingOnboard'
  | 'awaitingApproval'
  | 'notEligible'
  | 'notApproved'
  | 'serviceUnavailable'
  | 'genericError'
  | 'signUp'
  | 'badAuth'
  | 'unknown'
  | 'reactivation'
  | 'sessionExpired';

export enum UserSessionState {
  active = 'active',
  awaitingOnboard = 'awaitingOnboard',
  signUp = 'signUp',
  reactivation = 'reactivation',
  awaitingApproval = 'awaitingApproval',
  notEligible = 'notEligible',
  notApproved = 'notApproved',
  serviceUnavailable = 'serviceUnavailable',
  unknown = 'unknown',
  genericError = 'genericError',
  badAuth = 'badAuth',
  sessionExpired = 'sessionExpired',
}
