import moment from 'moment';

export const getYearsValues = (yearsMinValue: number = 1900): string[] => {
  const years = [] as string[];
  let yearsMaxValue = new Date().getFullYear();

  if (yearsMinValue < 1900 || yearsMinValue > yearsMaxValue) {
    return years;
  }

  while (yearsMaxValue >= yearsMinValue) {
    years.push(yearsMaxValue.toString());
    yearsMaxValue--;
  }

  return years;
};

export const getMonthsValues = (): string[] => {
  return moment.months();
};

export const getDayValues = (): string[] => {
  return Array.from(Array(31).keys()).map(i => `${i + 1}`);
};

export const getDayValuesForMonthAndYear = (month: string, year: string): string[] => {
  const days = [] as string[];

  const yearIsInvalid = (yearVal: string) => {
    const yearSub = yearVal.substring(0, 2);
    return yearVal.length !== 4 || (yearSub !== '19' && yearSub !== '20');
  };

  if (yearIsInvalid(year)) {
    return days;
  }

  const monthIndex = moment.months().indexOf(month) + 1; // array so 0 based

  if (monthIndex === 0) {
    return days;
  }

  let amountOfDaysInMonth = moment(`${year}-${monthIndex}`, 'YYYY-MM').daysInMonth();

  while (amountOfDaysInMonth > 0) {
    days.unshift(amountOfDaysInMonth < 10 ? `0${amountOfDaysInMonth}` : amountOfDaysInMonth.toString());
    amountOfDaysInMonth--;
  }

  return days;
};

const getMinimumAllowedDate = (): Date => {
  return moment()
    .subtract(6, 'years')
    .toDate();
};

export const isDateLessThanMinimumAllowedDate = (dateToCompare: Date): boolean => {
  return moment(dateToCompare).isSameOrBefore(getMinimumAllowedDate());
};

export const isDateGreaterThanMinimumAllowedDate = (dateToCompare: Date): boolean => {
  return moment(dateToCompare).isSameOrAfter(getMinimumAllowedDate());
};

export const toDateIsAfterStartDate = (startMonth: string, toMonth: string, startYear: string, toYear: string): boolean => {
  const startDate = moment(`${startMonth} ${startYear}`, 'MMM YYYY');
  const toDate = moment(`${toMonth} ${toYear}`, 'MMM YYYY');
  return toDate.isSameOrAfter(startDate);
};

export const getMonthAsStringValueFromDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleString('default', { month: 'long' });
};

export const getYearAsStringValueFromDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.getFullYear().toString();
};

export const getFormattedDateString = (month: string, year: string): string => {
  return moment(new Date(`${year}-${month}-01`)).format();
};

export const getDateFromShortMonthAndYear = (month: string, year: string): moment.Moment => {
  return moment(`${month} ${year}`, 'MMM YYYY').startOf('day');
};

export const today = (): moment.Moment => {
  return moment();
};
