import * as Yup from 'yup';

import {
  HouseRegexPattern,
  PostCodeRegexPattern,
  testAddressPeriodsForValidationError,
  testDateIsInTheFuture,
} from '@modules/core/validation';

import { getMonthsValues, getYearsValues } from '@utils/date-values';

export const manualAddressFormSchema = Yup.object().shape(
  {
    fromMonth: Yup.string()
      .required('Please enter the month of the start of your residency at your previous address.')
      .oneOf([...getMonthsValues()], 'Please enter the month of the start of your residency at your previous address.')
      .test('address-period-from', 'Resident from date must be before resident to date', function(residentFromMonthValue: string) {
        const fromYear = this.resolve(Yup.ref('fromYear'));
        const toMonth = this.resolve(Yup.ref('toMonth'));
        const toYear = this.resolve(Yup.ref('toYear'));

        return testAddressPeriodsForValidationError(residentFromMonthValue, fromYear, toMonth, toYear);
      })
      .test('address-period-from', 'Future dates are not valid', function(residentFromMonthValue: string) {
        const fromYear = this.resolve(Yup.ref('fromYear'));
        return !testDateIsInTheFuture(residentFromMonthValue, fromYear);
      }),
    fromYear: Yup.string()
      .required('Please enter the year of the start of your residency at your previous address.')
      .oneOf([...getYearsValues()], 'Please enter the year of the start of your residency at your previous address.')
      .test('address-period-from', 'Future dates are not valid', function(residentFromYearValue: string) {
        const fromMonth = this.resolve(Yup.ref('fromMonth'));
        return !testDateIsInTheFuture(fromMonth, residentFromYearValue);
      }),
    toMonth: Yup.string()
      .required('Please enter the month of the end of your residency at your previous address.')
      .oneOf([...getMonthsValues()], 'Please enter the month of the end of your residency at your previous address.')
      .test('address-period-to', 'Resident to date must be after resident from date', function(residentToMonthValue: string) {
        const fromMonth = this.resolve(Yup.ref('fromMonth'));
        const fromYear = this.resolve(Yup.ref('fromYear'));
        const toYear = this.resolve(Yup.ref('toYear'));

        return testAddressPeriodsForValidationError(fromMonth, fromYear, residentToMonthValue, toYear);
      })
      .test('address-period-to', 'Future dates are not valid', function(residentToMonthValue: string) {
        const toYear = this.resolve(Yup.ref('toYear'));
        return !testDateIsInTheFuture(residentToMonthValue, toYear);
      }),
    toYear: Yup.string()
      .required('Please enter the year of the end of your residency at your previous address.')
      .oneOf([...getYearsValues()], 'Please enter the year of the end of your residency at your previous address.')
      .test('address-period-to', 'Future dates are not valid', function(residentToYearValue: string) {
        const toMonth = this.resolve(Yup.ref('toMonth'));
        return !testDateIsInTheFuture(toMonth, residentToYearValue);
      }),
    flat: Yup.string(),
    houseName: Yup.string().trim()
      .when('houseNumber', {
        is: value => !value,
        then: Yup.string().required('Please enter a house number'),
        otherwise: Yup.string(),
      })
      .matches(
        HouseRegexPattern,
        'Your house name or number may only contain letters and numbers. Please remove any other special characters and try again',
      ),
    houseNumber: Yup.string().trim()
      .when('houseName', {
        is: value => !value,
        then: Yup.string().required('Please enter a house name'),
        otherwise: Yup.string(),
      })
      .matches(
        HouseRegexPattern,
        'Your house name or number may only contain letters and numbers. Please remove any other special characters and try again',
      ),
    postCode: Yup.string()
      .required('Please enter a valid postcode')
      .matches(PostCodeRegexPattern, 'Please enter a valid postcode. This must contain only letters and numbers'),
  },
  [['houseNumber', 'houseName']],
);
