// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent } from 'react';

import { PageLayout } from '@modules/domain/page-layout';

export const NotApprovedPage: FunctionComponent = (): JSX.Element => (
  <PageLayout title="We're sorry we have been unable to fulfill your application" metaTitle="Not approved">
    <Typography.Copy.Text>
      We employ a rigorous verification system in order to provide the utmost protection of your personal information. Unfortunately, we cannot accept your application for the Identity Plus service based on the information that you have provided.
    </Typography.Copy.Text>
    <Typography.Copy.Text>
      We apologise for any inconvenience caused. If you have any questions, please call us on 0344 848 4277*. Lines are open Monday- Friday, 8am – 6pm.
    </Typography.Copy.Text>
    <Typography.Copy.SmallText>
      *034 calls cost no more than calls to geographical numbers (01 or 02) and will be included as part of inclusive
      minutes in your package
    </Typography.Copy.SmallText>
  </PageLayout>
);
