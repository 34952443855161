import React from 'react';
import { FlexContainerStyled } from './flex-container.styled';

export enum flexDirection {
  COLUMN = 'column',
  ROW = 'row',
}

export enum fixedWidth {
  INNERCONTENT = '34.69rem',
}

export interface BreakpointColumn {
  breakpoint: number;
  columns: number;
  direction: flexDirection;
  gutter: number;
}

export interface FlexContainerProps {
  breakpointColumns?: BreakpointColumn[];
  columns?: number;
  direction?: flexDirection;
  gutter?: number;
  maxWidth?: fixedWidth;
}

export const FlexContainer: React.FunctionComponent<FlexContainerProps> = ({
  breakpointColumns = [],
  columns = 1,
  direction = flexDirection.ROW,
  gutter = 0,
  children,
  maxWidth,
}) => (
  <FlexContainerStyled breakpointColumns={breakpointColumns} columns={columns} direction={direction} gutter={gutter} maxWidth={maxWidth}>
    {children}
  </FlexContainerStyled>
);
