import { RouteGuard } from 'react-route-guard';
import { UserSessionState } from '../../user-session';
import { UserSessionLocalStorageKey } from '../../user-session/constants';

export class SignUpOrInactiveStateRouteGuard implements RouteGuard {
  static create = (): SignUpOrInactiveStateRouteGuard => new SignUpOrInactiveStateRouteGuard();

  private constructor() {}

  shouldRoute = (): boolean => {
    const sessionData = localStorage.getItem(UserSessionLocalStorageKey);

    if (!sessionData) {
      return false;
    }

    const userSession = JSON.parse(sessionData);

    return userSession.state === UserSessionState.signUp || userSession.state === UserSessionState.awaitingOnboard;
  };
}
