// @ts-ignore
import { Button, Link, Typography } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent } from 'react';

import { StyledLabel } from '@modules/core/label';
import { SegmentBlock } from '@modules/core/segment-block/segment-block';
import { SegmentBlockColumn } from '@modules/core/segment-block/segment-block-column';
import { flexDirection } from '@modules/core/segment-block/segment-block.styled';
import { StyledSpacer } from '@modules/core/spacer';
import { defaultMonthValue, defaultYearValue } from '@modules/core/validation';
import { CurrentAddressModel } from '@modules/domain/address/models';
import { getHouseValue } from '@partnerships/common/modules/utils';
import { getDateFromShortMonthAndYear, getMonthsValues, getYearsValues, today } from '@utils/date-values';
import { CurrentAddressPanel } from '.';
import { CurrentAddressPeriodControl } from './current-address-period-control';

interface CurrentAddressProps {
  currentAddress: CurrentAddressModel;
  fromDateConfirmed: boolean;
  fromMonth: string;
  fromYear: string;
  showEditView: boolean;
  onConfirmResidentFromDate: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onEdit: () => void;
  onFromMonthChanged?: (value: string) => void;
  onFromYearChanged?: (value: string) => void;
  allowEdit?: boolean;
}

const getMonthSelectOptions = (): string[] => {
  return [defaultMonthValue, ...getMonthsValues()];
};

const getYearSelectOptions = (): string[] => {
  return [defaultYearValue, ...getYearsValues()];
};

const getCurrentAddressSelectionsInvalidMessage = (monthValue: string, yearValue: string): string | null => {
  if (monthValue === defaultMonthValue || yearValue === defaultYearValue) {
    return 'Please enter the start of your residency at your current address';
  }

  const parsedDate = getDateFromShortMonthAndYear(monthValue, yearValue);

  if (parsedDate.isAfter(today(), 'day')) {
    return 'You must not enter a date in the future';
  }
  return null;
};

const CurrentAddress: FunctionComponent<CurrentAddressProps> = ({
  currentAddress,
  fromDateConfirmed,
  fromMonth,
  fromYear,
  showEditView,
  onConfirmResidentFromDate,
  onEdit,
  onFromMonthChanged,
  onFromYearChanged,
  allowEdit = false,
}): JSX.Element => {
  if (currentAddress) {
    const { city, flat, houseName, houseNumber, postCode, street, district, county } = currentAddress;
    const house = getHouseValue(flat, houseName, houseNumber);

    return showEditView ? (
      <React.Fragment>
        <Typography.Headings.Secondary>Current address</Typography.Headings.Secondary>

        <SegmentBlock direction={flexDirection.COLUMN}>
          <CurrentAddressPeriodControl
            monthOptions={getMonthSelectOptions()}
            monthValue={fromMonth}
            onMonthValueChanged={
              onFromMonthChanged
                ? onFromMonthChanged
                : () => {
                    return;
                  }
            }
            onYearValueChanged={
              onFromYearChanged
                ? onFromYearChanged
                : () => {
                    return;
                  }
            }
            invalidMessage={getCurrentAddressSelectionsInvalidMessage(fromMonth, fromYear)}
            selectionsHaveBeenConfirmed={fromDateConfirmed}
            yearOptions={getYearSelectOptions()}
            yearValue={fromYear}
          />
          <StyledSpacer>
            <Button
              type="primary"
              label="Confirm address"
              disabled={!!getCurrentAddressSelectionsInvalidMessage(fromMonth, fromYear)}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault();
                onConfirmResidentFromDate(e);
              }}
            />
          </StyledSpacer>
        </SegmentBlock>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <StyledSpacer>
          <Typography.Headings.Secondary>Current address</Typography.Headings.Secondary>
        </StyledSpacer>

        <SegmentBlock>
          <SegmentBlockColumn width="2">
            <CurrentAddressPanel house={house} street={street} district={district} city={city} county={county} postCode={postCode} />
            <StyledSpacer />

            <Typography.Copy.Text>
              {`You moved into this address in `}
              <StyledLabel>{`${fromMonth} ${fromYear}`}</StyledLabel>
            </Typography.Copy.Text>
          </SegmentBlockColumn>
          {allowEdit && (
            <SegmentBlockColumn width="1">
              <div style={{ textAlign: 'right' }}>
                <Link.Button onClick={() => onEdit()}> Edit address</Link.Button>
              </div>
            </SegmentBlockColumn>
          )}
        </SegmentBlock>
      </React.Fragment>
    );
  }

  return <></>;
};

CurrentAddress.displayName = 'current-address';

export { CurrentAddress };
