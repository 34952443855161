// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';

import moment from 'moment';
import React, { FunctionComponent } from 'react';

import { DefinitionList } from '@modules/core/definition-list';
import { SegmentBlock } from '@modules/core/segment-block/segment-block';

interface SectionPersonalDetailsProps {
  personalDetails: {
    title: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    dateOfBirthDay?: string;
    dateOfBirthMonth?: string;
    dateOfBirthYear?: string;
  };
  handleEditButtonClick?: () => void;
}
export const SectionPersonalDetails: FunctionComponent<SectionPersonalDetailsProps> = ({
  personalDetails,
  handleEditButtonClick,
}): JSX.Element => {
  const dateOfBirthDate = moment(
    `${personalDetails.dateOfBirthDay}-${personalDetails.dateOfBirthMonth}-${personalDetails.dateOfBirthYear}`,
    'D-MMMM-YYYY',
    true,
  );

  return (
    <section data-testid="section-personal-details">
      <Typography.Headings.Secondary>Personal details</Typography.Headings.Secondary>

      <SegmentBlock handleEditButtonClick={handleEditButtonClick}>
        <DefinitionList>
          <dt>Name:</dt>
          <dd>
            <strong>
              {personalDetails.title} {personalDetails.firstName} {personalDetails.middleName} {personalDetails.lastName}
            </strong>
          </dd>
          <dt>Date of Birth:</dt>
          <dd>
            <strong>{dateOfBirthDate.isValid() ? dateOfBirthDate.format('DD/MM/YYYY') : 'Not supplied'}</strong>
          </dd>
        </DefinitionList>
      </SegmentBlock>
    </section>
  );
};
