import styled from '@assets/styles/styled';
// @ts-ignore
import { Link } from '@experian-uk/corvetteuk-common-ui';

import { ContainerStyled } from '@modules/core/container';

export const LogosContainer = styled.div`
  padding-top: 12px;
`;

export const LogoCpp = styled.img`
  float: right;
  width: auto;
  max-width: 150px;
  max-height: 70px;
`;

export const LinkCpp = styled(Link.Anchor)`
  text-align: right;
`;

export const SubNav = styled.div`
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: ${({ theme }) => theme.colors.baseBackgroundBlue};
  border-top: 1px solid ${({ theme }) => theme.colors.baseMediumGrey};
`;

export const LogoutLink = styled(Link.Anchor)`
  float: right;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
`;

export const HeaderLinkContainer = styled(ContainerStyled)`
  padding-top: 0
    ${({ theme: { media } }) =>
      media(
        '15',
        `
    padding: 0;
  `,
      )};
`;
