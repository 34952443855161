// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent } from 'react';

import { StyledLabel } from '@modules/core/label';
import { SegmentBlock } from '@modules/core/segment-block/segment-block';
import { SegmentBlockColumn } from '@modules/core/segment-block/segment-block-column';
import { StyledSpacer } from '@modules/core/spacer';
import { CurrentAddressModel } from '@modules/domain/address/models';
import { getHouseValue } from '@partnerships/common/modules/utils';
import { CurrentAddressPanel } from '.';

interface CurrentAddressNoControlProps {
  currentAddress: CurrentAddressModel;
  fromMonth: string;
  fromYear: string;
  handleEditButtonClick?: () => void;
}

const CurrentAddressNoControl: FunctionComponent<CurrentAddressNoControlProps> = ({
  currentAddress,
  fromMonth,
  fromYear,
  handleEditButtonClick,
}): JSX.Element => {
  if (currentAddress) {
    const { city, flat, houseName, houseNumber, postCode, street, district, county } = currentAddress;
    const house = getHouseValue(flat, houseName, houseNumber);

    return (
      <React.Fragment>
        <StyledSpacer>
          <Typography.Headings.Secondary>Current address</Typography.Headings.Secondary>
        </StyledSpacer>
        <SegmentBlock handleEditButtonClick={handleEditButtonClick}>
          <SegmentBlockColumn width="2">
            <CurrentAddressPanel house={house} street={street} district={district} city={city} county={county} postCode={postCode} />
            <StyledSpacer />

            <Typography.Copy.Text>
              {`You moved into this address in `}
              <StyledLabel>{`${fromMonth} ${fromYear}`}</StyledLabel>
            </Typography.Copy.Text>
          </SegmentBlockColumn>
        </SegmentBlock>
      </React.Fragment>
    );
  }

  return <></>;
};

CurrentAddressNoControl.displayName = 'current-address-no-control';

export { CurrentAddressNoControl };
