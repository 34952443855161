import gql from 'graphql-tag';

import { Address } from '../models';
import { AddressLookUpItem } from './models';

export interface AddressLookUpItemsQueryResult {
  postCodeLookupItems: AddressLookUpItem[];
}

export interface AddressFromLookUpId {
  addressFromPostCodeLookup: Address;
}

export const GET_ADDRESS_LOOKUP_ITEMS_QUERY = gql`
  query getPostCodeLookupItems($postCode: String!, $house: String!) {
    postCodeLookupItems(postCode: $postCode, house: $house) {
      id
      text
    }
  }
`;

export const GET_ADDRESS_FROM_LOOKUP_ID = gql`
  query addressFromPostCodeLookup($lookUpItemId: String!) {
    addressFromPostCodeLookup(lookUpItemId: $lookUpItemId) {
      country
      county
      district
      flat
      houseNumber
      houseName
      postCode
      street
      city
    }
  }
`;
