// @ts-ignore
import { Button } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent } from 'react';

import { StyledFormEdit } from './form-edit.styled';

interface FormEditProps {
  disabled?: boolean;
  handleClose: () => void;
  hideSubmitButton?: boolean;
  submitButtonText: string;
  submitButtonTestId?: string;
}

export const FormEdit: FunctionComponent<FormEditProps> = ({
  disabled,
  children,
  hideSubmitButton,
  submitButtonText,
  submitButtonTestId = '',
}): JSX.Element => {
  return (
    // @ts-ignore
    <StyledFormEdit>
      {children}
      {!hideSubmitButton && (
        <Button data-testid={submitButtonTestId} type="primary" label={submitButtonText} isButton aria-label="Find Address Button" />
      )}
    </StyledFormEdit>
  );
};
