// @ts-ignore
import { Link } from '@experian-uk/corvetteuk-common-ui';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import React, { FormEvent, FunctionComponent } from 'react';

import { flexDirection } from '@modules/core/flex-container';
import { StyledFlexDivContainer } from '@modules/core/flex-container/flex-container.styled';
import { StyledFlexRow } from '@modules/core/flex-row';
import { FormEdit } from '@modules/core/form-edit';
import { StyledFieldInput } from '@modules/core/form-field';
import { FormGroup } from '@modules/core/form-group';
import { FormRow } from '@modules/core/form-row/form-row';
import { FormSelectMulti } from '@modules/core/form-select-multi';
import { defaultMonthValue, defaultYearValue } from '@modules/core/validation';
import { AddAddressFormProps } from '@modules/domain/address/props/add-address.props';
import { StyledFormSpan } from '@modules/domain/address/verified-address-form/verified-address-form.styled';
import { getMonthsValues, getYearsValues } from '@utils/date-values';
import { ManualAddressFormModel } from './manual-address-form.model';
import { manualAddressFormSchema } from './manual-address-form.schema';

const getMonthSelectOptions = () => {
  const defaultOption = { key: defaultMonthValue, value: defaultMonthValue };
  const monthOptions = getMonthsValues().map(month => ({ key: month, value: month }));
  return [defaultOption, ...monthOptions];
};

const getYearSelectOptions = () => {
  const defaultOption = { key: defaultYearValue, value: defaultYearValue };
  const monthOptions = getYearsValues().map(year => ({ key: year, value: year }));
  return [defaultOption, ...monthOptions];
};

const handleResidentFromFormGroupErrors = (
  errors: FormikErrors<ManualAddressFormModel>,
  touched: FormikTouched<ManualAddressFormModel>,
): string | undefined => {
  if (errors.fromMonth && touched.fromMonth) {
    return errors.fromMonth;
  }

  if (errors.fromYear && touched.fromYear) {
    return errors.fromYear;
  }

  return undefined;
};

const handleResidentToFormGroupErrors = (
  errors: FormikErrors<ManualAddressFormModel>,
  touched: FormikTouched<ManualAddressFormModel>,
): string | undefined => {
  if (errors.toMonth && touched.toMonth) {
    return errors.toMonth;
  }

  if (errors.toYear && touched.toYear) {
    return errors.toYear;
  }

  return undefined;
};

interface ManualAddressFormProps {
  title: string;
  isEditing?: boolean;
}

const ManualAddressForm: FunctionComponent<AddAddressFormProps<ManualAddressFormModel> & ManualAddressFormProps> = ({
  initialFormValues,
  title,
  isEditing = false,
  onCloseForm,
  onFormSubmit,
}): JSX.Element => (
  <Formik initialValues={initialFormValues} onSubmit={formModel => onFormSubmit(formModel)} validationSchema={manualAddressFormSchema}>
    {({ errors, setFieldValue, touched, values }) => (
      <FormEdit
        handleClose={onCloseForm}
        submitButtonText={isEditing ? 'Update Address' : 'Add Address'}
        submitButtonTestId="update-address-button"
      >
        <StyledFlexRow reversed wrap>
          <Link.Button onClick={onCloseForm}>{isEditing ? 'Cancel' : 'Add Later'}</Link.Button>
          <FormRow justifyContent="flex-start" maxWidth="448px">
            <FormGroup
              id="houseName"
              labelText="House name"
              value={values.houseName ? values.houseName : ''}
              errors={errors.houseName || errors.houseNumber}
              touched={touched.houseName || touched.houseNumber}
              required={false}
              flexBasis="180px"
              paddingTop="0"
              marginTop="0"
            >
              <StyledFieldInput
                type="text"
                id="houseName"
                name="houseName"
                data-testid="manual-houseName"
                value={values.houseName ? values.houseName : ''}
                aria-describedby="houseName-error"
                aria-required={true}
                aria-invalid={errors.houseName !== undefined}
                spellCheck={false}
              />
            </FormGroup>
            <StyledFlexDivContainer flex="0 1 auto" direction={flexDirection.ROW}>
              <StyledFormSpan>
                <strong>OR</strong>
              </StyledFormSpan>
            </StyledFlexDivContainer>
            <FormGroup
              id="houseNumber"
              labelText="House number"
              value={values.houseNumber}
              errors={errors.houseNumber || errors.houseName}
              touched={touched.houseNumber || touched.houseName}
              required={false}
              flexBasis="180px"
              paddingTop="0"
              marginTop="0"
            >
              <StyledFieldInput
                type="text"
                id="houseNumber"
                name="houseNumber"
                data-testid="manual-houseNumber"
                value={values.houseNumber ? values.houseNumber : ''}
                aria-describedby="houseNumber-error"
                aria-required={true}
                aria-invalid={errors.houseNumber !== undefined}
              />
            </FormGroup>
          </FormRow>
        </StyledFlexRow>
        <FormGroup id="flat" labelText="Flat (optional)" value={values.flat} errors={errors.flat} touched={touched.flat} required={false}>
          <StyledFieldInput
            type="text"
            id="flat"
            name="flat"
            data-testid="flat"
            value={values.flat}
            aria-describedby="flat-error"
            aria-required={true}
            aria-invalid={errors.flat !== undefined}
            flexbasis="48%"
            spellCheck={false}
          />
        </FormGroup>

        <FormGroup
          id="street"
          labelText="Street name"
          value={values.street}
          errors={errors.street}
          touched={touched.street}
          required={false}
        >
          <StyledFieldInput
            type="text"
            id="street"
            name="street"
            data-testid="street"
            value={values.street}
            aria-describedby="street-error"
            aria-required={true}
            aria-invalid={errors.street !== undefined}
            flexbasis="48%"
            spellCheck={false}
          />
        </FormGroup>

        <FormGroup
          id="district"
          labelText="District"
          value={values.district}
          errors={errors.district}
          touched={touched.district}
          required={false}
        >
          <StyledFieldInput
            type="text"
            id="district"
            name="district"
            data-testid="district"
            value={values.district}
            aria-describedby="district-error"
            aria-required={true}
            aria-invalid={errors.district !== undefined}
            flexbasis="48%"
            spellCheck={false}
          />
        </FormGroup>

        <FormGroup
          id="postTownOrCity"
          labelText="Post town/city"
          value={values.postTownOrCity}
          errors={errors.postTownOrCity}
          touched={touched.postTownOrCity}
          required={false}
        >
          <StyledFieldInput
            type="text"
            id="postTownOrCity"
            name="postTownOrCity"
            data-testid="postTownOrCity"
            value={values.postTownOrCity}
            aria-describedby="postTownOrCityerror"
            aria-required={true}
            aria-invalid={errors.postTownOrCity !== undefined}
            flexbasis="48%"
            spellCheck={false}
          />
        </FormGroup>

        <FormGroup id="county" labelText="County" value={values.county} errors={errors.county} touched={touched.county} required={false}>
          <StyledFieldInput
            type="text"
            id="county"
            name="county"
            data-testid="county"
            value={values.county}
            aria-describedby="county-error"
            aria-required={true}
            aria-invalid={errors.county !== undefined}
            flexbasis="48%"
            spellCheck={false}
          />
        </FormGroup>

        <FormGroup
          id="postCode"
          labelText="Postcode"
          value={values.postCode}
          errors={errors.postCode}
          touched={touched.postCode}
          required={false}
        >
          <StyledFieldInput
            type="text"
            id="postCode"
            name="postCode"
            data-testid="manual-postCode"
            value={values.postCode}
            aria-describedby="postCode-error"
            aria-required={true}
            aria-invalid={errors.postCode !== undefined}
            flexbasis="48%"
            spellCheck={false}
          />
        </FormGroup>

        <FormGroup
          id="residentFrom"
          errorId="residentFromGroupError"
          labelText="When did you move into this address?"
          errors={handleResidentFromFormGroupErrors(errors, touched)}
          value={values.fromMonth && values.fromYear}
          touched={touched.fromMonth || touched.toMonth}
          required={true}
        >
          <FormSelectMulti
            selects={[
              {
                label: 'Month',
                id: 'fromMonth',
                isValid: !errors.fromMonth,
                errorId: 'residentFromGroupError',
                name: 'Month',
                options: getMonthSelectOptions(),
                value: values.fromMonth,
                onChange: (event: FormEvent<HTMLInputElement>) => {
                  const { name, value } = event.currentTarget;
                  setFieldValue(name, value);
                },
              },
              {
                label: 'Year',
                id: 'fromYear',
                isValid: !errors.fromYear,
                errorId: 'residentFromGroupError',
                name: 'Year',
                options: getYearSelectOptions(),
                value: values.fromYear,
                onChange: (event: FormEvent<HTMLInputElement>) => {
                  const { name, value } = event.currentTarget;
                  setFieldValue(name, value);
                },
              },
            ]}
          />
        </FormGroup>

        <FormGroup
          id="residentTo"
          errorId="residentToGroupError"
          labelText="When did you move out of this address?"
          value={values.toMonth && values.toYear}
          errors={handleResidentToFormGroupErrors(errors, touched)}
          touched={touched.toMonth || touched.toYear}
          required={true}
        >
          <FormSelectMulti
            selects={[
              {
                label: 'Month',
                id: 'toMonth',
                isValid: !errors.toMonth,
                errorId: 'residentToGroupError',
                name: 'Month',
                options: getMonthSelectOptions(),
                value: values.toMonth,
                onChange: (event: FormEvent<HTMLInputElement>) => {
                  const { name, value } = event.currentTarget;
                  setFieldValue(name, value);
                },
              },
              {
                label: 'Year',
                id: 'toYear',
                isValid: !errors.toYear,
                errorId: 'residentToGroupError',
                name: 'Year',
                options: getYearSelectOptions(),
                value: values.toYear,
                onChange: (event: FormEvent<HTMLInputElement>) => {
                  const { name, value } = event.currentTarget;
                  setFieldValue(name, value);
                },
              },
            ]}
          />
        </FormGroup>
        <br />
      </FormEdit>
    )}
  </Formik>
);

ManualAddressForm.displayName = 'manual-address-form';

export { ManualAddressForm };
