import React, { FunctionComponent, useEffect, useState } from 'react';
import Modal from 'react-modal';

import { appTheme } from '@assets/styles/styled';
import { StyledModalClose } from '.';

interface ModalBoxProps {
  title?: string;
  isOpen: boolean;
}

export const ModalBox: FunctionComponent<ModalBoxProps> = ({ isOpen, children, title = '' }): JSX.Element => {
  Modal.setAppElement('#root');
  const [visible, setVisibility] = useState(!isOpen);

  useEffect(() => {
    setVisibility(!visible);
  }, [isOpen]);

  return (
    <Modal
      isOpen={visible}
      contentLabel={title}
      onRequestClose={() => setVisibility(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
        },
        content: {
          boxShadow: `0  0.125rem 0.1875rem 0.0625rem ${appTheme.colors.typeNeutral}`,
          maxWidth: '90%',
          width: '65rem',
          height: 'auto',
          position: 'relative',
          margin: 'auto',
          left: 'auto',
          right: 'auto',
          top: 'auto',
          bottom: 'auto',
        },
      }}
    >
      <StyledModalClose onClick={() => setVisibility(false)}>X</StyledModalClose>
      {children}
    </Modal>
  );
};
