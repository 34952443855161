import styled from 'styled-components';

import { hiddenVisually } from '@assets/styles/mixins';

export const StyledFormSelectMulti = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  label {
    ${hiddenVisually};
  }
`;
