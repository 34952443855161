import React, { FunctionComponent } from 'react';

import { StyledFormRow } from './form-row.styled';

interface FormRowProps {
  justifyContent?: string;
  maxWidth?: string;
  wrap?: boolean;
}

export const FormRow: FunctionComponent<FormRowProps> = ({ justifyContent, maxWidth, wrap, children }): JSX.Element => (
  <StyledFormRow justifyContent={justifyContent} maxWidth={maxWidth} wrap={wrap}>
    {children}
  </StyledFormRow>
);
