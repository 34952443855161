import { Context, UserSessionUpdate } from '@partnerships/common/modules/user-session';
import gql from 'graphql-tag';

export const PERFORM_ONBOARD_LBG = gql`
  mutation OnboardLbg($request: OnboardLbg!) {
    onboardLbg(request: $request) {
      state
    }
  }
`;

export const PERFORM_ONBOARD_CPP = gql`
  mutation OnboardCpp($request: OnboardCpp!) {
    onboardCpp(request: $request) {
      state
    }
  }
`;

export interface OnboardLbg {
  clientId: string;
  context: Context;
}

export interface OnboardCpp {
  clientId: string;
}

export interface OnboardLbgResponse {
  onboardLbg: UserSessionUpdate;
}

export interface OnboardCppResponse {
  onboardCpp: UserSessionUpdate;
}
