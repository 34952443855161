// @ts-ignore
import { LoadingSpinner } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent } from 'react';

import { StyledLoading } from './loading.styled';

interface LoadingProps {
  label?: string;
}

export const Loading: FunctionComponent<LoadingProps> = ({ label }): JSX.Element => (
  <StyledLoading>
    <LoadingSpinner label={label} />
  </StyledLoading>
);
