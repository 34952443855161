import { UserSessionLocalStorageKey } from '../constants';
import { UserSession, UserSessionResult } from '../user-session.query';

class NewUserSessionFactory {
  create = (sessionId: string): Promise<UserSessionResult> => {
    return this.getNewUserSession(sessionId);
  };

  private getNewUserSession = async (sessionId: string): Promise<UserSessionResult> => {
    try {
      const response = await this.getUserSessionApiResult(sessionId);

      const json = await response.json();

      if (!json.data) {
        return Promise.reject(new Error('Unable to get user session'));
      }

      this.storeUserSession(json.data.userSession);
      return Promise.resolve<UserSessionResult>(json.data);
    } catch (e) {
      throw e;
    }
  };

  private getUserSessionApiResult = async (sessionId: string): Promise<any> => {
    return fetch('/web-api/getSession/?sessionId=' + sessionId, {
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      method: 'GET',
      credentials: 'include',
    });
  };

  private getDataQueryString = (): string => {
    return `
      query getSession($sessionId: String!) {
        userSession(sessionId: $sessionId) {
          clientId
          state
          context {
            ... on LbgContext {
              subProduct,
              offline,
              returnToLBGURL,
              returnToVPDTabURL
            }
          }
        }
      }
    `;
  };

  private storeUserSession = (userSession: UserSession): void => {
    localStorage.removeItem(UserSessionLocalStorageKey);
    localStorage.setItem(UserSessionLocalStorageKey, JSON.stringify(userSession));
  };
}

export const newUserSessionFactory = new NewUserSessionFactory();
