import { PageLayout } from '@modules/domain/page-layout';
import { Onboarding } from '@modules/domain/sign-up/onboarding';
import React, { FunctionComponent } from 'react';
import { RouteChildrenProps } from 'react-router';

export const ActivationPage: FunctionComponent<RouteChildrenProps> = (props): JSX.Element => {
  return (
    <PageLayout title="Welcome to Identity Plus" metaTitle="Welcome to Identity Plus" sidebar={false}>
      <Onboarding authSuccessMessage="Redirecting, Please Wait" homeUrl="/" inProgressMessage="Please Wait" {...props} />
    </PageLayout>
  );
};
