// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';

import React, { createContext, FunctionComponent, useContext } from 'react';
import { useQuery } from 'react-apollo-hooks';

import { Error } from '@modules/core/error';
import { Loading } from '@modules/core/loading';
import { SegmentBlock } from '@modules/core/segment-block/segment-block';
import { StyledSegmentBlockImage } from '@modules/core/segment-block/segment-block.styled';
import { CPP_POLICY_NUMBER_QUERY, CPPPolicyNumberQueryResult } from '../queries/cpp-policy-number.query';

interface PolicyNumberContextModel {
  policyNumber: string;
}

export const PolicyNumberContext = createContext<PolicyNumberContextModel>({ policyNumber: '-' });

export const SectionPolicyNumber: FunctionComponent = (): JSX.Element => {
  const { data, loading } = useQuery<CPPPolicyNumberQueryResult>(CPP_POLICY_NUMBER_QUERY);
  const policyNumberContext = useContext(PolicyNumberContext);

  if (loading) {
    return <Loading />;
  }

  if (data && data.cppUserDetails) {
    policyNumberContext.policyNumber = data.cppUserDetails.policyNumber;

    return (
      <section data-testid="section-policy-number">
        <Typography.Headings.Secondary>Policy number</Typography.Headings.Secondary>
        <SegmentBlock withImage={true}>
          <StyledSegmentBlockImage>
            <img data-testid="cpp-logo" src="/cpp-assets/images/cpp-logo.svg" alt="CPP Logo" />
          </StyledSegmentBlockImage>
          <Typography.Copy.Text>
            Your policy number is <strong>{data.cppUserDetails.policyNumber}</strong>
          </Typography.Copy.Text>
        </SegmentBlock>
      </section>
    );
  }

  return <Error error="There was an error retrieving your data" />;
};
