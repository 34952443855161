import { defaultMonthValue, defaultYearValue } from '@modules/core/validation';
import { PreviousAddress } from '@modules/domain/address/models/previous-address.model';

export interface AddressDetailsModel {
  currentAddress?: {
    flat?: string;
    houseName?: string;
    houseNumber?: string;
    street: string;
    city: string;
    district: string;
    county: string;
    postCode: string;
  };
  currentAddressFromMonth: string;
  currentAddressFromYear: string;
  currentAddressMonthValue: string;
  currentAddressPreviousMonthValue: string;
  currentAddressYearValue: string;
  currentAddressPreviousYearValue: string;
  previousAddresses: PreviousAddress[];
  hasGapsInAddressHistory: boolean;
  hasRecommendedAmountOfAddressHistory: boolean;
  hasMinimumAmountOfMonthsAtCurrentAddress: boolean;
  showRemovePreviousAddressesWarning: boolean;
  updatePending: boolean;
}

export const addressState: AddressDetailsModel = {
  currentAddress: {
    flat: '',
    houseName: '',
    houseNumber: '',
    street: '',
    city: '',
    district: '',
    county: '',
    postCode: '',
  },
  currentAddressFromMonth: '',
  currentAddressFromYear: '',
  currentAddressMonthValue: defaultMonthValue,
  currentAddressYearValue: defaultYearValue,
  currentAddressPreviousMonthValue: defaultMonthValue,
  currentAddressPreviousYearValue: defaultYearValue,
  previousAddresses: [],
  hasGapsInAddressHistory: false,
  hasRecommendedAmountOfAddressHistory: false,
  hasMinimumAmountOfMonthsAtCurrentAddress: false,
  showRemovePreviousAddressesWarning: false,
  updatePending: false,
};
