import { defaultMonthValue, defaultYearValue } from '@modules/core/validation';
import { AddressDetailsAction } from '@modules/domain/address/dux';
import { AddressDetailsModel } from '@modules/domain/address/dux/state/address-details.state';
import { AddressHistoryItem, CurrentAddressModel, PreviousAddress } from '@modules/domain/address/models';
import { hasGapsInAddressHistory, hasRequiredAmountOfAddressHistory } from '@modules/domain/sign-up';
import { ADDRESS_TYPE } from '@shared/const';

export function addressDetailsReducer(state: AddressDetailsModel, action: AddressDetailsAction): AddressDetailsModel {
  switch (action.type) {
    case 'addCurrentAddress': {
      return reduceCurrentAddress(state, action.currentAddress);
    }

    case 'addPreviousAddress': {
      return reduceAddPreviousAddress(state, action.addressHistoryItem);
    }

    case 'setPreviousAddresses': {
      return reduceSetPreviousAddresses(state, action.addressHistoryItems, action.updatePending);
    }

    case 'cancelOverwritePreviousAddress': {
      return reduceCancelOverwritePreviousAddress(state);
    }

    case 'confirmedCurrentAddressResidencyFromDate': {
      return reduceConfirmedCurrentAddressResidencyFromDate(state);
    }

    case 'currentAddressMonthChanged': {
      return reduceCurrentAddressMonthValueChanged(state, action.newValue);
    }

    case 'currentAddressYearChanged': {
      return reduceCurrentAddressYearValueChanged(state, action.newValue);
    }

    case 'editPreviousAddress': {
      return reduceEditPreviousAddress(state, action.previousAddress);
    }

    case 'overwritePreviousAddressesConfirmed': {
      return reduceOverwritePreviousAddressesConfirmed(state);
    }

    case 'resetAddressUpdatePending': {
      return reduceResetUpdatePending(state);
    }

    default: {
      return state;
    }
  }
}

const addressesToAddressPeriod = (previousAddress: PreviousAddress) => ({
  start: {
    month: previousAddress.fromMonth,
    year: previousAddress.fromYear,
  },
  end: {
    month: previousAddress.toMonth,
    year: previousAddress.toYear,
  },
});

const calculateAddressHistoryPeriod = (currentAddressMonth: string, currentAddressYear: string, previousAddresses: PreviousAddress[]) => {
  const currentAddressPeriod = { month: currentAddressMonth, year: currentAddressYear };

  const prevAddressPeriods = previousAddresses.map(addressesToAddressPeriod);
  const hasRecommendedAmountOfAddressHistory = hasRequiredAmountOfAddressHistory(currentAddressPeriod, prevAddressPeriods);
  const gapsInAddressHistory = hasGapsInAddressHistory(currentAddressPeriod, prevAddressPeriods);
  const hasMinimumAmountOfMonthsAtCurrentAddress = hasRequiredAmountOfAddressHistory(currentAddressPeriod);

  return {
    gapsInAddressHistory,
    hasRecommendedAmountOfAddressHistory,
    hasMinimumAmountOfMonthsAtCurrentAddress,
  };
};

export const reduceCurrentAddress = (prevState: AddressDetailsModel, currentAddress: CurrentAddressModel): AddressDetailsModel => {
  const newState = {
    ...prevState,
    currentAddress,
  };

  return newState;
};

export const reduceSetPreviousAddresses = (
  prevState: AddressDetailsModel,
  addressHistoryItems: AddressHistoryItem[],
  updatePending: boolean,
): AddressDetailsModel => {
  const { currentAddressMonthValue, currentAddressYearValue } = prevState;

  const newPreviousAddresses = addressHistoryItems.map((addressHistoryItem: AddressHistoryItem, index: number) => {
    const addressId = index + 1;

    const newAddress: PreviousAddress = {
      ...addressHistoryItem,
      id: addressId,
    };

    return newAddress;
  });

  const {
    gapsInAddressHistory,
    hasRecommendedAmountOfAddressHistory,
    hasMinimumAmountOfMonthsAtCurrentAddress,
  } = calculateAddressHistoryPeriod(currentAddressMonthValue, currentAddressYearValue, newPreviousAddresses);

  return {
    ...prevState,
    hasGapsInAddressHistory: gapsInAddressHistory,
    hasRecommendedAmountOfAddressHistory,
    hasMinimumAmountOfMonthsAtCurrentAddress,
    previousAddresses: newPreviousAddresses,
    updatePending,
  };
};

export const reduceAddPreviousAddress = (prevState: AddressDetailsModel, addressHistoryItem: AddressHistoryItem): AddressDetailsModel => {
  const { currentAddressMonthValue, currentAddressYearValue, previousAddresses } = prevState;

  const addressId = previousAddresses.length + 1;

  const newAddress: PreviousAddress = {
    ...addressHistoryItem,
    id: addressId,
    addressType: ADDRESS_TYPE.previousAddress,
  };

  const updatedPreviousAddresses = [newAddress, ...previousAddresses];

  const {
    gapsInAddressHistory,
    hasRecommendedAmountOfAddressHistory,
    hasMinimumAmountOfMonthsAtCurrentAddress,
  } = calculateAddressHistoryPeriod(currentAddressMonthValue, currentAddressYearValue, updatedPreviousAddresses);

  return {
    ...prevState,
    hasGapsInAddressHistory: gapsInAddressHistory,
    hasRecommendedAmountOfAddressHistory,
    hasMinimumAmountOfMonthsAtCurrentAddress,
    previousAddresses: updatedPreviousAddresses,
    updatePending: true,
  };
};

export const reduceEditPreviousAddress = (prevState: AddressDetailsModel, updatedPreviousAddress: PreviousAddress): AddressDetailsModel => {
  const { currentAddressMonthValue, currentAddressYearValue, previousAddresses } = prevState;

  const updatedPreviousAddresses = previousAddresses.map(pa =>
    pa.id === updatedPreviousAddress.id ? Object.assign(pa, updatedPreviousAddress) : pa,
  );

  const {
    gapsInAddressHistory,
    hasRecommendedAmountOfAddressHistory,
    hasMinimumAmountOfMonthsAtCurrentAddress,
  } = calculateAddressHistoryPeriod(currentAddressMonthValue, currentAddressYearValue, updatedPreviousAddresses);

  return {
    ...prevState,
    hasGapsInAddressHistory: gapsInAddressHistory,
    hasRecommendedAmountOfAddressHistory,
    hasMinimumAmountOfMonthsAtCurrentAddress,
    previousAddresses: updatedPreviousAddresses,
    updatePending: true,
  };
};

export const reduceCancelOverwritePreviousAddress = (prevState: AddressDetailsModel): AddressDetailsModel => {
  const {
    currentAddressMonthValue,
    currentAddressYearValue,
    currentAddressPreviousMonthValue,
    currentAddressPreviousYearValue,
    previousAddresses,
  } = prevState;

  const prevMonthWasDefault = currentAddressPreviousMonthValue === defaultMonthValue;
  const prevYearWasDefault = currentAddressPreviousYearValue === defaultYearValue;

  const monthVal = prevMonthWasDefault ? currentAddressMonthValue : currentAddressPreviousMonthValue;
  const yearVal = prevYearWasDefault ? currentAddressYearValue : currentAddressPreviousYearValue;

  const {
    gapsInAddressHistory,
    hasRecommendedAmountOfAddressHistory,
    hasMinimumAmountOfMonthsAtCurrentAddress,
  } = calculateAddressHistoryPeriod(monthVal, yearVal, previousAddresses);

  return {
    ...prevState,
    currentAddressMonthValue: monthVal,
    currentAddressYearValue: yearVal,
    hasMinimumAmountOfMonthsAtCurrentAddress,
    hasRecommendedAmountOfAddressHistory,
    hasGapsInAddressHistory: gapsInAddressHistory,
    showRemovePreviousAddressesWarning: false,
  };
};

export const reduceConfirmedCurrentAddressResidencyFromDate = (prevState: AddressDetailsModel): AddressDetailsModel => {
  const { currentAddressMonthValue, currentAddressYearValue, previousAddresses } = prevState;
  const {
    gapsInAddressHistory,
    hasRecommendedAmountOfAddressHistory,
    hasMinimumAmountOfMonthsAtCurrentAddress,
  } = calculateAddressHistoryPeriod(currentAddressMonthValue, currentAddressYearValue, previousAddresses);

  return {
    ...prevState,
    hasGapsInAddressHistory: gapsInAddressHistory,
    hasRecommendedAmountOfAddressHistory,
    hasMinimumAmountOfMonthsAtCurrentAddress,
    showRemovePreviousAddressesWarning: previousAddresses.length > 0 && hasMinimumAmountOfMonthsAtCurrentAddress,
  };
};

export const reduceCurrentAddressMonthValueChanged = (prevState: AddressDetailsModel, newValue: string): AddressDetailsModel => {
  const { currentAddressMonthValue } = prevState;

  return {
    ...prevState,
    currentAddressMonthValue: newValue,
    currentAddressPreviousMonthValue: currentAddressMonthValue,
  };
};

export const reduceCurrentAddressYearValueChanged = (prevState: AddressDetailsModel, newValue: string): AddressDetailsModel => {
  const { currentAddressYearValue } = prevState;

  return {
    ...prevState,
    currentAddressYearValue: newValue,
    currentAddressPreviousYearValue: currentAddressYearValue,
  };
};

export const reduceOverwritePreviousAddressesConfirmed = (prevState: AddressDetailsModel): AddressDetailsModel => {
  const { currentAddressMonthValue, currentAddressYearValue, previousAddresses } = prevState;

  const {
    gapsInAddressHistory,
    hasRecommendedAmountOfAddressHistory,
    hasMinimumAmountOfMonthsAtCurrentAddress,
  } = calculateAddressHistoryPeriod(currentAddressMonthValue, currentAddressYearValue, previousAddresses);

  return {
    ...prevState,
    hasGapsInAddressHistory: gapsInAddressHistory,
    hasRecommendedAmountOfAddressHistory,
    hasMinimumAmountOfMonthsAtCurrentAddress,
    showRemovePreviousAddressesWarning: false,
    previousAddresses: [],
  };
};

export const reduceResetUpdatePending = (prevState: AddressDetailsModel): AddressDetailsModel => {
  const newState = {
    ...prevState,
    updatePending: false,
  };

  return newState;
};
