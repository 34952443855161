// @ts-ignore
import { Notification, notificationVariant } from '@experian-uk/corvetteuk-common-ui';
import { Fade } from '@modules/core/animated';
import { StyledSpacer } from '@modules/core/spacer';
import React, { FunctionComponent } from 'react';

interface RemovePreviousAddressesWarningProps {
  onCancel: () => void;
  onConfirm: () => void;
}

const RemovePreviousAddressesWarning: FunctionComponent<RemovePreviousAddressesWarningProps> = ({ onCancel, onConfirm }): JSX.Element => (
  <>
    <StyledSpacer>
      <Fade fadeDirection={'in'}>
        <Notification
          notificationTitle="Please provide confirmation"
          variant={notificationVariant.warning}
          withRefresh
          refreshFunc={onConfirm}
          refreshText="Confirm"
          ctaLinkFunc={onCancel}
          ctaLinkText="Cancel"
        >
          A moved in date of 6 or more years ago will remove the previous address from your profile. Do you wish to procced?
        </Notification>
      </Fade>
    </StyledSpacer>
  </>
);

RemovePreviousAddressesWarning.displayName = 'remove-previous-address-warning';

export { RemovePreviousAddressesWarning };
