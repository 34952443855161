// @ts-ignore
import { Button, setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import React, { FunctionComponent } from 'react';
import { RouteChildrenProps } from 'react-router';

import { ExternalRoutes, PdfRoutes, ReactivationRoutes, SignUpRoutes } from '@app/routes';
import { ButtonGroup } from '@modules/core/button-group';
import { DocumentLink } from '@modules/core/document-link';
import { NotePanel } from '@modules/core/note-panel/note-panel';
import { SpaLink } from '@modules/core/spa-link';
import { PageLayout } from '@modules/domain/page-layout';
import { WelcomeSection } from '@modules/domain/welcome-section';

interface WelcomePageProps extends RouteChildrenProps {
  isReactivation: boolean;
}

export const WelcomePage: FunctionComponent<WelcomePageProps> = ({ history, isReactivation }): JSX.Element => (
  <PageLayout title="What is Identity Plus?" metaTitle="What is Identity Plus?" sidebar={true}>
    <WelcomeSection />
    <NotePanel testId="notice-panel-terms-privacy">
      Please note that the Identity Plus services is provided by Experian. Please refer to the Experian{' '}
      <DocumentLink inline uri={PdfRoutes.PrivacyPolicy} label="Privacy Policy" /> and{' '}
      <DocumentLink inline uri={PdfRoutes.TermsAndConditions} label="Terms &amp; Conditions" /> for Identity Plus.
    </NotePanel>
    {isReactivation ? (
      <ButtonGroup>
        <SpaLink to={ReactivationRoutes.Index}>Go back to reactivation</SpaLink>
      </ButtonGroup>
    ) : (
      <ButtonGroup>
        <Button href={ExternalRoutes.CppIdentity} type="secondary" {...setAutomationElement('go-back-to-cpp')} label="Go back to CPP" />
        <Button
          {...setAutomationElement('register-for-identity')}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            history.push(SignUpRoutes.PersonalDetails);
          }}
          label="Register for Identity Plus"
        />
      </ButtonGroup>
    )}
  </PageLayout>
);
