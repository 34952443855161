import React from 'react';
import { Query } from 'react-apollo';

import { Loading } from '@modules/core/loading';

import { StyledRegistrationStatus, StyledUserHeader } from '.';
import { USER_DETAILS_QUERY, UserDetails, UserDetailsQueryResult } from './user-details.query';

const getDisplayText = (userDetails: UserDetails): string => {
  const { title, firstName, lastName } = userDetails;
  return title ? `${title} ${firstName} ${lastName}` : `${firstName} ${lastName}`;
};

export const UserHeader = (): JSX.Element => (
  <Query<UserDetailsQueryResult> query={USER_DETAILS_QUERY}>
    {({ data, error, loading }) => {
      if (error || (!loading && (!data || !data.userDetails))) {
        return null;
      }

      if (loading) {
        return <Loading />;
      }

      return (
        <StyledUserHeader>
          <strong>{getDisplayText(data!.userDetails)}</strong>
          <StyledRegistrationStatus>Not Registered</StyledRegistrationStatus>
        </StyledUserHeader>
      );
    }}
  </Query>
);
