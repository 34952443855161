// @ts-ignore
import { baseTheme } from '@experian-uk/corvetteuk-common-ui';
import * as styledComponents from 'styled-components';

import { ThemedStyledComponentsModule } from 'styled-components';

const { default: styled, css, createGlobalStyle, keyframes, ThemeProvider } = styledComponents as ThemedStyledComponentsModule<any
>;

const appTheme = {
  ...baseTheme,
};

export { css, createGlobalStyle, keyframes, ThemeProvider, appTheme };
export default styled;
