import styled from '@assets/styles/styled';

export const StyledModalClose = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1.75rem;
  height: 1.75rem;
  background: 0;
  border: 0;

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.baseOutline};
    outline: auto;
  }
`;
