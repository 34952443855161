import { ErrorRoutes } from '@partnerships/common/modules/routes';
import { UserSessionLocalStorageKey, UserSessionState } from '@partnerships/common/modules/user-session/constants';
import { terminateUserSession } from '@partnerships/common/modules/user-session/user-session-utils';
import { ApolloLink, InMemoryCache, NormalizedCacheObject } from 'apollo-boost';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { ErrorResponse, onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import 'whatwg-fetch';

const errorLink = onError(({ networkError }: ErrorResponse) => {
  if (networkError) {
    if ('statusCode' in networkError && networkError.statusCode === 401) {
      if (window.location.pathname !== ErrorRoutes.SessionTimeout) {
        terminateUserSession(UserSessionState.sessionExpired);
        window.location.href = ErrorRoutes.SessionTimeout;
      }
    }
  }
});

const httpLink: ApolloLink = createHttpLink({
  uri: '/graphql',
  fetch: window.fetch,
  credentials: 'same-origin',
});

const authLink: ApolloLink = setContext((_, { headers }) => {
  const userSessionData = localStorage.getItem(UserSessionLocalStorageKey);

  if (!userSessionData) {
    return null;
  }

  const userSession = JSON.parse(userSessionData);

  return {
    headers: {
      ...headers,
      'x-brandIdentifier': getBrandIdentifier(userSession.clientId),
      'x-xsrf-token': getCookie('XSRF-TOKEN')
    },
  };
});

const getBrandIdentifier = (clientId: string | undefined): string | undefined => {
  switch (clientId) {
    case 'cpp':
      return 'cpp';
    default:
      return undefined;
  }
};

export const createApolloClient = (): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    link: ApolloLink.from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache({ addTypename: false }),
  });

  function getCookie(key:any) {
    const b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }
