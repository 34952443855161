import React, { FunctionComponent } from 'react';

import { AddressLookUp, AddressLookUpItemMaybeRecord } from '../address-look-up';
import { VerifiedAddressForm, VerifiedAddressFormModel } from '../verified-address-form';

interface VerfiedAddressProps {
  formValues: VerifiedAddressFormModel;
  lookUpPostCodeValue: string;
  showAddressLookUpList: boolean;
  onLookUpAddressSelected: (formValues: VerifiedAddressFormModel, record: AddressLookUpItemMaybeRecord) => void;
  onMyAddressIsNotListed: (formValues: VerifiedAddressFormModel) => void;
  onCloseForm: () => void;
  onCloseLookUp: () => void;
  onFormSubmitted: (formValues: VerifiedAddressFormModel) => void;
}

const getHouseValueForAddressLookUp = (formValues: VerifiedAddressFormModel): string => {
  const houseValue = formValues.houseName ? formValues.houseName : formValues.houseNumber;
  return houseValue as string;
};

const VerfiedAddress: FunctionComponent<VerfiedAddressProps> = ({
  formValues,
  lookUpPostCodeValue,
  onCloseForm,
  onCloseLookUp,
  onFormSubmitted,
  onLookUpAddressSelected,
  onMyAddressIsNotListed,
  showAddressLookUpList,
}): JSX.Element => {
  return (
    <>
      <div>
        <VerifiedAddressForm initialFormValues={formValues} onCloseForm={onCloseForm} onFormSubmit={onFormSubmitted} />
      </div>
      {showAddressLookUpList && (
        <div>
          <AddressLookUp
            initialFormValues={formValues}
            house={getHouseValueForAddressLookUp(formValues)}
            postCode={lookUpPostCodeValue}
            onAddressSelected={onLookUpAddressSelected}
            onCloseForm={onCloseLookUp}
            onMyAddressIsNotListed={onMyAddressIsNotListed}
          />
        </div>
      )}
    </>
  );
};

VerfiedAddress.displayName = 'verfied-address';

export { VerfiedAddress };
