import React from 'react';

import { ContainerStyled } from '@modules/core/container';
import { BrowserRouter as Router } from 'react-router-dom';

import { GlobalStyles } from '@assets/styles/global';

import { appTheme } from '@assets/styles/styled';
import { ScrollToTopRouter } from '@modules/core/scroll-to-top-router';
import { FooterWrapper } from '@modules/domain/footer-wrapper';
import { OnSuccessfullyGetUserSessionCallback, UserSessionState, useUserSession } from '@partnerships/common/modules/user-session';
import { ThemeProvider } from 'styled-components';
import { Header } from '../modules/domain/header/header';
import { AppRoutes } from './app-routes';

const onSuccessfullyGetUserSessionCallback: OnSuccessfullyGetUserSessionCallback = data => {
  if (data.userSession.state === UserSessionState.active) {
    window.location.href = '/landing';
  }
};

export const RouterWithStyles = (): JSX.Element => {
  const [userSessionResult] = useUserSession(onSuccessfullyGetUserSessionCallback);

  if (!userSessionResult) {
    return <></>;
  }

  const displayBackToAccounts = () => {
    return userSessionResult && userSessionResult.userSession.state !== UserSessionState.active;
  };

  const displayBackToMain = () => {
    return userSessionResult && userSessionResult.userSession.state === UserSessionState.active;
  };

  return (
    <ThemeProvider theme={appTheme}>
      <>
        <GlobalStyles />
        <Router>
          <ScrollToTopRouter>
            <Header displayBackToAccounts={displayBackToAccounts()} displayBackToMain={displayBackToMain()} />
            <ContainerStyled>
              <AppRoutes />
            </ContainerStyled>
            <FooterWrapper />
          </ScrollToTopRouter>
        </Router>
      </>
    </ThemeProvider>
  );
};
