import { rem, spacers } from '@assets/styles/settings';
import styled from '@assets/styles/styled';

interface StyledDefinitionList {
  mb?: boolean;
}

export const StyledDefinitionList = styled.dl<StyledDefinitionList>`
  ${props => props.mb && `margin-bottom: ${spacers[4]}`};

  ${({ theme }) =>
    theme.media(
      '20',
      `
      dt,
      dd {
        display: inline-block;
        vertical-align: top;
      }

      dt {
        min-width: 9rem;
        padding-right: 0.5rem;
      }

      dd {
        width: calc(100% - 9rem);
        min-width: 50%;
      }
  `,
    )}
`;
