import React, { FunctionComponent, ReactNode } from 'react';

import { StyledSegmentBlockColumn } from './segment-block.styled';

interface SegmentBlockColumnProps {
  children: ReactNode;
  width: string;
}

export const SegmentBlockColumn: FunctionComponent<SegmentBlockColumnProps> = ({ width, children }) => {
  return <StyledSegmentBlockColumn width={width}>{children} </StyledSegmentBlockColumn>;
};
