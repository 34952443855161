import styled from '@assets/styles/styled';
// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';

export enum flexDirection {
  COLUMN = 'column',
  ROW = 'row',
  ROW_REVERSE = 'row-reverse',
}

interface StyledSegmentBlockProps {
  withImage?: boolean;
  direction?: flexDirection;
  hasEditButton?: boolean;
}

export const StyledSegmentBlock = styled.div<StyledSegmentBlockProps>`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : flexDirection.ROW)};
  margin: 1.5rem 0;
  background: ${({ theme }) => theme.colors.experianLighterGrey};
  border: 1px solid ${({ theme }) => theme.colors.baseOutline};
  border-radius: 0.25rem;

  > * {
    padding: 1.125rem;
  }

  p {
    margin: 0;
  }

  ${({ hasEditButton, theme }) => {
    if (hasEditButton) {
      return `
        position: relative;
    
        > .edit-button {
          position: absolute;
          top: 1.25rem;
          right: 1.25rem;
          padding: 0;
        }
    
        ${theme.media(
          '15',
          `
            padding-right: 3.5rem;
          `,
        )}
      `;
    }
  }}
`;

interface StyledSegmentBlockColumn {
  width: string;
}

export const StyledSegmentBlockColumn = styled.div<StyledSegmentBlockColumn>`
  flex: ${({ width }) => width} auto;
`;

export const StyledSegmentBlockAside = styled.div`
  flex: 1 auto;
`;
export const StyledSegmentBlockImage = styled(Typography.Copy.Text)`
  display: flex;
  align-items: center;
  padding: 0.625rem;
  background: ${({ theme }) => theme.colors.baseWhite};
  border-radius: 0.25rem 0 0 0.25rem;

  img {
    min-width: 4.375rem;
  }
`;
