import { UserSessionStateModel } from './constants';

export interface UserSessionUpdate {
  state: UserSessionStateModel;
}

export interface UserSession {
  clientId: string;
  state: UserSessionStateModel;
  context: Context;
}

export interface UserSessionResult {
  userSession: UserSession;
}

export declare type OnSuccessfullyGetUserSessionCallback = (result: UserSessionResult) => void;

export interface LbgContext {
  offline: boolean;
  subProduct: string;
  returnToLBGURL: string;
  returnToVPDTabURL: string;
}

export type Context = LbgContext | undefined;
