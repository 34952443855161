import { RouteGuard } from 'react-route-guard';
import { UserSessionState } from '../../user-session';
import { UserSessionLocalStorageKey } from '../../user-session/constants';

export class SignUpOrReactivationOrAwaitingStateRouteGuard implements RouteGuard {
  static create = (): SignUpOrReactivationOrAwaitingStateRouteGuard => new SignUpOrReactivationOrAwaitingStateRouteGuard();

  private constructor() {}

  shouldRoute = (): boolean => {
    const sessionData = localStorage.getItem(UserSessionLocalStorageKey);

    if (!sessionData) {
      return false;
    }

    const userSession = JSON.parse(sessionData);

    return (
      userSession.state === UserSessionState.signUp ||
      userSession.state === UserSessionState.reactivation ||
      userSession.state === UserSessionState.awaitingOnboard
    );
  };
}
