import React, { FunctionComponent, ReactNode } from 'react';
import { EditButton } from '../edit-button';
import { flexDirection, StyledSegmentBlock } from './segment-block.styled';

interface SegmentBlockProps {
  children: ReactNode;
  withImage?: boolean;
  direction?: flexDirection;
  handleEditButtonClick?: () => void;
}

export const SegmentBlock: FunctionComponent<SegmentBlockProps> = ({
  children,
  withImage = false,
  direction,
  handleEditButtonClick,
  ...props
}) => {
  return (
    <StyledSegmentBlock withImage={withImage} direction={direction} hasEditButton={!!handleEditButtonClick} {...props}>
      {handleEditButtonClick && <EditButton handleClick={handleEditButtonClick} />}
      {children}
    </StyledSegmentBlock>
  );
};
