import React, { FunctionComponent } from 'react';

import { StyledDefinitionList } from './definition-list.styled';

interface DefinitionListProps {
  mb?: boolean;
}

export const DefinitionList: FunctionComponent<DefinitionListProps> = ({ mb, children }) => (
  <StyledDefinitionList mb={mb}>{children}</StyledDefinitionList>
);
