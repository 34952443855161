// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import moment from 'moment';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useApolloClient, useQuery } from 'react-apollo-hooks';
import { Redirect, RouteChildrenProps } from 'react-router';

import { ErrorRoutes, SignUpRoutes } from '@app/routes';
import { Loading } from '@modules/core/loading';
import { StyledSpacer } from '@modules/core/spacer';
import { Address, AddressContext } from '@modules/domain/address';
import { PageLayout } from '@modules/domain/page-layout';
import {
  ACCEPT_TERMS_AND_CONDITIONS,
  PERSONAL_DETAILS_QUERY,
  ReactivationContext,
  TermsAndConditions,
  UPDATE_USER,
  UserPersonalDetailsQueryResult,
} from '@modules/domain/sign-up';
import { WelcomeSection } from '@modules/domain/welcome-section';
import { getExistingUserSession } from '@partnerships/common/modules/user-session/user-session-utils';

export const WelcomeBackPage: FunctionComponent<RouteChildrenProps> = ({ history }): JSX.Element => {
  const { data: personalDetailsData, loading: personalDetailsLoading, error } = useQuery<UserPersonalDetailsQueryResult>(
    PERSONAL_DETAILS_QUERY,
  );
  const apolloClient = useApolloClient();
  const reactivationContext = useContext(ReactivationContext);
  const { addressState, dispatch } = useContext(AddressContext);
  const { personalDetails } = reactivationContext;

  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState<boolean>(false);
  const [errors, setHasErrors] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentAddressFromDateConfirmed, setCurrentAddressFromDateConfirmed] = useState<boolean>(true);
  const [hasSetPreviousAddresses, setHasSetPreviousAddresses] = useState<boolean>(false);

  const handleReactivateNowClick = async (): Promise<void> => {
    const { userSession } = await getExistingUserSession();

    try {
      setIsLoading(true);

      const updateUserMutationResult = await apolloClient.mutate({
        mutation: UPDATE_USER,
        variables: {
          update: {
            clientId: userSession.clientId,
            isOffline: false,
            title: personalDetails.title,
            middleName: personalDetails.middleName,
            mothersMaidenName: personalDetails.mothersMaidenName,
            dateOfBirthDay: personalDetails.dateOfBirthDay,
            dateOfBirthMonth: personalDetails.dateOfBirthMonth,
            dateOfBirthYear: personalDetails.dateOfBirthYear,
            emailAddress: personalDetails.emailAddress,
            contactNumber: personalDetails.contactNumber,
            currentAddressFromMonth: addressState.currentAddressMonthValue,
            currentAddressFromYear: addressState.currentAddressYearValue,
            previousAddresses: addressState.previousAddresses.map(pa => ({
              abroad: pa.abroad,
              addressType: pa.addressType,
              manual: pa.manual,
              isVerified: pa.isVerified,
              city: pa.city,
              country: pa.country,
              county: pa.county,
              district: pa.district,
              flat: pa.flat,
              houseNumber: pa.houseNumber,
              houseName: pa.houseName,
              postCode: pa.postCode,
              street: pa.street,
              fromMonth: pa.fromMonth,
              fromYear: pa.fromYear,
              toMonth: pa.toMonth,
              toYear: pa.toYear,
            })),
          },
        },
      });

      updateUserMutationResult.data ? onMutationSuccess() : onMutationFailure();

      const acceptTermsMutationResult = await apolloClient.mutate({
        mutation: ACCEPT_TERMS_AND_CONDITIONS,
        variables: { isOffline: false },
      });

      acceptTermsMutationResult.data ? onMutationSuccess() : onMutationFailure();
    } catch (error) {
      onMutationFailure();
    }
  };

  const onMutationSuccess = (): void => {
    setIsLoading(false);
    history.push(SignUpRoutes.Activation);
  };

  const onMutationFailure = (): void => {
    setIsLoading(false);
    setHasErrors(true);
  };

  if (isLoading || personalDetailsLoading) {
    return (
      <PageLayout title="Welcome to Identity Plus" metaTitle="Welcome to Identity Plus" sidebar={true}>
        <Loading />
        <StyledSpacer />
      </PageLayout>
    );
  }

  if (errors || error || (personalDetailsData && !personalDetailsData.hasOwnProperty('userDetails'))) {
    return <Redirect to={ErrorRoutes.Root} />;
  }

  if (personalDetailsData && personalDetailsData.userDetails) {
    reactivationContext.personalDetails.contactNumber = personalDetailsData.userDetails.contactNumber;
    reactivationContext.personalDetails.dateOfBirthDay = personalDetailsData.userDetails.dateOfBirthDay;
    reactivationContext.personalDetails.dateOfBirthMonth = personalDetailsData.userDetails.dateOfBirthMonth;
    reactivationContext.personalDetails.dateOfBirthYear = personalDetailsData.userDetails.dateOfBirthYear;
    reactivationContext.personalDetails.emailAddress = personalDetailsData.userDetails.emailAddress;
    reactivationContext.personalDetails.firstName = personalDetailsData.userDetails.firstName;
    reactivationContext.personalDetails.lastName = personalDetailsData.userDetails.lastName;
    reactivationContext.personalDetails.title = personalDetailsData.userDetails.title;
    reactivationContext.personalDetails.mothersMaidenName = personalDetailsData.userDetails.mothersMaidenName;

    if (personalDetailsData.userDetails.currentAddress && personalDetailsData.userDetails.currentAddress !== addressState.currentAddress) {
      dispatch({ type: 'addCurrentAddress', currentAddress: personalDetailsData.userDetails.currentAddress });
      if (personalDetailsData.userDetails.currentAddress.fromDate) {
        dispatch({
          type: 'currentAddressMonthChanged',
          newValue: moment(personalDetailsData.userDetails.currentAddress.fromDate).format('MMMM'),
        });
        dispatch({
          type: 'currentAddressYearChanged',
          newValue: moment(personalDetailsData.userDetails.currentAddress.fromDate).format('YYYY'),
        });
      } else {
        setHasErrors(true);
      }
    }

    if (personalDetailsData.userDetails.previousAddresses && !hasSetPreviousAddresses) {
      dispatch({
        type: 'setPreviousAddresses',
        addressHistoryItems: personalDetailsData.userDetails.previousAddresses,
        updatePending: false,
      });
      setHasSetPreviousAddresses(true);
    }
  }

  return (
    <PageLayout title="Welcome to Identity Plus" metaTitle="Welcome to Identity Plus" sidebar={true}>
      <WelcomeSection />

      {!personalDetailsLoading && personalDetailsData && (
        <>
          {addressState.currentAddress && (
            <Address
              currentAddress={addressState.currentAddress}
              currentAddressFromDateConfirmed={currentAddressFromDateConfirmed}
              showPreviousAddressUpdatedNotification={false}
            />
          )}
        </>
      )}

      <TermsAndConditions
        isChecked={acceptTermsAndConditions}
        onCheckedChanged={() => setAcceptTermsAndConditions(!acceptTermsAndConditions)}
        onReactivateNowClick={() => handleReactivateNowClick()}
      />
    </PageLayout>
  );
};
