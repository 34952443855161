import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface ScrollToTopLocationState {
  noScroll: boolean;
}

class ScrollToTopComponent extends Component<RouteComponentProps> {
  componentDidUpdate(prevProps: RouteComponentProps) {
    if (this.props.location.state && (this.props.location.state as ScrollToTopLocationState).noScroll) {
      return;
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export const ScrollToTopRouter = withRouter(ScrollToTopComponent);
