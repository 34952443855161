import { RouteGuard } from 'react-route-guard';

import { ActiveStateRouteGuard } from './active-state.guard';
import { ReActivationStateRouteGuard } from './reactivation-state.guard';
import { SignUpStateRouteGuard } from './sign-up-state.guard';

export class ValidSessionStateRouteGuard implements RouteGuard {
  static create = (): ValidSessionStateRouteGuard => new ValidSessionStateRouteGuard();

  private constructor() {}

  shouldRoute = (): boolean => {
    return (
      ActiveStateRouteGuard.create().shouldRoute() ||
      SignUpStateRouteGuard.create().shouldRoute() ||
      ReActivationStateRouteGuard.create().shouldRoute()
    );
  };
}
