export const getHouseValue = (flat = '', houseName = '', houseNumber = ''): string => {
  const addressTag = [flat, houseName, houseNumber].map((value, index) => {
    if (index === 2 && houseName && houseName.trim()) {
      return null;
    }
    return value ? value.trim() : null;
  });

  return addressTag.filter(value => value).join(' ');
};
