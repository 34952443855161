import styled from 'styled-components';

export const StyledStatementOfRightsPage = styled.div`
  ol {
    list-style: decimal;
    padding-left: 40px;
    font-size: 1rem;
  }

  dt {
    font-weight: 700;
  }

  dl {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  dd,
  dt {
    width: 49.15254%;
  }
`;

export const RightsImportant = styled.p`
  text-transform: uppercase;
`;

