import { spacers } from '@assets/styles/settings';
import styled from '@assets/styles/styled';

interface SpacerProps {
  space?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
}

export const StyledSpacer = styled.div<SpacerProps>`
  margin-bottom: ${({ space }) => spacers[space || 4]};
`;
