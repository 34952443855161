// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import React from 'react';

import { StyledListNumbered } from '@modules/core/list-numbered';
import { StyledListUnordered } from '@modules/core/list-unordered';
import { StyledSpacer } from '@modules/core/spacer';

export const FaqsContent = [
  {
    id: 'identity-theft',
    title: 'What is identity theft?',
    content: (
      <React.Fragment>
        <Typography.Copy.Text>
          Identity theft is when someone steals your personal information or possessions so they can use your identity. Identity fraud is
          when they use your identity for their own financial gain — usually at a great cost to you.
        </Typography.Copy.Text>

        <Typography.Copy.Text>
          You might not even realise that your information has been stolen until after the fraud has happened and only find out when a bill
          arrives for something you didn't buy, or when you have trouble taking out a credit card or a mobile phone contract.
        </Typography.Copy.Text>

        <Typography.Copy.Text>
          According to research from Experian's Victims of Fraud team, it takes an average of 292 days for people to discover their
          information has been used for fraudulent purposes.
        </Typography.Copy.Text>

        <Typography.Copy.Text>
          <strong>Five common ways fraudsters can steal your identity</strong>
        </Typography.Copy.Text>

        <Typography.Copy.Text as="div">
          <StyledListNumbered>
            <li>
              <strong>Common theft</strong>
              <br />
              You could be burgled and have your personal possessions taken, for example your purse containing your ID.
            </li>
            <li>
              <strong>Cold-calling</strong>
              <br />
              Fraudsters call you pretending to be a genuine business and mislead you into giving away personal and financial information.
            </li>
            <li>
              <strong>Hacking</strong>
              <br />
              Software is deployed to hack into your computer or information is taken from your smartphone.
            </li>
            <li>
              <strong>Phishing</strong>
              <br />
              Fraudsters send an email that appears to be from a trusted company to get you to click a link and enter your personal
              information, such as your banking details.
            </li>
            <li>
              <strong>Data breach</strong>
              <br />
              Customer information could be stolen from a service provider. There have been a number of high-profile data breaches in recent
              years.
            </li>
          </StyledListNumbered>
        </Typography.Copy.Text>

        <Typography.Copy.Text>
          <strong>How identity theft could affect you</strong>
          <br />
          Having your identity stolen and used fraudulently can hit your finances hard. Fraudsters could take money from your bank account
          or they could take out credit in your name. Their actions can hurt your credit score and affect your chances of getting credit in
          the future.
        </Typography.Copy.Text>
        <Typography.Copy.Text>
          Thankfully in most situations the effects of fraud can be reversed. But this process can take an emotional toll on you and the
          impact can go on for much longer than the actual fraud itself — research by Experian's Victims of Fraud team shows that it can
          take a staggering 300 hours to set the record straight.
        </Typography.Copy.Text>
        <Typography.Copy.Text>
          That's why it's important to act quickly. Your first step is to get in touch with your banks and lenders — they'll work with you
          to resolve the issues and if necessary will contact the police on your behalf. Contacting us is the next step as we'll help you
          clear your credit report.
        </Typography.Copy.Text>
        <Typography.Copy.Text as="div">
          <StyledListUnordered>
            <li>Never respond to unsolicited emails and phone calls.</li>
            <li>Use different passwords for different accounts — particularly for your email account and online banking.</li>
            <li>
              Use strong passwords made up of three random words — you can add in numbers and symbols, and use a combination of lowercase
              and uppercase letters if you want.
            </li>
          </StyledListUnordered>
        </Typography.Copy.Text>
      </React.Fragment>
    ),
  },
  {
    id: 'warning-signs',
    title: 'How to spot the warning signs of identity fraud',
    content: (
      <React.Fragment>
        <Typography.Copy.Text>
          Identity fraud can be damaging both financially and emotionally. Thankfully there are steps you can take to prepare and protect
          yourself and limit the chance of it happening.
        </Typography.Copy.Text>
        <Typography.Copy.Text>
          If you are at risk of identity fraud, there are likely to be warning signs. Knowing what these are can help you spot the fraud
          early, keeping the damage to a minimum.
        </Typography.Copy.Text>
        <Typography.Copy.Text>Actively look for signs of fraud</Typography.Copy.Text>
        <Typography.Copy.Text>
          <strong>Check for unusual transactions</strong>
          <br />
          Always check your credit card and bank statements when you receive them, and make a habit of checking them online on a regular
          basis. Look for purchases you didn't make and charges you don't recognise.
        </Typography.Copy.Text>
        <Typography.Copy.Text>
          <strong>Keep track of your mail</strong>
          <br />
          Not receiving regular bank or credit card statements could be a sign that they've been re-routed to a fraudster. The same goes for
          important personal documents you're expecting which fail to come through the post.
        </Typography.Copy.Text>
        <Typography.Copy.Text>
          <strong>Check your credit report</strong>
          <br />
          You should check your credit report regularly as many of your financial accounts are detailed in it. According to our research, it
          takes an average of 292 days for people to discover their information has been used for fraudulent purposes. Checking your credit
          report lets you spot fraud early. Look out for:
        </Typography.Copy.Text>

        <Typography.Copy.Text as="div">
          <StyledListUnordered>
            <li>Searches on your report made by lenders as a result of a credit application.</li>
            <li>Home addresses that aren't yours.</li>
            <li>Loans and accounts that you didn't apply for.</li>
            <li>You can now be alerted to certain signs of potential fraudulent activity with Experian's Identity Fraud Monitoring</li>
          </StyledListUnordered>

          <strong>Other warning signs of fraud</strong>
          <StyledListUnordered>
            <li>Email confirmations for purchases you didn't make or emails demanding payment for an account you didn't set up.</li>
            <li>Credit cards arriving in the post which you didn't apply for.</li>
            <li>Bills from companies that you've had no dealings with.</li>
            <li>Debt collection agencies contacting you about bills you don't recognise.</li>
            <li>Being told that you've been approved or denied credit for accounts that you know nothing about.</li>
            <li>Being refused when you apply for a loan or credit card even though you know you have a healthy credit score.</li>
          </StyledListUnordered>
        </Typography.Copy.Text>

        <Typography.Copy.Text>
          It can be easy to overlook emails in your already jam-packed inbox or to monitor everything coming through the post. But being
          vigilant and creating a monitoring routine can highlight suspicious activity and protect you from identity fraud.
        </Typography.Copy.Text>
      </React.Fragment>
    ),
  },
  {
    id: 'protect',
    title: 'How to protect yourself against identity fraud',
    content: (
      <React.Fragment>
        <Typography.Copy.Text>
          Protecting yourself from identity fraud starts with keeping your personal information safe with a few simple habits.
        </Typography.Copy.Text>
        <Typography.Copy.Text>
          There's a lot of advice out there, and that's because there are several ways criminals can steal and use your personal details to
          commit identity fraud. We've rounded up advice from the police, the government, ActionFraud, Take 5 and our own Experian fraud
          experts to bring you the dos and don'ts of protecting yourself against identity fraud. If there are just three changes you make to
          your everyday habits, we'd recommend:
        </Typography.Copy.Text>
        <Typography.Copy.Text>Don't respond to unsolicited emails and phone calls.</Typography.Copy.Text>
        <Typography.Copy.Text>
          Use different passwords for different accounts — particularly for your email account and online banking.
        </Typography.Copy.Text>
        <StyledSpacer space={3}>
          <Typography.Copy.Text>
            Use strong passwords made up of three random words — you can add in numbers and symbols, and use a combination of lowercase and
            uppercase letters if you want.
          </Typography.Copy.Text>
        </StyledSpacer>
        <Typography.Copy.Text as="div">
          <strong>Safe banking and shopping</strong>
          <StyledListUnordered>
            <li>Never disclose your personal security details such as your PIN or full password.</li>
            <li>Never let your credit or debit card out of your sight.</li>
            <li>When entering your PIN, always shield the keypad and make sure no one is watching.</li>
            <li>Don't keep a written record of your PINs anywhere.</li>
            <li>Take extra care with contactless payment cards to avoid unauthorised payments.</li>
            <li>Check your credit report for any signs of fraud.</li>
          </StyledListUnordered>
          <strong>Safe banking and shopping</strong>
          <StyledListUnordered>
            <li>
              Use strong passwords made up of three random words — you can add in numbers and symbols, and use a combination of lowercase
              and uppercase letters if you want.
            </li>
            <li>Use different passwords for different accounts - particularly for your email account and online banking.</li>
            <li>Keep the software on your computer and smartphone up to date.</li>
            <li>Check websites are secure by looking for the padlock symbol or 'https' at the start of the website address.</li>
            <li>
              Only enter your personal information or credit card details onto secure websites that belong to organisations you know and
              trust.
            </li>
            <li>
              If you're using public Wi-Fi, don't log in to any sites that need a password (eg your bank, social media or email) or enter
              personal information such as your card details.
            </li>
            <li>Look out for spelling and grammar mistakes. Genuine organisations won't send emails full of errors.</li>
            <li>Don't open attachments or click links on emails from unknown sources.</li>
            <li>
              Don't enter your personal details when asked to do so via an email. For example, your bank would never email you asking you to
              confirm your internet banking username and password.
            </li>
            <li>Mark suspicious emails as junk mail and delete straight away.</li>
            <li>Don't use illegal streaming and downloading sites, as these often host malicious software or phishing scams.</li>
          </StyledListUnordered>
          <strong>Protecting your devices</strong>
          <StyledListUnordered>
            <li>Secure your mobile phone or tablet with a screen lock. For added security, you can set it to lock automatically.</li>
            <li>Only download apps from reputable stores.</li>
            <li>Don't 'jailbreak' or 'root' your device as this makes it vulnerable to malicious software.</li>
          </StyledListUnordered>
          <strong>Handling calls from strangers</strong>
          <StyledListUnordered>
            <li>Be cautious of unexpected phone calls.</li>
            <li>
              Be sure you know who you are talking to — it's a good idea to hang up and call the organisation back on its official number.
            </li>
            <li>Don't give away personal information to someone who has cold-called you.</li>
            <li>Don't be rushed into making a decision or sharing information — a genuine organisation won't mind waiting.</li>
            <li>If something doesn't feel right, listen to your instincts.</li>
          </StyledListUnordered>
          <strong>Managing your social media profile</strong>
          <StyledListUnordered>
            <li>Don't share personal details such as your date of birth or home address.</li>
            <li>Think twice about using location features that automatically make your whereabouts known.</li>
            <li>Only accept friends who you actually know.</li>
            <li>Set your privacy settings so that only your friends see what you post.</li>
          </StyledListUnordered>
          <strong>Managing your mail</strong>
          <StyledListUnordered>
            <li>
              Always shred or destroy documents that contain personal information, like your name, address or financial details, before
              throwing them away.
            </li>
            <li>If you're expecting a bank or credit card statement that doesn't arrive, let your bank know.</li>
            <li>Contact Royal Mail if any of your mail goes missing.</li>
            <li>If you live in shared accommodation, get an individual and secure mailbox.</li>
          </StyledListUnordered>
          <strong>If you move house</strong>
          <StyledListUnordered>
            <li>Set up mail redirection with Royal Mail for at least a year.</li>
            <li>
              Check your credit report for any signs of fraud. The optimal time to do this is 2-3 months after the move, as any new accounts
              should be listed.
            </li>
          </StyledListUnordered>
        </Typography.Copy.Text>
      </React.Fragment>
    ),
  },
  {
    id: 'identity-theft-victim',
    title: `What to do if you're a victim of identity fraud`,
    content: (
      <React.Fragment>
        <Typography.Copy.Text>
          Becoming a victim of identity fraud can be emotionally upsetting. You are probably feeling very overwhelmed but it's important to
          take action quickly when you realise your identity has been stolen.
        </Typography.Copy.Text>
        <Typography.Copy.Text>
          <strong>Contact your bank</strong>
          <br />
          Immediately report any lost or stolen credit or debit cards to the organisations that issued them.
          <br />
          Contact the relevant bank, credit card provider or other lenders (as well as any others you have accounts with) to inform them
          that you've been the victim of identity fraud. They will investigate the issue and, in some instances, they will contact the
          police on your behalf. Be prepared to provide proof of your identity and statements showing your home address if requested.
        </Typography.Copy.Text>
        <Typography.Copy.Text>
          If a bank or lender contacts you about credit that you don't know anything about, tell them this right away.
        </Typography.Copy.Text>
        <StyledSpacer space={3}>
          <Typography.Copy.Text>
            <strong>Report it to Action Fraud</strong>
            <br />
            Report the fraud to Action Fraud — the UK's national fraud and internet crime reporting center — and they will advise on steps
            to take and further organisations to contact.
          </Typography.Copy.Text>
        </StyledSpacer>

        <Typography.Copy.Text as="div">
          <strong>Contact Experian</strong>
          <div>As part of our Identity Monitoring service:</div>
          <StyledListUnordered>
            <li>Get a copy of your Experian Credit Report and check it for fraudulent information.</li>
            <li>Our dedicated Victims of Fraud team will work with you to contact lenders and clean up your credit record.</li>
            <li>
              We can apply Cifas Protective Registration — this will alert most lenders to the fraud so they will take extra care when
              dealing with applications that are in your name.
            </li>
            <li>We'll also contact the other credit agencies for you.</li>
          </StyledListUnordered>

          <strong>Stay in control</strong>
          <StyledListUnordered>
            <li>Contact Royal Mail if you think your post has been stolen or if a mail redirection has been set up on your address.</li>
            <li>Keep a record of all your calls, letters and emails about the fraud.</li>
            <li>Report all lost or stolen documents such as passports or driving licences to the issuing organisations.</li>
            <li>
              You can contact the Mailing Preference Service to have your name removed from any mailing lists – this service is free and
              will help stop marketing materials going to your old addresses.
            </li>
          </StyledListUnordered>
        </Typography.Copy.Text>
      </React.Fragment>
    ),
  },
  {
    id: 'alerts-and-monitoring',
    title: `What are fraud alerts and web monitoring?`,
    content: (
      <React.Fragment>
        <Typography.Copy.Text>Web monitoring is a service included with your Experian Identity Plus product.</Typography.Copy.Text>
        <Typography.Copy.Text>
          Personal details hacked from insecure websites are often leaked on the internet or sold on 'dark web' sites that are hidden from
          search engines. Identity thieves can try to use this information to access websites you use or impersonate you when signing up for
          services.
        </Typography.Copy.Text>
        <Typography.Copy.Text>
          We scan these websites as well as things like social media and government registers for the personal information you provide to
          us, such as your email addresses and passport details. If we find any of this information we'll send you an alert by email or text
          message of what we've found, where and what you should do if anything looks wrong. The more information you give us to scan for,
          the more comprehensive our search will be and the better protected you'll be from fraud and identity theft.
        </Typography.Copy.Text>
        <Typography.Copy.Text>Fraud alerts are also included with your Experian Identity Plus product.</Typography.Copy.Text>
        <Typography.Copy.Text>
          If identity thieves get hold of your personal details they may try to impersonate you to apply for credit. If this happens and the
          company they apply to searches your Experian Credit Report, we'll send you an alert by email or text to let you know. We'll also
          send you an alert if any new accounts are added to your Experian Credit Report so you can confirm they're genuine and you're not a
          victim of identity theft.
        </Typography.Copy.Text>
      </React.Fragment>
    ),
  },
  {
    id: 'fraud-report-explained',
    title: 'The Experian Fraud Report explained',
    content: (
      <React.Fragment>
        <Typography.Copy.Text>
          <strong>What is a fraud report?</strong>
          <br />
          The fraud report is a focused report based on activity taking place within your Experian credit report. This would allow you to
          quickly see anything that may be potentially fraudulent.
          <br />
          <br />A credit report details your personal credit history for accounts you’ve had in the last six years, including mortgages,
          credit cards, overdrafts, loans, mobile phone contracts and even some utilities such as gas, electricity and water. If you’re over
          18 and have taken out credit before, a credit reference agency is likely to hold a credit report for you.
        </Typography.Copy.Text>

        <Typography.Copy.Text>
          <strong>How do lenders use your credit report?</strong>
          <br />
          A credit report gives insight into your credit accounts, repayment records and how well you’re coping with your finances.
          <br />
          <br />
          Lenders usually have to tell you before they look at information from your credit report. They use it, along with what you’ve
          provided on your application form and information they might already have (if you’re an existing customer), to help them decide
          whether or not to offer you credit – usually by calculating their own credit score for your application.
        </Typography.Copy.Text>

        <Typography.Copy.Text>
          <strong>What’s in your credit report?</strong>
          <br />
          Your credit report contains information that helps lenders confirm your identity and assess whether you’re a reliable borrower,
          such as:
        </Typography.Copy.Text>

        <Typography.Copy.Text>
          <strong>Account Information</strong>
          <br />A view of credit accounts you’ve had and whether you’ve made repayments on time and in full. Items such as missed or late
          payments stay on your credit report for at least six years, as do court judgments for non-payment of debts, bankruptcies and
          individual voluntary arrangements.
        </Typography.Copy.Text>

        <Typography.Copy.Text>
          <strong>Financial connections</strong>
          <br />A list of people with whom you have a financial connection, such as a joint mortgage or bank account – they are known as
          your financial associates. Their credit history doesn’t appear in your credit report. However, when you apply for credit, lenders
          are able to look at their credit history also, as their circumstances could affect your ability to repay what you owe.
        </Typography.Copy.Text>

        <Typography.Copy.Text>
          <strong>Address details</strong>
          <br />A view of electoral roll information for your current address and previous addresses you provide when you apply. It also
          contains details of any other addresses you’ve been linked to in the past, such as those you’ve given to lenders on application
          forms.
        </Typography.Copy.Text>

        <Typography.Copy.Text>
          <strong>Where does the information in your credit report come from?</strong>
          <br />
          The information in your credit report comes from two major sources:
        </Typography.Copy.Text>

        <Typography.Copy.Text>
          <strong>Public Information</strong>
          <br />
          This includes electoral roll information and court judgments.
        </Typography.Copy.Text>

        <StyledSpacer space={3}>
          <Typography.Copy.Text>
            <strong>Credit history information</strong>
            <br />
            Many lenders share information on what you owe, and whether you’ve paid on time. You agree to this as part of any application
            for credit. Some lenders only contribute information on accounts that have defaulted, but these days most share monthly updates
            on all customers payment and balance history.
          </Typography.Copy.Text>
        </StyledSpacer>

        <Typography.Copy.Text as="div">
          <strong>When should you check your credit report?</strong>
          <StyledListUnordered>
            <li>If you’re changing job or moving home</li>
            <li>If you’re applying for credit</li>
            <li>If you’re worried about ID fraud</li>
          </StyledListUnordered>
        </Typography.Copy.Text>
      </React.Fragment>
    ),
  },
];
