import { UserSessionState } from '../constants';
import { UserSessionResult } from '../user-session.query';

class UnknownUserSessionFactory {
  create = () => {
    return Promise.resolve<UserSessionResult>({
      userSession: {
        clientId: '',
        state: UserSessionState.genericError,
        context: undefined,
      },
    });
  };
}

export const unknownUserSessionFactory = new UnknownUserSessionFactory();
