// @ts-ignore
import { Accordion } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent } from 'react';

import { FaqsContentModel } from './faqs-content.model';

import { scrollToElement } from '@utils/scroll-to-elelement';
import { RouteComponentProps } from 'react-router-dom';

interface FaqsProps extends RouteComponentProps {
  faqs: FaqsContentModel[];
}

const FaqsComponent: FunctionComponent<FaqsProps> = ({ location, faqs }): JSX.Element => {
  if (location && location.hash) {
    scrollToElement(location.hash.slice(1), '', true);
  }

  return (
    <React.Fragment>
      {faqs.map(faq => (
        <Accordion key={faq.id} label={faq.title} content={faq.content} id={faq.id} />
      ))}
    </React.Fragment>
  );
};

FaqsComponent.displayName = 'Faqs';

export const Faqs = FaqsComponent;
