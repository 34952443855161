// @ts-ignore
import { Link, Typography } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent } from 'react';

import { SegmentBlock } from '@modules/core/segment-block/segment-block';
import { SegmentBlockColumn } from '@modules/core/segment-block/segment-block-column';
import { StyledLabel } from '@modules/core/select-multi/select-multi.styled';
import { StyledSpacer } from '@modules/core/spacer';
import { CurrentAddressPanel } from '@modules/domain/address/current-address';

interface AddressSummaryProps {
  house: string;
  street?: string;
  district?: string;
  county?: string;
  postCode: string;
  fromMonth: string;
  fromYear: string;
  toMonth?: string;
  toYear?: string;
  city?: string;
  onEditAddressDetails: () => void;
}

const AddressSummary: FunctionComponent<AddressSummaryProps> = ({
  house,
  street,
  district,
  city,
  county,
  postCode,
  fromMonth,
  fromYear,
  toMonth,
  toYear,
  onEditAddressDetails,
}): JSX.Element => (
  <SegmentBlock>
    <SegmentBlockColumn width="2">
      <CurrentAddressPanel house={house} street={street} city={city} district={district} county={county} postCode={postCode} />
      <StyledSpacer />

      <Typography.Copy.Text>
        {`You moved into this address in `}
        <StyledLabel>{`${fromMonth} ${fromYear}`}</StyledLabel>
      </Typography.Copy.Text>
      <Typography.Copy.Text>
        {`You moved out of this address in `}
        <StyledLabel>{`${toMonth} ${toYear}`}</StyledLabel>
      </Typography.Copy.Text>
    </SegmentBlockColumn>
    <SegmentBlockColumn width="1">
      <div style={{ textAlign: 'right' }}>
        <Link.Button data-testid="edit-address-link" onClick={() => onEditAddressDetails()}>
          Edit address
        </Link.Button>
      </div>
    </SegmentBlockColumn>
  </SegmentBlock>
);

AddressSummary.displayName = 'address-summary';

export { AddressSummary };
