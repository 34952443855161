import { ManualAddressFormModel } from '../manual-address-form';
import { VerifiedAddressFormModel } from '../verified-address-form';

import { AddressHistoryItem } from '../models';

export const mapAddressHistoryItemToManualAddressFormValues = (address: AddressHistoryItem): ManualAddressFormModel => ({
  flat: address.flat ? address.flat : '',
  houseName: address.houseName ? address.houseName : '',
  houseNumber: address.houseNumber ? address.houseNumber : '',
  street: address.street ? address.street : '',
  district: address.district ? address.district : '',
  county: address.county ? address.county : '',
  postTownOrCity: address.city ? address.city : '',
  postCode: address.postCode,
  fromMonth: address.fromMonth,
  fromYear: address.fromYear,
  toMonth: address.toMonth,
  toYear: address.toYear,
});

export const mapVerifiedFormValuesToManualFormValues = (formValues: VerifiedAddressFormModel): ManualAddressFormModel => ({
  fromMonth: formValues.fromMonth,
  fromYear: formValues.fromYear,
  toMonth: formValues.toMonth,
  toYear: formValues.toYear,
  houseName: formValues.houseName,
  houseNumber: formValues.houseNumber,
  postCode: formValues.postCode,
  flat: '',
  street: '',
  district: '',
  county: '',
  postTownOrCity: '',
});
