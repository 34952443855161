// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import React from 'react';

import { CookieRoutes, PdfRoutes, SignUpRoutes } from '@app/routes';
import { DocumentLink } from '@modules/core/document-link';
import { InfoBlock } from '@modules/core/info-block/info-block';
import { StyledCppLinks } from './cpp-information.styled';

export const CppInformation = () => (
  <aside>
    <StyledCppLinks data-testid="cpp-side-menu-links">
      <Typography.Headings.Secondary>Identity Plus</Typography.Headings.Secondary>
      <DocumentLink uri={PdfRoutes.TermsAndConditions} label="Terms &amp; Conditions" />
      <DocumentLink uri={PdfRoutes.PrivacyPolicy} label="Privacy Policy" />
      <DocumentLink uri={CookieRoutes.Index} label="Cookies Policy" />
    </StyledCppLinks>

    <InfoBlock withBorder={true} iconName={'info'} heading={'CPP contact information'} testId="cpp-contact-info">
      If you need to get in touch, please call:
      <br />
      0344 848 4277*
      <br />
      <br />
      *Calls cost no more than calls to geographical numbers (01 or 02) and will be included as part of inclusive minutes in your package.
      Customer support is available between the following hours;
      <br />
      <br />
      8am - 6pm (Mon-Fri)
    </InfoBlock>
  </aside>
);
