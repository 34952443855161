import { colors, fontSizes, rem, spacers } from '@assets/styles/settings';
import styled from '@assets/styles/styled';

interface StyledTableProps {
  format?: 'coloured' | 'vertical';
}

export const StyledTable = styled.table<StyledTableProps>`
  margin-bottom: ${spacers[2]};

  caption {
    font-weight: 500;
    font-size: ${fontSizes.l};
    text-align: left;
  }

  th {
    text-align: left;
  }

  td,
  th {
    padding-top: ${spacers[2]};
    padding-right: ${({ format }) => (format === 'coloured' ? `${spacers[2]}` : '0')};
    padding-bottom: ${spacers[2]};
    padding-left: ${({ format }) => (format === 'coloured' ? `${spacers[2]}` : '0')};
    ${({ format }) => format === 'vertical' && `border: ${rem(2)} solid ${colors.white}`};
    font-size: 1.125rem;
    line-height: 1.75rem;

    ${({ theme }) =>
      theme.media(
        '10',
        `
      font-size: 1.25rem;
      line-height: 1.875rem;
    `,
      )}
  }

  td {
    ${({ format }) => format === 'coloured' && `background-color: ${colors.bg}`};
    ${({ format }) => format === 'vertical' && `text-align: center`};
    ${({ format }) => format === 'vertical' && `background-color: ${colors.bg}`};
    vertical-align: top;
  }

  tr {
    border-bottom: 1px solid ${colors.table.border};
  }

  th,
  tfoot td {
    ${({ format, theme }) => format === 'coloured' && `background-color: ${theme.colors.baseBackgroundBlue}`};
    font-weight: 500;
  }

  td:first-child,
  th:first-child {
    ${({ format }) => format === 'coloured' && `border-left: 0`};
  }

  td:last-child,
  th:last-child {
    ${({ format }) => format === 'coloured' && `border-right: 0`};
  }

  tr:first-child td {
    ${({ format }) => format === 'coloured' && `border-top: 0`};
  }

  tfoot tr:last-child td {
    ${({ format }) => format === 'coloured' && `border-bottom: 0`};
  }
`;

interface StyledTableHeadingProps {
  width: string;
}

export const StyledTableHeading = styled.th<StyledTableHeadingProps>`
  width: ${({ width }) => width};
`;
