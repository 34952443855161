import 'core-js/es6/';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'es6-promise';
import 'url-search-params-polyfill';

import React from 'react';
import { render } from 'react-dom';

import { App } from '@app/app';

const appRoot = document.getElementById('root');

render(<App />, appRoot);
