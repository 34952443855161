// @ts-ignore
import { Button } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent } from 'react';

import { ExternalRoutes } from '@app/routes';
import { ButtonGroup } from '@modules/core/button-group';
import { terminateUserSession } from '@partnerships/common/modules/user-session/user-session-utils';
import { TermsSection } from '../../terms-section';

interface TermsAndConditionsProps {
  isChecked: boolean;
  onCheckedChanged: (value: boolean) => void;
  onReactivateNowClick: () => void;
}

export const TermsAndConditions: FunctionComponent<TermsAndConditionsProps> = ({
  isChecked,
  onCheckedChanged,
  onReactivateNowClick,
}): JSX.Element => {
  return (
    <>
      <TermsSection isChecked={isChecked} onCheckedChanged={onCheckedChanged} />
      <ButtonGroup>
        <Button
          type="secondary"
          href={ExternalRoutes.CppIdentity}
          label="No thanks, I'll wait"
          onClick={() => terminateUserSession()}
          isButton
        />
        <Button
          data-testid="activate-now"
          label="Activate now"
          isButton
          type="primary"
          disabled={!isChecked}
          onClick={() => {
            if (!isChecked) {
              return;
            }
            onReactivateNowClick();
          }}
        />
      </ButtonGroup>
    </>
  );
};
