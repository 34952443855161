import { AddressContext } from '@modules/domain/address/address.context';
import { CurrentAddressNoControl } from '@modules/domain/address/current-address';
import { CurrentAddressModel } from '@modules/domain/address/models';
import { PreviousAddresses } from '@modules/domain/address/previous-address';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

interface AddressProps {
  currentAddress: CurrentAddressModel;
  currentAddressFromDateConfirmed: boolean;
  showPreviousAddressUpdatedNotification?: boolean;
  handleCurrentAddressEditClick?: () => void;
}

const Address: FunctionComponent<AddressProps> = ({
  currentAddressFromDateConfirmed,
  currentAddress,
  showPreviousAddressUpdatedNotification = true,
  handleCurrentAddressEditClick,
}): JSX.Element => {
  const { addressState, dispatch } = useContext(AddressContext);

  const [addLaterSelected, setAddLaterSelected] = useState<boolean>(false);
  const [showAddPreviousAddressForm, setShowAddPreviousAddressForm] = useState<boolean>(true);
  const [visibleAddressFormId, setVisibleAddressFormId] = useState<number>(0);
  const [updatedAddressId, setUpdatedAddressId] = useState<number>(0);
  const [showUpdatedPreviousAddress, setShowUpdatedPreviousAddress] = useState<boolean>(false);

  useEffect(() => {
    if (!addLaterSelected) {
      setShowAddPreviousAddressForm(true);
    }
  });

  const confirmRemovePreviousAddys = () => {
    dispatch({ type: 'overwritePreviousAddressesConfirmed' });
  };

  const onPreviousAddressAdded = (addressHistoryItem: any) => {
    setShowAddPreviousAddressForm(false);
    dispatch({ type: 'addPreviousAddress', addressHistoryItem });
  };

  const onPreviousAddressCancelled = () => {
    setShowAddPreviousAddressForm(!showAddPreviousAddressForm);
    setAddLaterSelected(true);
  };

  const handleEditExistingAddyUpdate = (addressId: number, formId: number, values: any): void => {
    setUpdatedAddressId(addressId);
    setVisibleAddressFormId(formId);
    dispatch({
      type: 'editPreviousAddress',
      previousAddress: {
        id: addressId,
        city: values.postTownOrCity,
        fromMonth: values.fromMonth,
        fromYear: values.fromYear,
        toMonth: values.toMonth,
        toYear: values.toYear,
        ...values,
      },
    });

    setShowUpdatedPreviousAddress(true);
  };

  const handleRemovePreviousAddresses = () => {
    dispatch({ type: 'setPreviousAddresses', addressHistoryItems: [], updatePending: true });
  };

  return (
    <>
      <CurrentAddressNoControl
        currentAddress={currentAddress}
        fromMonth={addressState.currentAddressMonthValue}
        fromYear={addressState.currentAddressYearValue}
        handleEditButtonClick={handleCurrentAddressEditClick}
      />

      <PreviousAddresses
        previousAddresses={addressState.previousAddresses}
        handleResetPreviousAddresses={handleRemovePreviousAddresses}
        handleRemovePreviousAddresses={confirmRemovePreviousAddys}
        handleRemovedPreviousAddressesCancel={() => dispatch({ type: 'cancelOverwritePreviousAddress' })}
        showUpdatedPreviousAddress={showUpdatedPreviousAddress}
        updatedAddressId={updatedAddressId}
        visibleAddressFormId={visibleAddressFormId}
        setVisibleAddressFormId={setVisibleAddressFormId}
        handleEditExistingAddyUpdate={handleEditExistingAddyUpdate}
        currentAddressFromDateConfirmed={currentAddressFromDateConfirmed}
        hasRecommendedAmountOfAddressHistory={addressState.hasRecommendedAmountOfAddressHistory}
        hasGapsInAddressHistory={addressState.hasGapsInAddressHistory}
        setAddLaterSelected={setAddLaterSelected}
        showAddPreviousAddressForm={showAddPreviousAddressForm}
        showRemovePreviousAddressesWarning={addressState.showRemovePreviousAddressesWarning}
        onPreviousAddressAdded={onPreviousAddressAdded}
        onPreviousAddressCancelled={onPreviousAddressCancelled}
        showPreviousAddressUpdatedNotification={showPreviousAddressUpdatedNotification}
      />
    </>
  );
};

Address.displayName = 'address';

export { Address };
