import React, { FunctionComponent } from 'react';
import { StyledDocumentLink } from './';

interface DocumentLinkProps {
  inline?: boolean;
  label: string;
  uri: string;
}

export const DocumentLink: FunctionComponent<DocumentLinkProps> = ({ inline = false, label, uri }): JSX.Element => (
  <StyledDocumentLink href={uri} target="_blank" inline blockItem={!!inline}>
    {label}
  </StyledDocumentLink>
);
