import React, { FunctionComponent } from 'react';

import { StyledNotePanel } from './note-panel.styled';

export interface NotePanelProps {
  testId?: string;
}

export const NotePanel: FunctionComponent<NotePanelProps> = ({ children, testId }) => (
  <StyledNotePanel data-testid={testId}> {children} </StyledNotePanel>
);
