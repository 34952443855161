// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';

import React, { FunctionComponent } from 'react';

import { TwoColumnContainer } from '@modules/core/two-column-container';
import { ShadowBox, StyledContactNumber, StyledOpeningTimes } from '.';

export const CallCharges: FunctionComponent = (): JSX.Element => (
  <Typography.Copy.Text>
    034 calls cost no more than calls to geographical numbers (01 or 02) and will be included as part of inclusive minutes in your package.
  </Typography.Copy.Text>
);

export enum OpeningDays {
  WeekDay = 'Monday - Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum OpeningTimes {
  FullDay = '8am - 6pm',
  HalfDay = '9am - 1pm',
  Closed = 'Closed',
}

export const PrimaryContactNumber: FunctionComponent = (): JSX.Element => (
  <Typography.Headings.Secondary as="span">0344 848 4277</Typography.Headings.Secondary>
);

export const CallCard: FunctionComponent = (): JSX.Element => {
  return (
    <ShadowBox>
      <div>
        <TwoColumnContainer justifyContent="left">
          <StyledContactNumber>
            <img src="/cpp-assets/images/icon_contact.svg" alt="Contact icon" width="54" height="54" />
            <Typography.Copy.Text>
              <strong>Call CPP</strong>
              <PrimaryContactNumber />
            </Typography.Copy.Text>
          </StyledContactNumber>
          <StyledOpeningTimes>
            <Typography.Copy.Text>
              <strong>{OpeningDays.WeekDay}</strong>
              {OpeningTimes.FullDay}
            </Typography.Copy.Text>
          </StyledOpeningTimes>
        </TwoColumnContainer>
        <CallCharges />
      </div>
    </ShadowBox>
  );
};
