import { hiddenVisually } from '@assets/styles/mixins';
import { rem } from '@assets/styles/settings';
import styled from '@assets/styles/styled';

export const StyledLabel = styled.label`
  margin-top: 0.75rem;
  margin-bottom: 0;
  padding-bottom: ${rem(8)};
  font-weight: 500;
`;

export const StyledInputs = styled.div`
  display: flex;
  width: calc(100% - 30px);

  div {
    flex: 1;
    margin-right: ${rem(10)};

    &:last-child {
      margin: 0;
    }
  }

  label {
    span {
      ${hiddenVisually};
    }
  }
`;
