import { rem } from '@assets/styles/settings';
import styled from '@assets/styles/styled';

export const StyledListNumbered = styled.ol`
  margin-bottom: ${rem(24)};
  padding-left: ${rem(18)};
  list-style-type: decimal;

  li {
    margin-left: ${rem(18)};
  }
`;
