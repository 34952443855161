// @ts-ignore
import { Link, Typography } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useQuery } from 'react-apollo-hooks';

import { SupportRoutes } from '@app/routes';
import { StyledDivider } from '@modules/core/divider';
import { Error } from '@modules/core/error';
import { fixedWidth, FlexContainer, flexDirection } from '@modules/core/flex-container';
import { Loading } from '@modules/core/loading';
import { StyledSpacer } from '@modules/core/spacer';
import { AddressStateProvider } from '@modules/domain/address';
import { PageLayout } from '@modules/domain/page-layout';
import { MY_PROFILE_QUERY, MyProfileQueryResult } from '@modules/domain/profile';
import { AddressSection } from '@modules/domain/profile/address-section/address-section';
import { EditProfileModal } from '@modules/domain/profile/edit-modal';
import { ProfileIntroSection } from '@modules/domain/profile/intro-section';
import { PersonalDetails } from '@modules/domain/profile/personal-details/personal-details';
import { PolicyNumberContext, SectionContactDetails, SectionPolicyNumber } from '@modules/domain/sign-up/personal-details/sections';

export const ProfilePage: FunctionComponent = (): JSX.Element => {
  const { data: customerDetailsData, loading: customerDetailsLoading } = useQuery<MyProfileQueryResult>(MY_PROFILE_QUERY);
  const [hidden, setVisibility] = useState<boolean>(false);
  const policyNumber = useContext(PolicyNumberContext).policyNumber;
  const metaTitle = 'My CPP ID';

  if (customerDetailsLoading) {
    return (
      <PageLayout title={metaTitle} metaTitle={metaTitle} sidebar={false}>
        <Loading />
      </PageLayout>
    );
  }

  if (customerDetailsData && customerDetailsData.userContactDetails) {
    const contactDetails = {
      emailAddress: customerDetailsData.userContactDetails.email,
      contactNumber: customerDetailsData.userContactDetails.contactPhoneNumber,
      secondaryPhoneNumber: customerDetailsData.userContactDetails.secondaryPhoneNumber,
    };

    const customerDetails = {
      titledFullName: customerDetailsData.userContactDetails.titledFullName,
      dob: customerDetailsData.userContactDetails.dob,
    };

    const { currentAddress, previousAddresses } = customerDetailsData.userContactDetails;

    return (
      <PageLayout title={metaTitle} metaTitle={metaTitle} sidebar={false}>
        <ProfileIntroSection />

        <FlexContainer columns={1} direction={flexDirection.COLUMN} maxWidth={fixedWidth.INNERCONTENT}>
          <SectionPolicyNumber />

          <StyledSpacer space={5}>
            <Typography.Copy.Text>This policy number helps us identify you quickly when you call.</Typography.Copy.Text>
          </StyledSpacer>

          <StyledSpacer space={5}>
            {customerDetails && (
              <PersonalDetails
                customerDetails={customerDetails}
                handleEditButtonClick={() => {
                  setVisibility(!hidden);
                }}
              />
            )}
          </StyledSpacer>

          <StyledSpacer space={2}>
            <section>
              <Typography.Headings.Secondary>Contact details</Typography.Headings.Secondary>
              <SectionContactDetails
                contactDetails={contactDetails}
                handleEditButtonClick={() => {
                  setVisibility(!hidden);
                }}
              />
            </section>
          </StyledSpacer>
        </FlexContainer>

        <AddressStateProvider>
          <AddressSection
            currentAddress={currentAddress}
            previousAddresses={previousAddresses}
            handleCurrentAddressEditClick={() => {
              setVisibility(!hidden);
            }}
          />
        </AddressStateProvider>

        <StyledSpacer space={8}>
          <StyledDivider />
        </StyledSpacer>

        <section>
          <Typography.Headings.Primary as="h2">Worried you may have been a fraud victim?</Typography.Headings.Primary>
          <Typography.Copy.Text>
            We want you to get the most out of your Experian Identity Plus service. Please use our{' '}
            <Link.Anchor inline href={SupportRoutes.Index}>
              contact page
            </Link.Anchor>{' '}
            if you have any questions
          </Typography.Copy.Text>
        </section>

        <EditProfileModal isVisible={hidden} policyNumber={policyNumber} />
      </PageLayout>
    );
  } else {
    return (
      <PageLayout title={metaTitle} metaTitle={metaTitle} sidebar={false}>
        <ProfileIntroSection />
        <Error error="There was an error retrieving your data" />
      </PageLayout>
    );
  }
};
