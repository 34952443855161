import { UserSessionLocalStorageKey } from '@partnerships/common/modules/user-session/constants';
import { RouteGuard } from 'react-route-guard';
import { UserSessionState } from '../../user-session';

export class SignUpStateRouteGuard implements RouteGuard {
  static create = (): SignUpStateRouteGuard => new SignUpStateRouteGuard();

  private constructor() {}

  shouldRoute = (): boolean => {
    const sessionData = localStorage.getItem(UserSessionLocalStorageKey);

    if (!sessionData) {
      return false;
    }

    const userSession = JSON.parse(sessionData);

    return userSession.state === UserSessionState.signUp;
  };
}
