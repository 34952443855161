import { UserSessionLocalStorageKey } from '../constants';
import { UserSession, UserSessionResult, UserSessionUpdate } from '../user-session.query';

class ExistingUserSessionFactory {
  create = (): Promise<UserSessionResult> => {
    return Promise.resolve<UserSessionResult>({
      userSession: this.getExistingUserSession(),
    });
  };

  merge = async (update: UserSessionUpdate): Promise<UserSessionResult> => {
    const oldSession = await this.getExistingUserSession();
    oldSession.state = update.state;
    await this.storeUserSession(oldSession);
    return this.create();
  };

  private getExistingUserSession = (): UserSession => {
    return JSON.parse(localStorage.getItem(UserSessionLocalStorageKey) as string);
  };

  private storeUserSession = (userSession: UserSession): void => {
    localStorage.removeItem(UserSessionLocalStorageKey);
    localStorage.setItem(UserSessionLocalStorageKey, JSON.stringify(userSession));
  };
}

export const existingUserSessionFactory = new ExistingUserSessionFactory();
