// ========================================================================
// SETTINGS/COLOURS
// ========================================================================

// PAGE
const colText = '#333';
const colBorder = '#bfbfbf';
const colBorderDark = '#8e8e8e';
const colError = '#dc2a4d';
const colErrorLight = '#ffcfca';
const colErrorDark = '#bb2847';
const colValid = '#649c00';
const colValidLight = '#eef6df';
const colWarning = '#eeb320';
const colLight = colBorder;
const colReadonly = '#dedede';
const colSuccessBg = '#eef6df';
const colSuccessBorder = '#8ab535';
const colErrorBg = '#fae2e7';
const colErrorBorder = colError;
const colWarningBg = '#fdf7e7';
const colWarningBorder = colWarning;
const colLightBorder = colLight;
const colTableBorder = '#efefef';
const colBubbleBorder = '#d5e1eb';
const tableEmptyCell = '#cccccc';
const white = '#ffffff';
const colLightBg = white;
const outline = '#4d90fe';
const disabled = '#e4e4e4';
const disabledText = '#cecece';

const colScoreLow = '#fff200';
const colScoreLowMedium = '#ffc500';
const colScoreMedium = '#ff9900';
const colScoreMediumHigh = '#de5e13';
const colScoreHigh = '#bd2327';

export const colors = {
  creditScore: {
    default: white,
    low: colScoreLow,
    lowMedium: colScoreLowMedium,
    medium: colScoreMedium,
    mediumHigh: colScoreMediumHigh,
    high: colScoreHigh,
  },
  bg: white,
  text: colText,
  border: colBorder,
  borderDark: colBorderDark,
  error: colError,
  errorLight: colErrorLight,
  errorDark: colErrorDark,
  valid: colValid,
  warning: colWarning,
  readonly: colReadonly,
  hr: colBorder,
  white,
  alerts: {
    success: {
      bg: colSuccessBg,
      border: colSuccessBorder,
    },
    error: {
      bg: colErrorBg,
      border: colErrorBorder,
    },
    warning: {
      bg: colWarningBg,
      border: colWarningBorder,
    },
    light: {
      bg: colLightBg,
      border: colLightBorder,
    },
  },
  table: {
    border: colTableBorder,
    emptyCell: tableEmptyCell,
  },
  outline,
  disabled,
  disabledText,
  validLight: colValidLight,
  bubbleBorder: colBubbleBorder,
} as any;
