import React, { FunctionComponent, useEffect, useState } from 'react';
import { useApolloClient } from 'react-apollo-hooks';
import { RouteChildrenProps } from 'react-router';

import { OnboardingLoader } from './onboarding-loader';
import { checkAuthState, onboard } from './onboarding.service';

interface OnboardingProps {
  authSuccessMessage: string;
  homeUrl: string;
  inProgressMessage: string;
}

export const Onboarding: FunctionComponent<OnboardingProps & RouteChildrenProps> = ({
  authSuccessMessage,
  history,
  homeUrl,
  inProgressMessage,
}): JSX.Element => {
  const [message, setMessage] = useState<string>('');
  const client = useApolloClient();

  const redirectToHome = (): void => {
    history.push(homeUrl);
  };

  const redirectToCompletion = (): void => {
    window.location.href = '/landing';
  };

  useEffect(() => {
    let inProgress = true;

    setMessage(inProgressMessage);

    checkAuthState(client).then(
      authSuccess => {
        if (inProgress) {
          if (authSuccess) {
            setMessage(authSuccessMessage);
            onboard(client).then(
              onboardSuccess => {
                if (inProgress) {
                  if (onboardSuccess) {
                    redirectToCompletion();
                  } else {
                    redirectToHome();
                  }
                }
              },
              () => {
                if (inProgress) {
                  redirectToHome();
                }
              },
            );
          } else {
            redirectToHome();
          }
        }
      },
      () => {
        redirectToHome();
      },
    );

    return () => {
      inProgress = false;
    };
  }, []);

  return <OnboardingLoader message={message} />;
};
