// @ts-ignore
import { Link, Notification, notificationVariant, Typography } from '@experian-uk/corvetteuk-common-ui';
import { Fade } from '@modules/core/animated';
import { SegmentBlock } from '@modules/core/segment-block/segment-block';
import { flexDirection } from '@modules/core/segment-block/segment-block.styled';
import { StyledSpacer } from '@modules/core/spacer';
import { AddPreviousAddressForm } from '@modules/domain/address/add-previous-address';
import { AddressHistoryListItem } from '@modules/domain/address/address-history-list';
import { PreviousAddress } from '@modules/domain/address/models';
import { RemovePreviousAddressesWarning } from '@modules/domain/address/remove-previous-addresses-warning';
import { mapAddressHistoryItemToManualAddressFormValues } from '@modules/domain/address/utils';
import React, { Fragment, FunctionComponent } from 'react';

interface PreviousAddressesProps {
  previousAddresses: PreviousAddress[];
  handleRemovePreviousAddresses: () => void;
  handleRemovedPreviousAddressesCancel: () => void;
  handleResetPreviousAddresses: () => void;
  showUpdatedPreviousAddress: boolean;
  updatedAddressId: number;
  visibleAddressFormId: number;
  setVisibleAddressFormId: (id: number) => void;
  handleEditExistingAddyUpdate: (addressId: number, formId: number, values: any) => void;
  currentAddressFromDateConfirmed: boolean;
  hasRecommendedAmountOfAddressHistory: boolean;
  hasGapsInAddressHistory: boolean;
  setAddLaterSelected: (value: boolean) => void;
  showAddPreviousAddressForm: boolean;
  onPreviousAddressAdded: (addressHistoryItem: any) => void;
  onPreviousAddressCancelled: () => void;
  showRemovePreviousAddressesWarning: boolean;
  showPreviousAddressUpdatedNotification: boolean;
}

const PreviousAddresses: FunctionComponent<PreviousAddressesProps> = ({
  previousAddresses,
  handleResetPreviousAddresses,
  handleRemovePreviousAddresses,
  handleRemovedPreviousAddressesCancel,
  showUpdatedPreviousAddress,
  updatedAddressId,
  setVisibleAddressFormId,
  visibleAddressFormId,
  handleEditExistingAddyUpdate,
  currentAddressFromDateConfirmed,
  hasRecommendedAmountOfAddressHistory,
  hasGapsInAddressHistory,
  setAddLaterSelected,
  showAddPreviousAddressForm,
  onPreviousAddressAdded,
  onPreviousAddressCancelled,
  showRemovePreviousAddressesWarning,
  showPreviousAddressUpdatedNotification,
}): JSX.Element => {
  const previousAddressBanner = (
    <React.Fragment>
      {!hasRecommendedAmountOfAddressHistory && (
        <Notification notificationTitle="Please note" variant={notificationVariant.warning}>
          We see you haven't been at your current address for more than 6 years; we recommend you provide more information to ensure the
          accuracy of your Experian profile.
        </Notification>
      )}
      {hasGapsInAddressHistory && (
        <Notification notificationTitle="Please note" variant={notificationVariant.warning}>
          There are gaps in your address history.
        </Notification>
      )}
    </React.Fragment>
  );

  return (
    <>
      {showRemovePreviousAddressesWarning && (
        <StyledSpacer>
          <RemovePreviousAddressesWarning onCancel={handleRemovedPreviousAddressesCancel} onConfirm={handleRemovePreviousAddresses} />
        </StyledSpacer>
      )}

      <StyledSpacer>
        {previousAddresses.length > 0 && (
          <React.Fragment>
            <Typography.Headings.Secondary>Previous address{previousAddresses.length >= 2 ? 'es' : ''}</Typography.Headings.Secondary>
            {previousAddressBanner}
            <Link.Button data-testid="remove-previous-addresses" onClick={handleResetPreviousAddresses}>
              Remove All Addresses
            </Link.Button>
          </React.Fragment>
        )}
        {previousAddresses.map((address: PreviousAddress) => (
          <Fragment key={`AddressHistoryListItem${address.id}`}>
            {address.id > 0 && <StyledSpacer space={6} />}

            <StyledSpacer />

            <AddressHistoryListItem
              key={address.id}
              id={address.id}
              address={address}
              formValues={mapAddressHistoryItemToManualAddressFormValues(address)}
              showEditForm={address.id === visibleAddressFormId}
              onCloseForm={() => setVisibleAddressFormId(0)}
              onEditAddressDetails={() => setVisibleAddressFormId(address.id)}
              onSubmitForm={values => handleEditExistingAddyUpdate(address.id, 0, values)}
            />

            {showPreviousAddressUpdatedNotification && showUpdatedPreviousAddress && updatedAddressId === address.id && (
              <>
                <StyledSpacer space={4} />
                <Fade fadeDirection="in">
                  <Notification notificationTitle="Previous address successfully updated" variant={notificationVariant.success}>
                    Your previous address has been successfully updated.
                  </Notification>
                </Fade>
              </>
            )}
          </Fragment>
        ))}
      </StyledSpacer>

      {currentAddressFromDateConfirmed && (!hasRecommendedAmountOfAddressHistory || hasGapsInAddressHistory) && (
        <>
          {previousAddresses.length === 0 ? (
            <>
              <Typography.Headings.Secondary>Please add a previous address</Typography.Headings.Secondary>
              {previousAddressBanner}
            </>
          ) : (
            <Typography.Headings.Secondary>Add another previous address</Typography.Headings.Secondary>
          )}
          {showAddPreviousAddressForm ? (
            <AddPreviousAddressForm
              onPreviousAddressAdded={addressHistoryItem => onPreviousAddressAdded(addressHistoryItem)}
              onCancel={onPreviousAddressCancelled}
            />
          ) : (
            <SegmentBlock direction={flexDirection.ROW}>
              <Link.Button onClick={() => setAddLaterSelected(false)}> Add address</Link.Button>
            </SegmentBlock>
          )}
        </>
      )}
    </>
  );
};

PreviousAddresses.displayName = 'previous-addresses';

export { PreviousAddresses };
