// @ts-ignore
import { Link, Typography } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent, useContext } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { RouteChildrenProps } from 'react-router';

import { CookieRoutes, SignUpRoutes } from '@app/routes';
import { Error } from '@modules/core/error';
import { Loading } from '@modules/core/loading';
import { StyledSpacer } from '@modules/core/spacer';
import { AddressContext } from '@modules/domain/address/address.context';
import { PageLayout } from '@modules/domain/page-layout';
import { PersonalDetailsFormModel, SignUpContext } from '@modules/domain/sign-up';
import { PERSONAL_DETAILS_QUERY, UserPersonalDetailsQueryResult } from '@modules/domain/sign-up';
import { PersonalDetailsForm } from '@modules/domain/sign-up/personal-details/form';
import { useUserSession } from '@partnerships/common/modules/user-session';
import moment from 'moment';

export const PersonalDetailsPage: FunctionComponent<RouteChildrenProps> = ({ history }): JSX.Element => {
  const { data: personalDetailsData, loading, error } = useQuery<UserPersonalDetailsQueryResult>(PERSONAL_DETAILS_QUERY);
  const signUpContext = useContext(SignUpContext);
  const addressContext = useContext(AddressContext);
  const { addressState, dispatch } = addressContext;
  const [session] = useUserSession();
  const pageTitle = 'Register for Identity Plus provided by Experian';
  const pageMetaTitle = 'Register';

  if (loading || !personalDetailsData) {
    return (
      <PageLayout title={pageTitle} metaTitle={pageMetaTitle} sidebar={true}>
        <Loading />
      </PageLayout>
    );
  }

  if (error || !personalDetailsData || !personalDetailsData.hasOwnProperty('userDetails')) {
    return (
      <PageLayout title={pageTitle} metaTitle={pageMetaTitle} sidebar={true}>
        <Error error="Could not load your data. Please try again." />
      </PageLayout>
    );
  }

  if (!session) {
    return <React.Fragment />;
  }

  if (personalDetailsData.userDetails) {
    signUpContext.personalDetails.contactNumber = personalDetailsData.userDetails.contactNumber;
    signUpContext.personalDetails.dateOfBirthDay = personalDetailsData.userDetails.dateOfBirthDay;
    signUpContext.personalDetails.dateOfBirthMonth = personalDetailsData.userDetails.dateOfBirthMonth;
    signUpContext.personalDetails.dateOfBirthYear = personalDetailsData.userDetails.dateOfBirthYear;
    signUpContext.personalDetails.emailAddress = personalDetailsData.userDetails.emailAddress;
    signUpContext.personalDetails.firstName = personalDetailsData.userDetails.firstName;
    signUpContext.personalDetails.lastName = personalDetailsData.userDetails.lastName;
    signUpContext.personalDetails.middleName = personalDetailsData.userDetails.middleName;
    signUpContext.personalDetails.title = personalDetailsData.userDetails.title;
    signUpContext.personalDetails.mothersMaidenName = personalDetailsData.userDetails.mothersMaidenName;

    if (personalDetailsData.userDetails.currentAddress && personalDetailsData.userDetails.currentAddress !== addressState.currentAddress) {
      dispatch({ type: 'addCurrentAddress', currentAddress: personalDetailsData.userDetails.currentAddress });
      if (personalDetailsData.userDetails.currentAddress.fromDate) {
        dispatch({
          type: 'currentAddressMonthChanged',
          newValue: moment(personalDetailsData.userDetails.currentAddress.fromDate).format('MMMM'),
        });
        dispatch({
          type: 'currentAddressYearChanged',
          newValue: moment(personalDetailsData.userDetails.currentAddress.fromDate).format('YYYY'),
        });
        if (personalDetailsData.userDetails.previousAddresses) {
          dispatch({
            type: 'setPreviousAddresses',
            addressHistoryItems: personalDetailsData.userDetails.previousAddresses,
            updatePending: false,
          });
        }
      } else {
        return (
          <PageLayout title={pageTitle} metaTitle={pageMetaTitle} sidebar={true}>
            <Error error="Could not load your data. Please try again." />
          </PageLayout>
        );
      }
    }
  }

  const handlePersonalDetailsFormSubmit = (): void => {
    history.push(SignUpRoutes.AddressDetails);
  };

  const getInitialFormValues = (): PersonalDetailsFormModel => {
    return {
      ...personalDetailsData.userDetails,
    };
  };

  return (
    <PageLayout title={pageTitle} metaTitle={pageMetaTitle} sidebar={true}>
      <div>
        <Typography.Copy.Text>
          Any details provided in this section are processed by Experian to provide you with the Identity Plus service.
        </Typography.Copy.Text>
        <Typography.Copy.Text>
          For your convenience we have already added some of the details for you, these details have been provided to us by CPP. If any of
          these details are incorrect please contact CPP directly so they can update them for you. You can contact CPP by calling 0344 848
          4277
        </Typography.Copy.Text>
        <Typography.Copy.Text>
          <strong>Please review the information below to ensure it is still accurate.</strong>
        </Typography.Copy.Text>
      </div>

      {personalDetailsData && (
        <StyledSpacer space={4}>
          <PersonalDetailsForm values={getInitialFormValues()} onSubmit={handlePersonalDetailsFormSubmit} />
        </StyledSpacer>
      )}

      <section>
        <h3>
          <strong>Key information about how we use cookies</strong>
        </h3>
        <Typography.Copy.Text>
          We mainly use cookies to understand how you interact with the site. By using this Experian site, you agree to the above. For more
          information, please refer to the Experian{' '}
          <Link.Anchor inline href={CookieRoutes.Index} target="_blank">
            Cookies information page
          </Link.Anchor>{' '}
          for Identity Monitor
        </Typography.Copy.Text>
      </section>
    </PageLayout>
  );
};
