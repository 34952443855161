// @ts-ignore
import { Button, Typography } from '@experian-uk/corvetteuk-common-ui';

import React, { FunctionComponent } from 'react';

import { SignUpRoutes } from '@app/routes';
import { StyledSpacer } from '@modules/core/spacer';

import { SectionContactDetails, SectionPersonalDetails, SectionPolicyNumber } from '../sections';
import { PersonalDetailsFormModel } from './personal-details-form.model';

interface PersonalDetailsFormProps {
  values: PersonalDetailsFormModel;
  onSubmit: () => void;
}

export const PersonalDetailsForm: FunctionComponent<PersonalDetailsFormProps> = ({ values, onSubmit }): JSX.Element => {
  const personalDetails = {
    title: values.title,
    firstName: values.firstName,
    middleName: values.middleName,
    lastName: values.lastName,
    dateOfBirthDay: values.dateOfBirthDay,
    dateOfBirthMonth: values.dateOfBirthMonth,
    dateOfBirthYear: values.dateOfBirthYear,
  };

  const contactDetails = {
    emailAddress: values.emailAddress,
    contactNumber: values.contactNumber,
    secondaryPhoneNumber: values.secondaryPhoneNumber,
  };

  return (
    <div>
      <StyledSpacer space={5}>
        <SectionPersonalDetails personalDetails={personalDetails} />
      </StyledSpacer>

      <StyledSpacer space={5}>
        <section>
          <Typography.Headings.Secondary>Contact details</Typography.Headings.Secondary>

          <StyledSpacer space={3}>
            <Typography.Copy.Text>
              We ask for these so that we can send you Credit Report alerts, along with other communications about the service. These will
              automatically be sent by email.
            </Typography.Copy.Text>
          </StyledSpacer>

          <StyledSpacer space={3}>
            <Typography.Copy.Text>
              Once you have successfully registered, you will be able to update your alert preferences, should you prefer to receive alerts
              by text.
            </Typography.Copy.Text>
          </StyledSpacer>

          <SectionContactDetails contactDetails={contactDetails} />
        </section>
      </StyledSpacer>

      <StyledSpacer space={5}>
        <SectionPolicyNumber />
      </StyledSpacer>

      <StyledSpacer space={3}>
        <Button
          isButton
          label="Continue to final step"
          href={SignUpRoutes.AddressDetails}
          onClick={onSubmit}
          data-testid="register-final-step"
        />
      </StyledSpacer>
    </div>
  );
};
