import moment from 'moment';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useApolloClient } from 'react-apollo-hooks';

// @ts-ignore
import { Button, Notification, notificationVariant } from '@experian-uk/corvetteuk-common-ui';
import { Fade } from '@modules/core/animated';
import { Loading } from '@modules/core/loading';
import { StyledSpacer } from '@modules/core/spacer';
import { Address, AddressContext } from '@modules/domain/address';
import { AddressHistoryItem, CurrentAddressModel } from '@modules/domain/address/models';
import { UPDATE_PREVIOUS_ADDRESSES } from '@modules/domain/profile/address-history-list.mutations';

interface AddressSectionProps {
  currentAddress: CurrentAddressModel;
  previousAddresses: AddressHistoryItem[];
  handleCurrentAddressEditClick?: () => void;
}

const AddressSection: FunctionComponent<AddressSectionProps> = ({
  currentAddress,
  previousAddresses,
  handleCurrentAddressEditClick,
}): JSX.Element => {
  const { addressState, dispatch } = useContext(AddressContext);

  const apolloClient = useApolloClient();

  const [hasSetPreviousAddresses, setHasSetPreviousAddresses] = useState<boolean>(false);
  const [updateErrors, setUpdateErrors] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [didUpdate, setDidUpdate] = useState<boolean>(false);

  if (currentAddress && currentAddress !== addressState.currentAddress) {
    dispatch({ type: 'addCurrentAddress', currentAddress });
    if (currentAddress.fromDt) {
      dispatch({
        type: 'currentAddressMonthChanged',
        newValue: moment(currentAddress.fromDt).format('MMMM'),
      });
      dispatch({
        type: 'currentAddressYearChanged',
        newValue: moment(currentAddress.fromDt).format('YYYY'),
      });
    }
  }

  if (previousAddresses && !hasSetPreviousAddresses) {
    dispatch({ type: 'setPreviousAddresses', addressHistoryItems: previousAddresses, updatePending: false });
    setHasSetPreviousAddresses(true);
  }

  const handleAddressUpdate = async (): Promise<void> => {
    setIsUpdating(true);

    try {
      const updateUserMutationResult = await apolloClient.mutate({
        mutation: UPDATE_PREVIOUS_ADDRESSES,
        variables: {
          addresses: addressState.previousAddresses.map(pa => ({
            addressType: pa.addressType,
            city: pa.city,
            country: pa.country,
            county: pa.county,
            district: pa.district,
            flat: pa.flat,
            houseNumber: pa.houseNumber,
            houseName: pa.houseName,
            isVerified: pa.isVerified,
            manual: pa.manual,
            abroad: pa.abroad,
            postCode: pa.postCode,
            street: pa.street,
            fromDt: moment.utc(`01/${pa.fromMonth}/${pa.fromYear}`),
            toDt: moment.utc(`01/${pa.toMonth}/${pa.toYear}`),
          })),
        },
      });

      updateUserMutationResult.data ? onMutationSuccess() : onMutationFailure();
    } catch (e) {
      onMutationFailure();
    }
  };

  const onMutationSuccess = (): void => {
    setIsUpdating(false);
    setUpdateErrors(false);
    setDidUpdate(true);
    dispatch({ type: 'resetAddressUpdatePending' });
  };

  const onMutationFailure = (): void => {
    setIsUpdating(false);
    setUpdateErrors(true);
    setDidUpdate(false);
  };

  return (
    <>
      {addressState.currentAddress && addressState.currentAddress.postCode !== '' && addressState.currentAddressMonthValue !== '' && (
        <section data-testid="profile-address-details">
          <Address
            currentAddress={addressState.currentAddress}
            currentAddressFromDateConfirmed={true}
            showPreviousAddressUpdatedNotification={false}
            handleCurrentAddressEditClick={handleCurrentAddressEditClick}
          />
          {isUpdating ? (
            <Loading />
          ) : (
            <>
              {addressState.updatePending && (
                <StyledSpacer space={4}>
                  <Button
                    data-testid="save-address-details"
                    label="Save"
                    isButton
                    type="primary"
                    disabled={isUpdating}
                    onClick={() => {
                      handleAddressUpdate();
                    }}
                  />
                </StyledSpacer>
              )}
            </>
          )}
          {didUpdate && !updateErrors && (
            <Fade fadeDirection="in">
              <Notification
                notificationTitle="Details successfully updated"
                variant={notificationVariant.success}
                ctaLinkFunc={() => setDidUpdate(false)}
                ctaLinkText="Close"
              >
                Your details have been successfully updated.
              </Notification>
            </Fade>
          )}
          {updateErrors && <Notification>Could not update your details</Notification>}
        </section>
      )}
    </>
  );
};

export { AddressSection };
